import { TTableColumn } from 'components'
import { formatNilValue } from 'utils'

export const BASIC_TABLE_COLUMNS: TTableColumn[] = [
  {
    name: 'Name',
    field: 'name',
    width: '60%',
    format: formatNilValue
  },
  {
    name: 'Type',
    field: 'type',
    width: '40%',
    format: formatNilValue
  }
]
