import { ReactNode } from 'react'

import { mapClasses } from 'utils'

export interface BackdropProps {
  className?: string
  isLoading?: boolean
  children: ReactNode
}

function Backdrop({ className, isLoading = false, children }: BackdropProps) {
  if (!isLoading) return null

  return (
    <div
      className={mapClasses(
        'fixed z-[1000] top-0 left-0 w-full h-full',
        className
      )}
    >
      <div className="flex justify-center items-center h-full bg-black bg-opacity-80">
        {children}
      </div>
    </div>
  )
}

export default Backdrop
