import {
  GraphCardGroup,
  GraphCardWrapper
} from 'components/graph-card'
import 'react-vis/dist/style.css'
import { TPlotSeries } from 'services/costhistory.interface'
import Spinner from 'components/spinner'
import {
  // AreaChart,
  // BarChart,
  AreaChartSeries,
  // LineChart
} from '@mantine/charts'
import { TCurrency } from 'services/cost.interface'
// import { makeTooltip } from 'components/LabeledDataRepresenation/helper'
import { useEffect, useState } from 'react'
import { Area, AreaChart, Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

interface BarLineChartProps {
  data: TPlotSeries[] | object[]
  dataSeries: AreaChartSeries[]
  isLoading?: boolean
  chartType?: 'line' | 'bar' | 'area',
  currency: TCurrency
  height?: number | undefined
  onClick?: (event: any) => void
}

function BarLineChart({
  data,
  dataSeries,
  chartType,
  isLoading,
  currency,
  height,
  onClick
}:
  BarLineChartProps) {
  const [theHeight, setTheHeight] = useState<number>(300)
  useEffect(() => {
    if (height) setTheHeight(height)
  }, [height])
  if (isLoading) {
    return (
      <GraphCardWrapper>
        <GraphCardGroup
          className="flex justify-center items-center h-full py-20"
          compact
          spacing={20}
        >
          <Spinner size={40} />
        </GraphCardGroup>
      </GraphCardWrapper>
    )
  }
  return (
    <div className={`h-[${theHeight}px]`}>
      {chartType === 'bar' && (
        <ResponsiveContainer width="100%" height={theHeight}>
          <BarChart
            width={theHeight}
            data={data}
            onClick={onClick}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              formatter={(value, name) => {
                if (name !== '') return [value, name]
                return [value, 'Undefined']
              }}
              separator={`: ${currency.symbol}`}
            />
            {dataSeries.map((serie) => {
              return <Bar key={serie.name} dataKey={serie.name} stackId="a" fill={serie.color} />
            })}
          </BarChart>
        </ResponsiveContainer>
      )}
      {chartType === 'line' && (
        <ResponsiveContainer width="100%" height={theHeight}>
          <LineChart
            width={theHeight}
            data={data}
            onClick={onClick}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              formatter={(value, name) => {
                if (name !== '') return [value, name]
                return [value, 'Undefined']
              }}
              separator={`: ${currency.symbol}`}
            />
            {dataSeries.map((serie) => {
              return <Line key={serie.name} dataKey={serie.name} type="monotone" activeDot={{ r: 8 }} stroke={serie.color} />
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
      {chartType === 'area' && (

        <ResponsiveContainer width="100%" height={theHeight}>
          <AreaChart
            width={theHeight}
            data={data}
            onClick={onClick}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              formatter={(value, name) => {
                if (name !== '') return [value, name]
                return [value, 'Undefined']
              }}
              separator={`: ${currency.symbol}`}
            />
            {dataSeries.map((serie) => {
              return <Area key={serie.name} dataKey={serie.name} stackId="a" fill={serie.color} stroke={serie.color} />
            })}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default BarLineChart
