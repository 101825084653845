import { AiFillCloud } from 'react-icons/ai'
import {
  ClusterCard, Table, TTableColumn, TTableData,
} from 'components'

function SingleProject() {
  const columns: TTableColumn[] = [
    { name: 'Resource', field: 'resourceValue', width: 200 },
    { name: 'Requested', field: 'requestedValue', width: 100 },
    { name: 'Usage', field: 'usageValue', width: 100 },
    { name: 'Under-provisioning', field: 'underProvisioningValue', width: 100 },
    { name: 'Over-provisioning', field: 'overProvisioningValue', width: 100 },
    { name: 'Savings', field: 'savingsValue', width: 150 }
  ]
  const breakdownColumns: TTableColumn[] = [
    { name: 'Container', field: 'containerValue', width: 200 },
    { name: 'Cluster', field: 'clusterValue', width: 200 },
    { name: 'Cpu Usage', field: 'cpuUsageValue', width: 120 },
    { name: 'Cpu request', field: 'cpuRequestValue', width: 120 },
    { name: 'Cpu recommend', field: 'cpuRecommend', width: 120 },
    { name: 'RAM usage', field: 'ramUsageValue', width: 120 },
    { name: 'RAM request', field: 'ramRequestValue', width: 120 },
    { name: 'RAM recommend', field: 'ramRecommendValue', width: 120 },
    { name: 'Efficiency', field: 'efficiencyValue', width: 120 },
    { name: 'Savings', field: 'savingsValue', width: 120 }
  ]

  const tableData: TTableData[] = [
    {
      resourceValue: 'CPU',
      requestedValue: '1.41',
      usageValue: '1.41m',
      underProvisioningValue: '18m',
      overProvisioningValue: '1.28',
      savingsValue: '€18.18/mo'
    },
    {
      resourceValue: 'RAM',
      requestedValue: '1.1GiB',
      usageValue: '687.7 MiB',
      underProvisioningValue: '289.8 MiB',
      overProvisioningValue: '527.6 MiB',
      savingsValue: '€0.18/mo'
    }
  ]

  const breakdownTableData: TTableData[] = [
    {
      containerValue: 'CPU',
      clusterValue: 'localdev/cluster-one',
      cpuUsageValue: '0m',
      cpuRequestValue: '200m',
      cpuRecommend: '10m',
      ramUsageValue: '12.4 MiB',
      ramRequestValue: '50 MiB',
      ramRecommendValue: '20 MiB',
      efficiencyValue: '0.7%',
      savingsValue: '€3.96/mo'
    },
    {
      containerValue: 'CPU',
      clusterValue: 'localdev/cluster-one',
      cpuUsageValue: '0m',
      cpuRequestValue: '200m',
      cpuRecommend: '10m',
      ramUsageValue: '12.4 MiB',
      ramRequestValue: '50 MiB',
      ramRecommendValue: '20 MiB',
      efficiencyValue: '0.7%',
      savingsValue: '€3.96/mo'
    }
  ]

  return (
    <div className="main xs:p-4 tablet:p-10">
      <p className="text-xl font-medium xs:pb-3">Project A</p>
      <div className="w-full border border-dark-grey rounded-md my-2 px-4 py-3 flex justify-center flex-col items-center bg-green-300 h-28 savings">
        <div className="flex flex-col justify-center items-center">
          <p className="text-3xl font-semibold text-gray-800">$ 500</p>
          <p className="text-xl font-medium text-gray-800">
            Estimated Monthly Cost Savings
          </p>
        </div>
      </div>
      <p className="text-lg font-medium xs:pb-3 py-4">
        Project cost optimizations
      </p>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <p className="text-base font-medium xs:pb-3">Summary</p>
          <p className="text-green-700 font-semibold">€18.90/mo</p>
        </div>
        <div className="border-b border-gray-200 mb-3 overflow-auto">
          <Table fullHeight={false} columns={columns} data={tableData} />
        </div>
      </div>
      <div className="flex flex-col pt-2">
        <p className="text-base font-medium xs:pb-3">Breakdown</p>
        <div className="border-b border-gray-200 mb-3 overflow-auto">
          <Table fullHeight={false} columns={breakdownColumns} data={breakdownTableData} />
        </div>
      </div>
      <div className="flex flex-col pt-2">
        <p className="text-base font-medium xs:pb-3 tablet:pb-0">
          Cluster Optimization
        </p>
        <div className="flex justify-between items-center xs:flex-col flex-wrap tablet:flex-row w-full pb-3">
          <ClusterCard
            heading="Right-size your cluster nodes"
            description="Adjust the number and size of cluster nodes to stop over spending on unused capacity"
            icon={<AiFillCloud size={24} className="text-primary-text-color" />}
          />
          <ClusterCard
            heading="Resize Local disk"
            description="Adjust the number and size of cluster nodes to stop over spending on unused capacity"
            icon={<AiFillCloud size={24} className="text-primary-text-color" />}
          />
          <ClusterCard
            heading="Delete unassigned resourses"
            description="Adjust the number and size of cluster nodes to stop over spending on unused capacity"
            icon={<AiFillCloud size={24} className="text-primary-text-color" />}
          />
          <ClusterCard
            heading="Remedy abandoned workloads"
            description="Adjust the number and size of cluster nodes to stop over spending on unused capacity"
            icon={<AiFillCloud size={24} className="text-primary-text-color" />}
          />
          <ClusterCard
            heading="Manage unclaimed volumes"
            description="Adjust the number and size of cluster nodes to stop over spending on unused capacity"
            icon={<AiFillCloud size={24} className="text-primary-text-color" />}
          />
          <ClusterCard
            heading="Manage underutilized nodes"
            description="Adjust the number and size of cluster nodes to stop over spending on unused capacity"
            icon={<AiFillCloud size={24} className="text-primary-text-color" />}
          />
        </div>
      </div>
    </div>
  )
}

export default SingleProject
