import {
  createContext,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'

import { TReport, TReportFilter } from 'services/report.interface'
import {
  TFetchLabelsFunction,
  TCreateReportFilterFunction,
  TDeleteReportFilterFunction,
  TFetchReportFiltersFunction,
  TFetchReportsByFilterFunction
} from 'services/fetch.interface'
import { FETCH_STATUS } from 'components'
import { apiErrorHandler, axios } from 'utils'

interface ReportContextType {
  getReportFilters: TFetchReportFiltersFunction
  createReportFilter: TCreateReportFilterFunction
  deleteReportFilter: TDeleteReportFilterFunction
  getReportsByFilter: TFetchReportsByFilterFunction
  fetchLabels: TFetchLabelsFunction
  labels: string[]
}

const ReportContext = createContext<ReportContextType>({} as ReportContextType)

export function ReportContextProvider({
  children
}: {
  children: ReactElement
}) {
  const keepRef = useRef({ isFetchedLabels: false })

  const [labels, setLabels] = useState<string[]>([])

  const createReportFilter = useCallback<TCreateReportFilterFunction>(
    async (body, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.post<TReport[]>('/query', {
          ...body,
          options: { saveQuery: true }
        })
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || []
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return []
      }
    },
    []
  )

  const deleteReportFilter = useCallback<TDeleteReportFilterFunction>(
    async (params, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        await axios.delete<TReport[]>('/query', { params })
        updateStatus?.(FETCH_STATUS.SUCCESS)
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
      }
    },
    []
  )

  const getReportFilters = useCallback<TFetchReportFiltersFunction>(
    async (_, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<TReportFilter[]>('/query')
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || []
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return []
      }
    },
    []
  )

  const getReportsByFilter = useCallback<TFetchReportsByFilterFunction>(
    async (body, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.post<TReport[]>('/query', {
          ...body,
          options: { saveQuery: false }
        })
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || []
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return []
      }
    },
    []
  )

  const fetchLabels = useCallback<TFetchLabelsFunction>(
    async (_, updateStatus) => {
      if (keepRef.current.isFetchedLabels) return

      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<{ labels: string[] }>('/labels')
        setLabels(data.labels)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        keepRef.current.isFetchedLabels = true
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        setLabels([])
      }
    },
    []
  )

  const value = useMemo<ReportContextType>(
    () => ({
      getReportFilters,
      createReportFilter,
      deleteReportFilter,
      getReportsByFilter,
      fetchLabels,
      labels
    }),
    [
      getReportFilters,
      createReportFilter,
      deleteReportFilter,
      getReportsByFilter,
      fetchLabels,
      labels
    ]
  )

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  )
}

export default ReportContext
