//--------------------------------------------------------------------------
// ResourceFilterElement.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { ButtonIcon } from 'components';
import { OPERATOR_OPTIONS, NUMBER_OPERATOR_OPTIONS, TEXT_OPERATOR_OPTIONS } from 'constants/report.constants';
import { isString, reportLoadAutoCompleteIcon } from 'pages/label-rules/helperFunctions';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { toast, Bounce } from 'react-toastify';
import { axios } from 'utils/axios';
//--------------------------------------------------------------------------
// The type of a filter
//--------------------------------------------------------------------------
type filterType = {
  field: string;
  operator: string;
  value: string | number;
}
//--------------------------------------------------------------------------
// The interface for the element that will be drawn by the system
//--------------------------------------------------------------------------
interface ResourceFilterElementProps {
  filter: filterType
  idx: number,
  filters: filterType[],
  setFilters: (newFilters: filterType[]) => void
  label?: boolean,
  options: string[]
}
//--------------------------------------------------------------------------
// The element that will be drawn by the system
//--------------------------------------------------------------------------
export function ResourceFilterElement({
  filter,
  idx,
  filters,
  setFilters,
  label,
  options
}: ResourceFilterElementProps) {
  //--------------------------------------------------------------------------
  // The states that will be used throughout the creation of a filter.
  //--------------------------------------------------------------------------
  const titleCase = (s: string) => s.replace(/^[-_]*(.)/, (_: any, c: string) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_: any, c: string) => ` ${c.toUpperCase()}`)
  const [preExistingData, setPreExistingData] = useState<string[]>([])
  const [queryKey, setQueryKey] = useState<string>(`initialFor${label ? 'label' : 'field'}${filter.field}`)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)

  //--------------------------------------------------------------------------
  // When a filter is loaded in this useEffect will initialise the states
  // mentioned above. It reacts based on if it is a condition filter or a
  // label filter. This is needed since labels their names start with "label."
  // however we would not want to present this to the user.
  //--------------------------------------------------------------------------
  useEffect(() => {
    if (filter.field === '') {
      const theField = options[0]
      const newFilter = {
        field: theField,
        operator: filter.operator,
        value: filter.value
      }
      if (label) {
        newFilter.field = `label.${newFilter.field}`
      }
      const newList = filters.map((el, idx2) => {
        if (idx === idx2) {
          return newFilter
        } return el
      })
      setQueryKey(`initialFor${label ? 'label' : 'field'}${newFilter.field}`)
      setDataLoaded(false)
      setFilters(newList)
    }
  }, [filter])

  //--------------------------------------------------------------------------
  // This query is responsible for fetching the preexisting label- or
  // field-values for a specific label or field.
  //--------------------------------------------------------------------------
  useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!dataLoaded && filter.field) {
          if (isString(filter.field)) {
            await axios
              .get(`/${label ? 'labels' : 'fields'}/${filter.field}`)
              .then((res) => {
                setDataLoaded(true)
                setPreExistingData(res.data)
              })
              .catch((error) => {
                toast.error(`Error code ${error.status}`, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
                // throw new Error(`Error code ${error.status}`)
              })
          }
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  //--------------------------------------------------------------------------
  // This will output the desired component used to show the filter to the user.
  //--------------------------------------------------------------------------
  return (
    <div className="rounded-lg bg-white flex border border-gray-500 bg-gray-300">

      <div className="flex bg-gray-300 rounded-l-lg w-full">
        {reportLoadAutoCompleteIcon('allIsFine', 'allIsFine', filter.field, !dataLoaded && filter.field !== undefined)}
        <div className="w-full">
          <select
            className={`h-full w-full bg-white text-center bottom-0 rounded-l-lg border-l border-gray-500 ${label ? 'border-r' : ''}`}
            value={filter.field}
            onChange={(event) => {
              const newFilter = {
                field: event.target.value,
                operator: filter.operator,
                value: filter.value
              }
              if (label) {
                newFilter.field = `label.${newFilter.field}`
              }
              const newList = filters.map((el, idx2) => {
                if (idx === idx2) {
                  return newFilter
                } return el
              })
              setQueryKey(`initialFor${label ? 'label' : 'field'}${newFilter.field}`)
              setDataLoaded(false)
              setPreExistingData([])
              setFilters(newList)
            }}
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {titleCase(option)}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* )} */}
      {!label && (
        <div className="border-l border-r border-gray-500">
          <select
            className="h-full bg-white text-center bottom-0"
            value={filter.operator}
            onChange={(event) => {
              const newFilter = {
                field: filter.field,
                operator: event.target.value,
                value: filter.value
              }
              const newList = filters.map((el, idx2) => {
                if (idx === idx2) {
                  return newFilter
                } return el
              })
              setFilters(newList)
            }}
          >
            {isString(filter.field) && (
              [...OPERATOR_OPTIONS, ...TEXT_OPERATOR_OPTIONS].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )))}

            {!isString(filter.field) && (
              [...OPERATOR_OPTIONS, ...NUMBER_OPERATOR_OPTIONS].map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )))}
          </select>
        </div>
      )}
      <div className="flex bg-gray-300 rounded-r-lg w-full">
        <input
          type="search"
          list={`preExistingDataOptionsFor${filter.field}`}
          className="h-full text-gray-500 w-full p-1 rounded-r-lg text-center bottom-0 border-r border-gray-500"
          value={filter.value}
          onChange={(event) => {
            const newFilter = {
              field: filter.field,
              operator: filter.operator,
              value: event.target.value
            }
            if (label) {
              newFilter.field = `label.${newFilter.field}`
            }
            const newList = filters.map((el, idx2) => {
              if (idx === idx2) {
                return newFilter
              } return el
            })
            setFilters(newList)
          }}
        />
        <datalist id={`preExistingDataOptionsFor${filter.field}`}>
          {preExistingData.map((el) => {
            return <option value={el} />
          })}
        </datalist>
        <ButtonIcon
          onClick={() => {
            const newFilters = filters.filter((el, elIdx) => {
              return elIdx !== idx
            })
            setFilters(newFilters)
          }}
        >
          <IoClose color="black" />
        </ButtonIcon>
      </div>
    </div>
  )
}
