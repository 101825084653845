import { TYamlLineData } from 'services/application.interface'

// prevent "Maximum call stack size exceeded" error
const MAX_LEVEL_FOR_SAFE = 50

export const mapYamlLines = ({
  value,
  level,
  field
}: {
  value: unknown
  level: number
  field: string
}): TYamlLineData[] => {
  if (value === undefined || level >= MAX_LEVEL_FOR_SAFE) return []

  const line: TYamlLineData = { level, field, value: '' }

  // Null
  if (value === null) {
    return [{ ...line, value: 'null' }]
  }

  // String
  if (typeof value === 'string') {
    let newValue = value || `'${''}'`
    if (/^[0-9]+$/.test(value)) newValue = `'${value}'`
    return [{ level, field, value: newValue }]
  }

  // Number
  if (typeof value === 'number') {
    return [{ level, field, value }]
  }

  // Array
  if (Array.isArray(value)) {
    let lineList: TYamlLineData[] = [{ level, field, value: '' }]
    value.forEach((item) => {
      let itemList = mapYamlLines({ value: item, level: level + 1, field: '' })
      itemList = itemList.map((item, index) => ({
        ...item,
        prefix: item.prefix || (index === 0 ? 'dash' : 'space')
      }))
      lineList = [...lineList, ...itemList]
    })
    return lineList
  }

  // Object
  if (typeof value === 'object') {
    // if value is a item of array, field will be empty
    let newLevel = level
    let lineList: TYamlLineData[] = []
    if (field) {
      newLevel = level + 1
      lineList = [{ level, field, value: '' }]
    }
    Object.entries(value).forEach(([field, value]) => {
      const itemList = mapYamlLines({ value, level: newLevel, field })
      lineList = [...lineList, ...itemList]
    })
    return lineList
  }

  return []
}
