import { ReactNode } from 'react'

import { mapClasses } from 'utils'
import { SPACING } from 'constants/sizes.constants'

interface GraphCardGroupProps {
  className?: string
  grow?: boolean
  key?: string
  spacing?: number
  children?: ReactNode
  compact?: boolean
}

function GraphCardGroup({
  className,
  grow,
  spacing = SPACING.GRAPH_GROUP,
  key,
  children,
  compact
}: GraphCardGroupProps) {
  return (
    <div
      key={key}
      className={mapClasses(
        `shrink basis-0 flex ${
          compact ? 'active-icon' : 'justify-between'
        } xs:flex-col large:flex-row w-full`,
        grow && 'grow',
        className
      )}
      style={{ gap: spacing }}
    >
      {children}
    </div>
  )
}

export default GraphCardGroup
