/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TCurrency } from 'services/cost.interface';
import { CURRENCY_SYMBOL } from 'constants/currency.constants';
import { isNil } from './isNil'

export const roundNumberToString = (value: string | number, decimal = 2) => {
  if (!value || Number.isNaN(Number(value))) return Number(0).toFixed(decimal)
  return Number(value).toFixed(decimal)
}

export const roundNumber = (value: string | number, decimal = 2) => (
  Number(roundNumberToString(value, decimal))
);

export const formatNumber = (value?: number) => {
  // const currencySymbol = options?.currency?.symbol || CURRENCY_SYMBOL.DOLLAR
  if (isNil(value)) return 'N/A'
  return `${roundNumberToString(value!, 2)}`
}

export const isNumber = (value?: any) => {
  return typeof value === 'number';
}

export const formatDateRange = (value?: string) => {
  // const currencySymbol = options?.currency?.symbol || CURRENCY_SYMBOL.DOLLAR
  if (!value) return 'N/A'
  if (isNil(value)) return 'N/A'
  const dateParts = value?.split(',')
  const startDate = new Date(dateParts[0])
  const endDate = new Date(dateParts[1])
  return `${startDate.toDateString()}-${endDate.toDateString()}`
}

export const formatCost = (value?: number, options?: { currency?: TCurrency }) => {
  const currencySymbol = options?.currency?.symbol || CURRENCY_SYMBOL.DOLLAR
  if (isNil(value)) return 'N/A'
  return `${currencySymbol}${roundNumberToString(value!)}`
}

export const formatPercent = (value: number) => {
  if (isNil(value)) return 'N/A'
  return `${roundNumberToString(value)}%`
}
