import { useEffect, useMemo, useRef } from 'react'
import { mapClasses } from 'utils'
import { v4 as uuid } from 'uuid'

interface CheckboxProps {
  label: string
  size?: 'sm' | 'md' | 'lg'
  checked?: boolean
  labelLeft?: boolean
  indeterminate?: boolean
  onChange?: (checked: boolean) => void
}

function Checkbox({
  label,
  checked,
  labelLeft,
  size = 'sm',
  indeterminate = false,
  onChange
}: CheckboxProps) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const id = useMemo(() => uuid(), [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <div
      className={mapClasses(
        'flex items-center relative',
        size === 'lg' && 'p-1',
        size === 'md' && 'p-1',
        size === 'sm' && ''
      )}
    >
      <input
        ref={inputRef}
        className={mapClasses(
          'absolute top-1/2 -translate-y-1/2 rounded cursor-pointer accent-red-500 float-right',
          size === 'lg' && 'w-8 h-8',
          size === 'md' && 'w-6 h-6',
          size === 'sm' && 'w-4 h-4'
        )}
        id={id}
        checked={checked}
        type="checkbox"
        onChange={(event) => onChange?.(event.target.checked)}
      />
      {!labelLeft && (
        <label
          htmlFor={id}
          className="w-full pl-6 py-2 float-left cursor-pointer"
        >
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
