import { ReactNode, useMemo } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { RiCloseLine } from 'react-icons/ri'
import { AiOutlineFullscreenExit, AiOutlineFullscreen } from 'react-icons/ai'

import { ButtonIcon, FetchStatus } from 'components'
import { TFetchStatus } from 'services/fetch.interface'
import { mapClasses } from 'utils'
import { mapYamlLines } from './yarmlUtils'
import YamlLine from './YamlLine'

interface YamlContentProps {
  isFullScreen?: boolean
  fetchStatus?: TFetchStatus
  yamlData?: unknown
  title?: ReactNode
  fetchYaml?: () => Promise<void>
  onToogleFullScreen?: (isFullScreen: boolean) => void
  onClose?: () => void
}

function YamlContent({
  isFullScreen,
  fetchStatus,
  title,
  yamlData,
  fetchYaml,
  onToogleFullScreen,
  onClose
}: YamlContentProps) {
  const yamlLineList = useMemo(() => {
    if (!yamlData) return []
    return mapYamlLines({ value: cloneDeep(yamlData), level: 0, field: '' })
  }, [yamlData])

  const { isLoading, isError } = fetchStatus || {}

  return (
    <div className="w-full h-full bg-[#24292D] shadow-xl border-black rounded-r">
      {/* head */}
      <div className="flex justify-between gap-2 px-5 py-4 shadow bg-[#1F2428]">
        <div className="grow">
          {!!title && <p className="text-gray-500 font-semibold">{title}</p>}
        </div>
        <ButtonIcon
          className="block p-1"
          onClick={() => onToogleFullScreen?.(!isFullScreen)}
        >
          {isFullScreen ? (
            <AiOutlineFullscreenExit size={20} />
          ) : (
            <AiOutlineFullscreen size={20} />
          )}
        </ButtonIcon>
        <ButtonIcon className="block" onClick={onClose}>
          <RiCloseLine size={24} />
        </ButtonIcon>
      </div>

      {/* body */}
      <div
        className={mapClasses(
          'grow px-5 py-4 text-white h-[calc(100%-56px)] overflow-auto',
          (isLoading || isError) && 'flex items-center'
        )}
      >
        <FetchStatus status={fetchStatus} retry={fetchYaml}>
          <div>
            {yamlLineList.map((lineData, index) => (
              <YamlLine key={index} lineData={lineData} />
            ))}
          </div>
        </FetchStatus>
      </div>
    </div>
  )
}

export default YamlContent
