import { useState, ReactNode, useEffect } from 'react'
import { FaAngleDown } from 'react-icons/fa'

import { mapClasses } from '../../utils'

type AccordionProps = {
  defaultExpanded?: boolean
  duration?: number
  title?: string
  className?: string
  children: ReactNode
}

function Accordion({
  defaultExpanded = false,
  duration = 100,
  title = '',
  className,
  children
}: AccordionProps) {
  const [contentElement, setContentElement] = useState<HTMLElement | null>(null)
  const [isExpanded, setExpanded] = useState<boolean>(defaultExpanded)
  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    let timeoutId: string

    const observer = new ResizeObserver(() => {
      clearTimeout(timeoutId)
      if (!contentElement) return
      // when content's height is being changed, set maxHeight by very large number
      // -> in this time, raw css will handle [Accordion] height
      setMaxHeight(10000)

      timeoutId = setTimeout(() => {
        // after changing content's height was completed, set maxHeight by content's height
        // -> from now, collapsing will work correctly
        setMaxHeight(contentElement.offsetHeight)
      }, 200) as unknown as string
    })

    if (contentElement) observer.observe(contentElement)
    return () => {
      observer.disconnect()
      clearTimeout(timeoutId)
    }
  }, [contentElement])

  return (
    <div
      className={mapClasses(
        'rounded-lg relative shadow-lg overflow-hidden',
        className
      )}
    >
      <div
        className={mapClasses(
          'px-4 py-3 flex items-center justify-between bg-gray-100 cursor-pointer select-none',
          'hover:bg-gray-200 hover:text-primary-text-color transition-colors'
        )}
        onClick={() => setExpanded(!isExpanded)}
      >
        <p className="font-semibold">{title}</p>
        <span
          className={mapClasses(
            'transition-transform',
            isExpanded && '-rotate-180'
          )}
          style={{ transitionDuration: `${duration}ms` }}
        >
          <FaAngleDown size={20} />
        </span>
      </div>

      <div
        className="transition-all overflow-hidden ease-linear"
        style={{
          maxHeight: isExpanded ? maxHeight : 0,
          transitionDuration: `${duration}ms`
        }}
      >
        <div ref={setContentElement} className="p-4">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Accordion
