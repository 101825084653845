import { Button, Popover } from '@mantine/core';
import { useState } from 'react';

interface OpenAdditionPopoverProps {
  str: string | number
  addLabelRuleCallBack: () => void
}
export function OpenAdditionPopover({ str, addLabelRuleCallBack }: OpenAdditionPopoverProps) {
  const [open, setOpen] = useState<boolean>(false)
  const changeOpen = () => {
    setOpen(!open)
  }
  return (
    <Popover width={300} position="bottom" withArrow shadow="md" clickOutsideEvents={['mouseup', 'touchend']} opened={open}>
      <Popover.Target>
        <span onClick={changeOpen}>{str}</span>
      </Popover.Target>
      <Popover.Dropdown
        bg="white"
        className="border border-gray-300"
      >
        <div className="flex flex-col text-center">
          <span>Add as label rule?</span>
          <div className="flex flex-row">
            <Button
              type="button"
              className="bg-white hover:bg-gray-600 text-black hover:text-white border-2 border-black w-full m-1"
              onClick={() => {
                changeOpen()
              }}
            >
              Cancel
            </Button>

            <Button
              type="button"
              className="bg-[#668957] hover:bg-[#93ac89] border-2 border-[#668957] rounded-lg w-full m-1 text-white"
              onClick={() => {
                changeOpen()
                addLabelRuleCallBack()
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}
