import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { isNil, mapClasses } from 'utils'
import {
  TComputePositionReturnValue,
  TPlacement,
  computePosition
} from './popover.utils'

interface PopoverProps {
  open?: boolean
  mode?: 'click' | 'hover'
  placement?: TPlacement
  className?: string
  extraSpace?: number
  extraPositions?: { top?: number; left?: number }
  anchorElement: HTMLElement | null
  containerElement?: HTMLElement | null
  children: ReactNode
  onClose?: () => void
}

function Popover({
  open: openProp,
  mode = 'click',
  placement = 'bottom-start',
  extraPositions,
  className,
  extraSpace = 8,
  anchorElement,
  containerElement,
  children,
  onClose
}: PopoverProps) {
  const keepRef = useRef({ isControlled: false })
  const [isOpen, setOpen] = useState(false)
  const [contentElement, setContentElement] = useState<HTMLElement | null>(null)

  keepRef.current.isControlled = !isNil(openProp)

  const positionStyles = useMemo<TComputePositionReturnValue>(() => {
    if (!anchorElement || !contentElement) return {}

    return computePosition({
      anchorEl: anchorElement,
      contentEl: contentElement,
      extraSpace,
      extraPositions,
      placement
    })
  }, [placement, extraSpace, anchorElement, contentElement])

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  useEffect(() => {
    // uncontrolled
    if (!keepRef.current.isControlled) return
    // controlled
    setOpen(openProp || false)
  }, [openProp])

  // handle events for uncontrolled mode
  useEffect(() => {
    const handleClick = () => setOpen(true)
    const handleMouseOver = () => setOpen(true)
    const handleMouseLeave = () => setOpen(false)

    if (anchorElement && !keepRef.current.isControlled) {
      if (mode === 'click') {
        anchorElement.addEventListener('mousedown', handleClick)
      }
      if (mode === 'hover') {
        anchorElement.addEventListener('mouseover', handleMouseOver)
        anchorElement.addEventListener('mouseleave', handleMouseLeave)
      }
    }

    return () => {
      anchorElement?.removeEventListener('mousedown', handleClick)
      anchorElement?.removeEventListener('mouseover', handleMouseOver)
      anchorElement?.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [mode, anchorElement])

  if (!isOpen || !anchorElement) return null

  return createPortal(
    <div
      className={mapClasses(
        'fixed z-[1000]',
        mode === 'click' && 'top-0 left-0 w-full h-full'
      )}
      onClick={handleClose}
      style={mode === 'hover' ? positionStyles : undefined}
    >
      <div
        className={mapClasses(
          'shadow-lg shadow-gray-500/50 border border-gray-100 rounded',
          mode === 'click' && 'absolute'
        )}
        style={mode === 'click' ? positionStyles : undefined}
      >
        <div
          ref={setContentElement}
          className={mapClasses(
            'relative z-10 rounded',
            'px-3 py-1.5 bg-white',
            className
          )}
        >
          {children}
        </div>
      </div>
    </div>,
    containerElement || document.body
  )
}

export default Popover
