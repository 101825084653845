import Axios from 'axios'
import env from 'env'
// import { useKeycloak } from '@react-keycloak/web'

const axiosInstance = Axios.create({
  baseURL: env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

const axiosGraphQlInstance = Axios.create({
  baseURL: env.REACT_APP_GRAPH_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('access_token') || ''
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`
  }

  return config
})
axiosGraphQlInstance.interceptors.request.use((config) => {
  const token = window.localStorage.getItem('access_token') || ''
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`
  }

  return config
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { config: originalRequest, response } = error

    if (![401, 403].includes(response.status) || originalRequest._retry) {
      return Promise.reject(error)
    }
    /* const { keycloak, initialized } = useKeycloak()
    originalRequest._retry = true
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(30)
    } */
    const token = window.localStorage.getItem('access_token') || ''
    originalRequest.headers = {
      ...originalRequest.headers,
      Authorization: `Bearer ${token}`
    }

    return Axios(originalRequest)
  }
)

axiosGraphQlInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { config: originalRequest, response } = error

    if (![401, 403].includes(response.status) || originalRequest._retry) {
      return Promise.reject(error)
    }
    /* const { keycloak, initialized } = useKeycloak()
    originalRequest._retry = true
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(30)
    } */
    const token = window.localStorage.getItem('access_token') || ''
    originalRequest.headers = {
      ...originalRequest.headers,
      Authorization: `Bearer ${token}`
    }

    return Axios(originalRequest)
  }
)

export { axiosInstance as axios, axiosGraphQlInstance as graphQLAxios }
