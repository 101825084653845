import { ChartTooltip } from '@mantine/charts';
import { TCurrency } from 'services/cost.interface';

export function makeTooltip(label: any, payload: any, currency: TCurrency) {
  const newPayload = payload.sort((a: { value: number; }, b: { value: number; }) => {
    return b.value - a.value
  })
  return (
    <ChartTooltip
      label={label}
      payload={
        newPayload.map((el: {
          unit: string; value: number;
        }) => {
          const newEl = el
          newEl.unit = currency.symbol
          return newEl
        })
      }
    />
  )
}
