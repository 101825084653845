import { TReportCondition, TReportConditionData, TReportFilterData, TReportFilterDisplay } from 'services/report.interface';
import { v4 as uuid } from 'uuid'
import { REPORT_FILTER_TYPE } from 'constants/report.constants';
import { TQueryRequest } from 'services/commons.interface';
import { AreaChartSeries } from '@mantine/charts';
import { CHART_COLORS } from 'constants/color.constants';
import { TFocusGroup, TPlotSeries } from 'services/costhistory.interface';
import { generateEmptyFilter } from 'pages/advanced-reporting/report.utils2';
import { rndColors } from './colorList';

export const toTimestamp = (strDate: string) => {
  const datum = Date.parse(strDate)
  return datum
}

export const getValue = (item: unknown, property: string, isdate: boolean) => {
  if (isdate) {
    return toTimestamp((item as any)[property])
  }
  const v = parseFloat(Number((item as any)[property]).toFixed(8))
  if (Number.isNaN(v)) {
    return Math.abs(0)
  }
  return v
}

export const getStringValue = (item: unknown, property: string) => {
  return String((item as any)[property])
}

export const cleanIntToString = (value: string, max: number) => {
  const v = value.replace(/\D/g, '');
  if (parseInt(v, 10) > max) {
    return max.toString()
  }
  return v
}

export const cleanResourceString = (value: string | undefined) => {
  if (!value) return ''
  const v = value.split(':', 2)
  if (v.length > 1) {
    return v[1]
  }
  return value
}

export const cleanLabelRuleString = (value: string | undefined) => {
  if (!value) return ''
  const val = value.replaceAll('labelrule/', '')
  return val
}

// *******************************************************************
// This function converts the report filter data to TReportCondition[]
// *******************************************************************
export const convertReportFilterDataToCondition = (reportFilter: TReportFilterData[]) => {
  const payload: TReportCondition[] = []
  reportFilter.forEach((filter) => {
    filter.conditions.forEach((condition) => {
      const payloadFilter = {
        field: condition.field,
        value: condition.value,
        operator: condition.operator
      }

      if (filter.type !== REPORT_FILTER_TYPE.DEFAULT) {
        payloadFilter.field = `${filter.type}.${condition.field}`
      }
      payload.push(payloadFilter)
    })
  })
  return payload
}

export const convertReportFilterDisplayToQuery =
  (report?: TReportFilterDisplay): TQueryRequest => {
    const payload: TQueryRequest = {
      name: report?.name || '',
      description: report?.description,
      filters: report?.filters,
      options: {
        saveQuery: false,
        accumulate: false,
        window: report?.chart?.window || '',
        step: report?.chart?.step || '1h',
        aggregate: report?.chart?.aggregate || 'resourceId'
      }
    }
    return payload
  }

export const convertReportFilterDisplayToData =
  (reportParam?: TReportFilterDisplay): TReportFilterData[] => {
    const filterList: TReportFilterData[] = []
    const defaultConditions: TReportConditionData[] = []
    const labelConditions: TReportConditionData[] = []
    const annotationConditions: TReportConditionData[] = []
    if (!reportParam) return filterList
    reportParam.filters?.forEach((filter) => {
      if (filter.field.startsWith('label.')) {
        labelConditions.push({
          ...filter,
          id: uuid(),
          field: filter.field?.replace('label.', '') || ''
        })
      } else if (filter.field.startsWith('annotation.')) {
        annotationConditions.push({
          ...filter,
          id: uuid(),
          field: filter.field?.replace('annotation.', '') || ''
        })
      } else {
        defaultConditions.push({ ...filter, id: uuid() })
      }
    })

    if (defaultConditions.length) {
      filterList.push({
        ...generateEmptyFilter('default'),
        conditions: defaultConditions
      })
    }

    if (labelConditions.length) {
      filterList.push({
        ...generateEmptyFilter('label'),
        conditions: labelConditions
      })
    }

    if (annotationConditions.length) {
      filterList.push({
        ...generateEmptyFilter('annotation'),
        conditions: annotationConditions
      })
    }
    return filterList
  }

// ************************************************************
// Function to format the data into something the graph can use
// ************************************************************
export const convertTFocusGroupToTPlotSeries = (
  data: TFocusGroup[],
  dateProperty: string,
  valueProperty: string
) => {
  const seriesDataGroups = new Map<string, TPlotSeries>()

  if (!Array.isArray(data) || data.length === 0) {
    return []
  }
  const series: TPlotSeries[] = []
  // convert the data to time series
  data?.forEach((key, i) => {
    Object.entries(key).forEach(([k, value]) => {
      const x = getValue(value, dateProperty, true)
      const y = getValue(value, valueProperty, false)
      if (seriesDataGroups.has(k)) {
        // ***********************
        // Update an existing item
        // ***********************
        const sdata = seriesDataGroups.get(k)
        sdata?.data.push({ x, y })
      } else {
        // ******************
        // Create an new item
        // ******************
        const randomColor = rndColors[i]
        const item: TPlotSeries = {
          color: randomColor,
          label: k,
          data: []
        }
        item.data.push({ x, y })
        seriesDataGroups.set(k, item)
        series.push(item)
      }
    })
  })
  return series
}

// ***************************************
// Convert the FocusGroup to BarChart data
// ***************************************
export const convertTFocusGroupToBarChartSeries = (
  data: TFocusGroup[],
  dateProperty: string,
  valueProperty: string
) => {
  const seriesDataGroups = new Map<string, object>()

  /*
export const data = [
  { month: 'January', Smartphones: 1200, Laptops: 900, Tablets: 200 },
  { month: 'February', Smartphones: 1900, Laptops: 1200, Tablets: 400 },
  { month: 'March', Smartphones: 400, Laptops: 1000, Tablets: 200 },
  { month: 'April', Smartphones: 1000, Laptops: 200, Tablets: 800 },
  { month: 'May', Smartphones: 800, Laptops: 1400, Tablets: 1200 },
  { month: 'June', Smartphones: 750, Laptops: 600, Tablets: 1000 },
];
  */
  if (!Array.isArray(data) || data.length === 0) {
    return []
  }
  const series: object[] = []
  // convert the data to time series
  data?.forEach((key) => {
    Object.entries(key).forEach(([k, value]) => {
      const x = getStringValue(value, dateProperty)
      const y = getValue(value, valueProperty, false)
      const parts = x.split(' ')
      const newKey = parts[0].replaceAll(':', '').replaceAll('-', '').replaceAll(' ', '').replaceAll('.', '')
      if (seriesDataGroups.has(newKey)) {
        // ***********************
        // Update an existing item
        // ***********************
        const tmpData = seriesDataGroups.get(newKey)
        if (tmpData) {
          if (k in tmpData) {
            const tVal = (tmpData as any)
            tVal[k] += y
          } else {
            Object.defineProperty(tmpData, k, {
              value: y,
              writable: true
            });
          }
        }
      } else {
        // ******************
        // Create an new item
        // ******************
        const item = {
          date: parts[0],
          [k]: y,
        }
        seriesDataGroups.set(newKey, item)
      }
    })
  })
  seriesDataGroups.forEach((v) => {
    series.push(v)
  });
  return series
}

export const convertTFocusGroupToBarChartSeriesKey = (
  data: TFocusGroup[],
) => {
  if (!Array.isArray(data) || data.length === 0) {
    return []
  }
  const seriesKey: AreaChartSeries[] = []
  const seriesDataGroups = new Map<string, string>()
  // convert the data to time series
  const cols = CHART_COLORS
  let colInt = 0
  data?.forEach((key) => {
    Object.entries(key).forEach(([k]) => {
      // const randomColor = rndColors[i]
      if (!seriesDataGroups.has(k)) {
        seriesDataGroups.set(k, k)
        if (colInt >= cols.length) { colInt = 0 }
        seriesKey.push({ name: k, color: cols[colInt] })
        colInt++
      }
    })
  })
  return seriesKey
}
