import {
  createContext,
  ReactElement,
  useCallback,
  useMemo,
  useState
} from 'react'

import {
  CatalogPackage,
  CatalogPackageVersion
} from 'services/catalog.interface'
import { apiErrorHandler, axios } from 'utils'

interface CatalogContextType {
  getCatalogPackages: CallableFunction
  catalogPackages: CatalogPackage[]
  getCatalogPackageVersionDetails: CallableFunction
  catalogPackageVersion: CatalogPackageVersion | null
  loading: boolean
}

const CatalogContext = createContext<CatalogContextType>(
  {} as CatalogContextType
)

export function CatalogContextProvider({
  children
}: {
  children: ReactElement
}) {
  const [catalogPackages, setCatalogPackages] = useState<CatalogPackage[]>([])
  const [catalogPackageVersion, setCatalogPackageVersion] = useState<CatalogPackageVersion | null>(
    null
  )
  const [loading, setLoading] = useState<boolean>(true)

  const getCatalogPackages = useCallback(
    async (catalogId: string) => {
      setLoading(true)
      try {
        const { data } = await axios.get(`/catalogs/${catalogId}/packages`)
        setCatalogPackages(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        apiErrorHandler(error)
      }
    },
    [setCatalogPackages]
  )

  const getCatalogPackageVersionDetails = useCallback(
    async (catalogId: string, packageName: string, version: string) => {
      setLoading(true)
      try {
        const { data } = await axios.get(`/catalogs/${catalogId}/packages/${packageName}/${version}`)
        setCatalogPackageVersion(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        apiErrorHandler(error)
      }
    },
    [setCatalogPackageVersion]
  )

  const value = useMemo<CatalogContextType>(
    () => ({
      getCatalogPackages,
      catalogPackages,
      getCatalogPackageVersionDetails,
      catalogPackageVersion,
      loading
    }),
    [
      getCatalogPackages,
      catalogPackages,
      getCatalogPackageVersionDetails,
      catalogPackageVersion,
      loading
    ]
  )

  return (
    <CatalogContext.Provider value={value}>
      {children}
    </CatalogContext.Provider>
  )
}

export default CatalogContext
