import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import { TConfiguration } from 'services/config.interface'
import useConfiguration from 'hooks/useConfiguration'
import {
  Backdrop,
  FETCH_STATUS,
  FetchStatus,
  GraphCard,
  GraphCardGroup,
  GraphCardWrapper,
  SkeletonWrapper,
  Spinner
} from 'components'
import ConfigurationCard from './ConfigurationCard'

function Configuration() {
  const { getConfigurations, updateConfigurations } = useConfiguration()

  const [isFetched, setFeteched] = useState(false)
  const [fetchConfigurationStatus, setFetchConfigurationStatus] = useState(
    FETCH_STATUS.START
  )
  const [updateConfigurationsStatus, setUpdateConfigurationsStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [configurations, setConfigurations] = useState<TConfiguration[]>([])

  const initialLoading = useMemo(() => {
    return !isFetched && fetchConfigurationStatus.isLoading
  }, [isFetched, fetchConfigurationStatus])

  const handleChange = (path: string, value: string) => {
    setConfigurations(_.set(_.cloneDeep(configurations), path, value))
  }

  const fetchConfiguration = useCallback(async () => {
    const configs = await getConfigurations(
      undefined,
      setFetchConfigurationStatus
    )
    setConfigurations(configs)
    setFeteched(true)
  }, [getConfigurations])

  const handleUpdate = async () => {
    await updateConfigurations(configurations, setUpdateConfigurationsStatus)
    fetchConfiguration()
  }

  useEffect(() => {
    fetchConfiguration()
  }, [fetchConfiguration])

  return (
    <GraphCardWrapper>
      <SkeletonWrapper
        isLoading={initialLoading}
        count={2}
        inline
        height={40}
        className="w-48"
        containerClassName="flex justify-between"
      >
        <div className="flex items-center justify-between">
          <p className="text-xl font-medium">Configuration</p>
          <button
            disabled={updateConfigurationsStatus.isLoading}
            type="button"
            onClick={handleUpdate}
            className="bg-primary-color text-white rounded-md py-2.5 px-7 disabled:bg-gray-200"
          >
            Save
          </button>
        </div>
      </SkeletonWrapper>

      <GraphCardGroup grow>
        <GraphCard isLoading={initialLoading} isOutline={false}>
          <FetchStatus
            status={fetchConfigurationStatus}
            retry={fetchConfiguration}
            height="100%"
          >
            <div className="flex flex-col gap-6">
              {configurations.map((config, index) => (
                <ConfigurationCard
                  key={index}
                  parentPath={`[${index}]`}
                  config={config}
                  onChange={handleChange}
                />
              ))}
            </div>
          </FetchStatus>
        </GraphCard>
      </GraphCardGroup>

      <Backdrop isLoading={updateConfigurationsStatus.isLoading}>
        <Spinner size={40} />
      </Backdrop>
    </GraphCardWrapper>
  )
}

export default Configuration
