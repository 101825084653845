/* eslint-disable react/destructuring-assignment */
import AutoSelectBase, {
  GroupBase,
  Props as AutoSelectBaseProps
} from 'react-select'

function AutoSelect<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: AutoSelectBaseProps<OptionType, IsMulti, GroupType>) {
  return (
    <AutoSelectBase
      isSearchable
      {...props}
      styles={{
        ...props.styles,
        container: (provided) => ({
          ...provided,
          width: '100%'
        }),
        control: (provided) => ({
          ...provided,
          borderRadius: '0.5rem',
          border: '1px solid rgb(191 208 219)',
          maxHeight: '34px',
          minHeight: 'unset'
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingTop: 0
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          marginTop: -3
        })
      }}
    />
  )
}

export default AutoSelect
