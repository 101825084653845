import { IconCheck, IconX } from '@tabler/icons-react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'

function Component(
  place: JSX.Element,
  tag: string,
  type: string,
  icon: JSX.Element,
  deleteButton: JSX.Element,
  onClick?: () => void,
) {
  let border = 'border-4 border-[#787885] rounded-xl text-[#787885] bg-[#ededf0]'
  let chipIcon = <div />
  let styling = 'w-full flex justify-center'

  if (type === 'selected') {
    border = 'border-4 border-[#65b168] rounded-xl text-[#65b168] bg-[#ecf7ed]'
    chipIcon = <IconCheck size={30} />
    styling = 'w-full flex justify-between'
  }

  if (type === 'notAllowed') {
    border = 'border-4 border-[#ef6292] rounded-xl text-[#ef6292] bg-[#fdedf2]'
    chipIcon = <IconX size={30} />
    styling = 'w-full flex justify-between'
  }

  if (type === 'withNumber') {
    border = 'border-4 border-[#7985cb] rounded-xl text-[#7985cb] bg-[#eff0fa]'
    styling = 'w-full flex justify-between bg-[#eff0fa]'
  }

  return (
    <div
      className={border}
      style={{ width: '100%' }}
      onClick={onClick}
    >
      <div className="flex w-full h-full align-middle justify-around hover:cursor-pointer select-none p-3">
        <span className="">{chipIcon}</span>
        <span className={styling}>
          {place} {tag} <div className="flex">{deleteButton} {icon}</div>
        </span>
      </div>
    </div>
  )
}
export function selectionComponent(tag: string, onClick: () => void) {
  return Component(<div />, tag, 'selection', <div />, <div />, onClick)
}
export function selectedComponent(tag: string, onClick: () => void) {
  return Component(<div />, tag, 'selected', <div />, <div />, onClick)
}
export function notAllowedComponent(tag: string, onClick: () => void) {
  return Component(<div />, tag, 'notAllowed', <div />, <div />, onClick)
}
export function componentWithNumber(
  tag: string,
  place: number,
  maxNumber: number,
  upCallback: (el: string) => void,
  downCallback: (el: string) => void,
  deleteCallback: () => void,
) {
  const upButton = <button type="button" onClick={() => { upCallback(tag) }}><FaAngleUp /></button>
  const downButton = <button type="button" onClick={() => { downCallback(tag) }}><FaAngleDown /></button>
  const deleteButton = <button type="button" onClick={() => { deleteCallback() }}><IconX size={20} /></button>
  let Element = <div className="grid">{upButton}{downButton}</div>
  if (place === maxNumber) Element = <div>{upButton}</div>
  if (place === 0) Element = <div>{downButton}</div>
  return Component(<div>#{place}: </div>, tag, 'withNumber', Element, deleteButton)
}
