import React, { useMemo } from 'react'
import _ from 'lodash'
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown
} from 'react-icons/md'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from 'react-beautiful-dnd'

import ButtonIcon from 'components/button-icon'
import { mapClasses } from 'utils'
import useTableContext from './useTableContext'
import { sortTableData } from './table.utils'

import { TTableData } from './types'

const SPACE_WITH = 25

interface GroupPrefixProps {
  isExpand: boolean
  level: number
  hasChildren: boolean
  onToggle: () => void
}

function GroupPrefix({
  isExpand,
  level,
  hasChildren,
  onToggle
}: GroupPrefixProps) {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onToggle()
  }

  return (
    <div className="flex items-center">
      <span style={{ width: SPACE_WITH * level }} />

      {hasChildren && (
        <div style={{ width: SPACE_WITH }}>
          <ButtonIcon onClick={handleClick}>
            {isExpand ? (
              <MdOutlineKeyboardArrowDown className="inline text-lg" />
            ) : (
              <MdOutlineKeyboardArrowRight className="inline text-lg" />
            )}
          </ButtonIcon>
        </div>
      )}

      {!hasChildren && <span style={{ width: SPACE_WITH }} />}
    </div>
  )
}

interface TableBodyRowBaseProps {
  groupLevel?: number
  rowData: TTableData
  draggableProvided?: DraggableProvided
  draggableState?: DraggableStateSnapshot
}

function TableBodyRowBase({
  groupLevel = 0,
  rowData,
  draggableProvided,
  draggableState
}: TableBodyRowBaseProps) {
  const {
    droppableId,
    extendRowIdList,
    toggleExtendRowId,
    groupChildrenField,
    columns,
    formatOptions,
    order,
    orderBy,
    selectedRowIdList,
    onRowClick
  } = useTableContext()

  const isExpand = useMemo(() => {
    return !!rowData._id && !!extendRowIdList.includes(rowData._id)
  }, [rowData._id, extendRowIdList])

  const childrenRowDataList = useMemo<TTableData[]>(() => {
    const dataList = _.get(rowData, groupChildrenField || '') || []
    return sortTableData(dataList, order, orderBy)
  }, [rowData, groupChildrenField, order, orderBy])

  const handleToggle = () => {
    if (!rowData._id) return
    toggleExtendRowId(rowData._id, !isExpand)
  }
  return (
    <>
      <tr
        className={mapClasses(
          'hover:bg-gray-100 border-b border-gray-200 border-solid',
          droppableId && 'bg-white !left-auto !top-auto',
          draggableState?.isDragging && '!border-white !bg-gray-200',
          (selectedRowIdList?.includes(rowData._id || '') || selectedRowIdList?.includes(rowData._rawData?.id || '')) && '!bg-gray-200',
          onRowClick && 'cursor-pointer'
        )}
        onClick={() => onRowClick?.(rowData)}
        ref={draggableProvided?.innerRef}
        {...draggableProvided?.draggableProps}
        {...draggableProvided?.dragHandleProps}
      >
        {columns.map((column, columnIndex) => {
          const parts = column.field.split('.')
          let data = rowData
          parts.forEach((p) => {
            data = data[p]
          })
          const value = data || ''
          // const value = rowData[column.field] || ''
          const render = column.render || column.format || (() => value)

          return (
            <td
              key={`table-key-${columnIndex}`}
              style={{ width: column.width }}
              className="px-4 py-3 break-words text-sm text-gray-800 border-r border-gray-300"
            >
              <div className="flex items-center w-full h-10">
                {!!groupChildrenField && columnIndex === 0 && (
                  <GroupPrefix
                    isExpand={isExpand}
                    level={groupLevel}
                    hasChildren={!!childrenRowDataList.length}
                    onToggle={handleToggle}
                  />
                )}

                <span className="w-full h-fit">
                  {render(value, { ...formatOptions, row: rowData })}
                </span>
              </div>
            </td>
          )
        })}
      </tr>

      {isExpand &&
        childrenRowDataList.map((childrenRowData, groupChildIndex) => (
          <TableBodyRowBase
            key={groupChildIndex}
            groupLevel={groupLevel + 1}
            rowData={childrenRowData}
          />
        ))}
    </>
  )
}

interface TableBodyRowProps
  extends Omit<TableBodyRowBaseProps, 'draggableProvided'> {
  draggableIndex?: number
  draggableId?: string
}

function TableBodyRow({
  draggableId,
  draggableIndex,
  ...props
}: TableBodyRowProps) {
  const { droppableId, draggable } = useTableContext()

  if (!droppableId || !draggableId || _.isNil(draggableIndex)) {
    return <TableBodyRowBase {...props} />
  }

  return (
    <Draggable
      draggableId={`${droppableId}_${draggableId}`}
      isDragDisabled={!draggable}
      index={draggableIndex}
    >
      {(draggableProvided, draggableState) => (
        <TableBodyRowBase
          {...props}
          draggableProvided={draggableProvided}
          draggableState={draggableState}
        />
      )}
    </Draggable>
  )
}

export default TableBodyRow
