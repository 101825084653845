import { TCost } from 'services/cost.interface'
import { roundNumber } from './formatNumber'
import { isNil } from './isNil';

interface AllocationTotalValues {
  totalCost: number;
  totalEfficiency: number;
  cpuEfficiency: number;
  ramEfficiency: number;
  withNull: {
    totalCost: number | null;
    totalEfficiency: number | null;
    cpuEfficiency: number | null;
    ramEfficiency: number | null;
  }
}

/**
 *
 * @param allocationList
 *
 * [
 *
 *    {
 *      dome: { ..., totalCost: 1, totalEfficiency: 1, ... },
 *      dashboard: { ..., totalCost: 1, totalEfficiency: 1, ... }
 *    },
 *
 *    {
 *      dome: { ..., totalCost: 2, totalEfficiency: 2, ... },
 *    },
 *
 * ]
 *
 * @returns
 *  {
 *    dome: { totalCost: 3, totalEfficiency: 3, ... },
 *    dashboard: { totalCost: 1, totalEfficiency: 1, ... }
 * }
 */
export const mergeAllocationTotals = (allocationList: TCost[]) => {
  const result: Record<string, AllocationTotalValues> = {};

  allocationList.forEach((allocation) => {
    Object.entries(allocation).forEach(([key, values]) => {
      if (!result[key]) {
        result[key] = {
          totalCost: 0,
          totalEfficiency: 0,
          cpuEfficiency: 0,
          ramEfficiency: 0,
          withNull: {
            totalCost: null,
            totalEfficiency: null,
            cpuEfficiency: null,
            ramEfficiency: null,
          },
        };
      }
      result[key].totalCost += values.totalCost || 0;
      result[key].totalEfficiency += values.totalEfficiency || 0;
      result[key].cpuEfficiency += values.cpuEfficiency || 0;
      result[key].ramEfficiency += values.ramEfficiency || 0;
    })
  });

  // round all values
  Object.entries(result).forEach(([key, values]) => {
    result[key].totalCost = roundNumber(values.totalCost);
    result[key].totalEfficiency = roundNumber(values.totalEfficiency);
    result[key].cpuEfficiency = roundNumber(values.cpuEfficiency);
    result[key].ramEfficiency = roundNumber(values.ramEfficiency);
  })

  // apply value with "null"
  allocationList.forEach((allocation) => {
    Object.entries(allocation).forEach(([key, values]) => {
      if (!isNil(values.totalCost)) {
        result[key].withNull.totalCost = result[key].totalCost;
      }
      if (!isNil(values.totalEfficiency)) {
        result[key].withNull.totalEfficiency = result[key].totalEfficiency;
      }
      if (!isNil(values.cpuEfficiency)) {
        result[key].withNull.cpuEfficiency = result[key].cpuEfficiency;
      }
      if (!isNil(values.ramEfficiency)) {
        result[key].withNull.ramEfficiency = result[key].ramEfficiency;
      }
    })
  });

  return result;
}
