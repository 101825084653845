//--------------------------------------------------------------------------
// index.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { useState } from 'react';
import { DatePicker } from '@mantine/dates';
import { Popover } from '@mantine/core';
import classes from './style.module.css'
import { getLastMonth, getThisMonth, getToday, getYesterday, lastWeek, thisWeek } from './helpFunction';

//--------------------------------------------------------------------------
// The interface for the datepicker
//--------------------------------------------------------------------------
interface DatePickerProps {
  window: [Date | null, Date | null],
  setWindow: (newWindow: [Date | null, Date | null]) => void
}
//--------------------------------------------------------------------------
// The datepicker, this was created by combining the mantine Popover and the
// mantine datepicker. We chose to do this ourself instead of using the
// DatePickerInput because this allows us to have more control of the element.
// We wanted to add buttons for today, yesterday, tis week, last week, this
// month and last month.
//--------------------------------------------------------------------------
export function DateRangePicker({ window, setWindow }: DatePickerProps) {
  //--------------------------------------------------------------------------
  // The states and constants used in this element.
  //--------------------------------------------------------------------------
  const [open, setOpen] = useState<boolean>(false)
  const accentColor = '#668957'
  const choices = [
    { name: 'Today', value: getToday() },
    { name: 'Yesterday', value: getYesterday() },
    { name: 'This Week', value: thisWeek() },
    { name: 'Last Week', value: lastWeek() },
    { name: 'This Month', value: getThisMonth() },
    { name: 'Last Month', value: getLastMonth() }
  ]
  //--------------------------------------------------------------------------
  // Returns the actual element. styles are made following this tutorial:
  // https://mantine.dev/styles/css-modules/#adding-styles-to-mantine-components
  // And using the properties at the bottom of this page:
  // https://mantine.dev/dates/date-picker/?t=styles-api
  //--------------------------------------------------------------------------
  return (
    <Popover width={450} withArrow arrowOffset={8} opened={open}>
      <Popover.Target>
        <div onClick={() => setOpen(!open)}>
          <span className="text-gray-500 font-semibold">
            {`${window[0]?.toISOString().split('T')[0]}, ${window[1]?.toISOString().split('T')[0]}`}
          </span>
        </div>
      </Popover.Target>
      <Popover.Dropdown style={{ border: '1px solid black', padding: 0 }}>
        <div className="flex w-full">
          <div className="flex flex-col w-2/5">
            {
              choices.map((el, idx) => {
                return (
                  <button
                    className={`hover:bg-[#93ac89] hover:text-white w-full h-full px-2 py-1 ${idx === choices.length - 1 ? '' : 'border-b border-gray-300'}`}
                    type="button"
                    onClick={() => {
                      setWindow(el.value as [Date | null, Date | null])
                      setOpen(!open)
                      // setWindow()
                    }}
                  >
                    {el.name}
                  </button>
                )
              })
            }
          </div>
          {open && (
            <div className="w-full border-l border-gray-300">
              <DatePicker
                w={300}
                className="mx-2"
                type="range"
                defaultDate={window[0] !== null ? window[0] : undefined}
                classNames={{ day: classes.day }}
                value={window}
                onChange={setWindow}
                highlightToday
                styles={{
                  day: {
                    '[data-selected]': {
                      backgroundColor: `${accentColor}`,
                      color: 'white',
                    },
                  },
                }}
              />
            </div>
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
