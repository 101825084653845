import { TTableColumn } from 'components'
import { formatCost, formatNilValue, formatPercent } from 'utils'
import { optimizedDataCheckmarkType } from './helper'

export const OptimizationTableColumns: TTableColumn[] = [
  {
    name: 'Done',
    field: 'done',
    width: '6%',
    sortable: false,
    filterable: false,
    render: (done: optimizedDataCheckmarkType) => (
      <input
        type="checkbox"
        id="myCheck"
        checked={done.value}
        onChange={done.callback}
      />
    )
  },
  {
    name: 'Name of resource',
    field: 'name',
    width: '20%',
    format: formatNilValue
  },
  {
    name: 'Action to take',
    field: 'action',
    width: '20%',
    format: formatNilValue
  },
  {
    name: 'Monthly Cost',
    field: 'monthlyCost',
    width: '13%',
    format: formatCost
  },
  {
    name: 'Estimated monthly savings',
    field: 'monthlySaving',
    width: '13%',
    format: formatCost
  },
  {
    name: 'Saving percentage',
    field: 'savingPercentage',
    width: '13%',
    format: formatPercent
  },
  {
    name: 'Restart needed',
    field: 'restart',
    width: '7%',
    filterable: false,
    format: formatNilValue
  },
  {
    name: 'Rollback possible',
    field: 'rollback',
    width: '7%',
    filterable: false,
    format: formatNilValue
  }
]
