import { ReactNode, forwardRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import { mapClasses } from 'utils'
import { MIN_HEIGHT, SPACING } from 'constants/sizes.constants'

interface GraphCardProps {
  isLoading?: boolean
  isHide?: boolean
  isHoverable?: boolean
  isOutline?: boolean
  grow?: number
  spacing?: number
  direction?: 'row' | 'col'
  height?: number
  skeletonHeight?: number
  wrapperClassname?: string
  className?: string
  onClick?: () => void
  children?: ReactNode
}

const GraphCard = forwardRef<HTMLDivElement, GraphCardProps>(
  (
    {
      isLoading,
      isHide,
      isHoverable,
      isOutline = true,
      grow = 1,
      spacing = SPACING.GRAPH_CARD,
      direction = 'col',
      height,
      wrapperClassname,
      className,
      skeletonHeight,
      onClick,
      children
    },
    ref
  ) => {
    if (isLoading) {
      return (
        <div
          className={mapClasses(
            'grow shrink basis-0 min-w-0 w-full',
            isHide && 'opacity-0',
            className
          )}
          style={{ flexGrow: grow, height }}
        >
          <Skeleton
            count={1}
            height={height || skeletonHeight || '100%'}
            style={{
              minHeight: height || skeletonHeight || MIN_HEIGHT.GRAPH_CONTENT
            }}
          />
        </div>
      )
    }

    return (
      <div
        ref={ref}
        className={mapClasses(
          'grow shrink basis-0 min-w-0 w-full flex flex-col',
          isOutline && 'border border-dark-grey rounded-md',
          isHide && 'opacity-0 hidden large:flex',
          isHoverable && 'cursor-pointer hover:bg-gray-100',
          wrapperClassname
        )}
        style={{ flexGrow: grow, height, flexBasis: height }}
        onClick={onClick}
      >
        {!isHide && (
          <div
            className={mapClasses(
              'grow shrink basis-0 min-w-0 w-full flex',
              direction === 'row' ? 'flex-row' : 'flex-col',
              isOutline && 'px-4 py-3',
              className
            )}
            style={{ gap: spacing, height }}
          >
            {children}
          </div>
        )}
      </div>
    )
  }
)

export default GraphCard
