import { createContext, ReactElement, useCallback, useMemo } from 'react'

import {
  TFetchApplicationYamlFunction,
  TFetchApplicationDetailFunction,
  TFetchUncategorizedComponentsFunction,
  TCreateApplicationFunction,
  TUpdateApplicationFunction,
  TDeleteApplicationFunction
} from 'services/fetch.interface'
import { TApplication, TApplicationYaml } from 'services/application.interface'
import { TComponent } from 'services/component.interface'
import { apiErrorHandler, axios } from 'utils'
import { FETCH_STATUS } from 'components'

interface ApplicationContextType {
  getApplicationYaml: TFetchApplicationYamlFunction
  getUncategorizedComponents: TFetchUncategorizedComponentsFunction
  getApplicationDetail: TFetchApplicationDetailFunction
  createApplication: TCreateApplicationFunction
  updateApplication: TUpdateApplicationFunction
  deleteApplication: TDeleteApplicationFunction
}

const ApplicationContext = createContext<ApplicationContextType>(
  {} as ApplicationContextType
)

export function ApplicationContextProvider({
  children
}: {
  children: ReactElement
}) {
  const getApplicationYaml = useCallback<TFetchApplicationYamlFunction>(
    async (params, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<{
          resource: TApplicationYaml
        }>('/resource', { params })
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data?.resource || {}
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return {}
      }
    },
    []
  )

  const getUncategorizedComponents =
    useCallback<TFetchUncategorizedComponentsFunction>(
      async (_, updateStatus) => {
        try {
          updateStatus?.(FETCH_STATUS.START)
          const { data } = await axios.get<TComponent[]>('/components')
          updateStatus?.(FETCH_STATUS.SUCCESS)
          return data || []
        } catch (error) {
          updateStatus?.(FETCH_STATUS.FAIL)
          apiErrorHandler(error)
          return []
        }
      },
      []
    )

  const getApplicationDetail = useCallback<TFetchApplicationDetailFunction>(
    async ({ projectId, applicationId }, updateStatus) => {
      const url = `/projects/${projectId}/applications/${applicationId}`

      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<TApplication>(url)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || { _type: 'application', name: '' }
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return { _type: 'application', name: '' }
      }
    },
    []
  )

  const createApplication = useCallback<TCreateApplicationFunction>(
    async ({ projectId, body }, updateStatus) => {
      const url = `/projects/${projectId}/applications`

      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.post<TApplication>(url, body)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || { _type: 'application', name: '' }
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return { _type: 'application', name: '' }
      }
    },
    []
  )

  const updateApplication = useCallback<TUpdateApplicationFunction>(
    async ({ projectId, applicationId, body }, updateStatus) => {
      const url = `/projects/${projectId}/applications/${applicationId}`

      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.put<TApplication>(url, body)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || { _type: 'application', name: '' }
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return { _type: 'application', name: '' }
      }
    },
    []
  )

  const deleteApplication = useCallback<TDeleteApplicationFunction>(
    async ({ projectId, applicationId }, updateStatus) => {
      const url = `/projects/${projectId}/applications/${applicationId}`

      try {
        updateStatus?.(FETCH_STATUS.START)
        await axios.delete<TApplication>(url)
        updateStatus?.(FETCH_STATUS.SUCCESS)
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
      }
    },
    []
  )

  const value = useMemo<ApplicationContextType>(
    () => ({
      getApplicationYaml,
      getUncategorizedComponents,
      getApplicationDetail,
      createApplication,
      updateApplication,
      deleteApplication
    }),
    [
      getApplicationYaml,
      getUncategorizedComponents,
      getApplicationDetail,
      createApplication,
      updateApplication,
      deleteApplication
    ]
  )

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  )
}

export default ApplicationContext
