import cloneDeep from 'lodash/cloneDeep'

import { TCurrency } from 'services/cost.interface'
import { TFilter, TTableColumn, TTableData } from './types'

export const addNessessoryFields = ({
  data,
  childrenField,
  idPrefix
}: {
  data: TTableData[]
  childrenField?: string
  idPrefix?: string
}) => {
  return data.map((rowData, index) => {
    const newRowData: TTableData = {
      ...rowData,
      _id: `${idPrefix || ''}${index}`,
      _index: index,
      _rawData: rowData
    }
    const subData: TTableData[] = newRowData[childrenField || ''] || []

    if (childrenField && subData) {
      newRowData[childrenField] = addNessessoryFields({
        data: subData,
        childrenField,
        idPrefix: `${index}_`
      })
    }

    return newRowData
  })
}

export const flatTableRowDataWithChildren = (
  data: TTableData[],
  childrenField?: string
) => {
  if (!childrenField) return data

  let list: TTableData[] = []

  data.forEach((dataRow) => {
    list.push(cloneDeep(dataRow))

    if (dataRow[childrenField]) {
      const childrenList = flatTableRowDataWithChildren(
        dataRow[childrenField],
        childrenField
      )
      list = [...list, ...cloneDeep(childrenList)]
    }
  })

  return list
}

export const getFilterOptions = ({
  data,
  childrenField,
  column,
  formatOptions
}: {
  data: TTableData[]
  column: TTableColumn
  formatOptions?: { currency?: TCurrency }
  childrenField?: string
}) => {
  const options: string[] = []

  data.forEach((rowData) => {
    const params = { ...formatOptions, row: rowData }
    const newOption = column.format
      ? String(column.format(rowData[column.field], params))
      : String(rowData[column.field])
    if (newOption) options.push(newOption)

    // get children's options
    const childrenData: TTableData[] = rowData[childrenField || ''] || []
    const childrenOptions = getFilterOptions({
      data: childrenData,
      childrenField,
      column,
      formatOptions
    })
    options.push(...childrenOptions)
  })

  return options
}

export const sortTableData = (
  dataList: TTableData[],
  order: string,
  orderBy: string
) => {
  return [...dataList].sort((prev, next) => {
    if (prev[orderBy] === next[orderBy]) return 0
    let orderNumber = (prev[orderBy] || 0) > (next[orderBy] || 0) ? 1 : -1
    if (order === 'desc') orderNumber *= -1
    return orderNumber
  })
}

export const filterTableData = (
  data: TTableData[],
  filters: TFilter[],
  formatOptions?: { currency?: TCurrency },
  groupChildrenField?: string
) => {
  if (!Object.keys(filters).length) return data

  const result: TTableData[] = []

  data.forEach((item) => {
    let matched = true

    filters.forEach(({ field, values, format }) => {
      if (!matched) return
      matched = values.includes(
        String(format(item[field], { ...formatOptions, row: item }))
      )

      if (matched) {
        result.push(item)
      } else {
        const subData: TTableData[] = item[groupChildrenField || ''] || []

        // check if children rows are matched
        if (!matched && subData?.length) {
          const filteredSubData = filterTableData(
            subData,
            filters,
            formatOptions,
            groupChildrenField
          )

          if (filteredSubData?.length) {
            result.push({
              ...item,
              [groupChildrenField || '']: filteredSubData
            })
          }
        }
      }
    })
  })

  return result
}
