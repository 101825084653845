import {
  MutableRefObject,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react'

function usePopup(
  ref: MutableRefObject<HTMLElement | null>,
  configs?: { isPreventClickOutside: boolean }
) {
  const keepRef = useRef({ forceClose: false })
  const [isOpen, setIsOpen] = useState(false)

  const handleClickOutSideOfPopup = useCallback(
    (e: any) => {
      if (configs?.isPreventClickOutside) return

      const path = e.path || (e.composedPath && e.composedPath())
      if (path && !path.includes(ref.current) && isOpen) {
        setIsOpen(false)
      }
    },
    [ref, isOpen, configs]
  )

  const openPopup = useCallback(() => {
    if (!keepRef.current.forceClose) {
      setIsOpen(true)
    }
    keepRef.current.forceClose = false
  }, [])
  const closePopup = useCallback(() => {
    setIsOpen(false)
    keepRef.current.forceClose = true
  }, [])
  const togglePopup = useCallback(() => setIsOpen(!isOpen), [isOpen])

  useEffect(() => {
    if (ref.current && isOpen) {
      document.addEventListener('mouseup', handleClickOutSideOfPopup)
    }
    return () => {
      document.removeEventListener('mouseup', handleClickOutSideOfPopup)
    }
  }, [ref, handleClickOutSideOfPopup, isOpen])

  return {
    isOpen,
    closePopup,
    openPopup,
    togglePopup
  }
}

export default usePopup
