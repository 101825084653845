import { ReactNode } from 'react'

import Backdrop from 'components/backdrop'
import Spinner from 'components/spinner'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'components/modal'

interface ConfirmModalProps {
  isOpen: boolean
  isLoading?: boolean
  title?: string | ReactNode
  description?: string | ReactNode
  onClose: () => void
  onConfirm: () => void
}

function ConfirmModal({
  isOpen,
  isLoading,
  title = '',
  description = '',
  onClose,
  onConfirm
}: ConfirmModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader className="text-red-500" onClose={onClose}>
        {title}
      </ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter className="gap-4">
        <button
          type="button"
          className="rounded-lg hover:bg-blue-500 hover:bg-opacity-10 py-1 px-4 text-blue-500"
          onClick={onClose}
        >
          No
        </button>
        <button
          type="button"
          className="rounded-lg hover:bg-red-500 hover:bg-opacity-10 py-1 px-4 text-red-500"
          onClick={onConfirm}
        >
          Yes
        </button>
      </ModalFooter>

      <Backdrop isLoading={isLoading}>
        <Spinner size={40} />
      </Backdrop>
    </Modal>
  )
}

export default ConfirmModal
