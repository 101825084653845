export function mapClasses(...params: (string | boolean | undefined)[]) {
  let className = ''

  params.forEach((item) => {
    if (item) {
      className += ` ${item}`
    }
  })

  return className.trim()
}
