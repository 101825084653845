//--------------------------------------------------------------------------
// LabelRuleCreator.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { useEffect, useState } from 'react';
import { effectType, labelRuleType, ruleType } from 'pages/label-rules/queryHelperFunctions';
import PatternMatchedInput from 'components/regex-input/PatternMatchedInput';
import { re, reTest } from 'pages/advanced-reporting/mutation-new-report-modal';
import { LRComponent } from './LRComponent'

//--------------------------------------------------------------------------
// The interface for the LabelRuleCreator object.
//--------------------------------------------------------------------------
interface LabelRuleCreatorProps {
  labelRule: labelRuleType,
  setLabelRuleCallBack: (rules: labelRuleType) => void
  onButtonClick: (labelRule: labelRuleType) => void
}

//--------------------------------------------------------------------------
// The function for the LabelRuleCreator object.
//--------------------------------------------------------------------------
export function LabelRuleCreator({
  labelRule,
  setLabelRuleCallBack,
  onButtonClick
}: LabelRuleCreatorProps) {
  //--------------------------------------------------------------------------
  // The states that will be used throughout the function.
  //--------------------------------------------------------------------------
  const [curLabelRule, setCurLabelRule] = useState<labelRuleType>(labelRule)
  //--------------------------------------------------------------------------
  // Once the function loads in the received labelrule set this as your
  // current labelRule.
  //--------------------------------------------------------------------------
  useEffect(() => {
    setCurLabelRule(labelRule)
  }, [labelRule])
  //--------------------------------------------------------------------------
  // This function will replace the labelrule with a labelrule that has the
  // name changed.
  //--------------------------------------------------------------------------
  function changeName(str: string) {
    setCurLabelRule({
      name: str,
      description: curLabelRule.description,
      enabled: curLabelRule.enabled,
      rules: curLabelRule.rules,
      effects: curLabelRule.effects
    })
  }
  //--------------------------------------------------------------------------
  // This function will replace the labelrule with a labelrule that has the
  // rules in it changed.
  //--------------------------------------------------------------------------
  function changeRules(rules: ruleType[]) {
    setLabelRuleCallBack({
      name: curLabelRule.name,
      description: curLabelRule.description,
      enabled: curLabelRule.enabled,
      rules,
      effects: curLabelRule.effects
    })
    setCurLabelRule({
      name: curLabelRule.name,
      description: curLabelRule.description,
      enabled: curLabelRule.enabled,
      rules,
      effects: curLabelRule.effects
    })
  }
  //--------------------------------------------------------------------------
  // This function will replace the labelrule with a labelrule that has the
  // description in it changed.
  //--------------------------------------------------------------------------
  function changeDescription(str: string) {
    setCurLabelRule({
      name: curLabelRule.name,
      description: str,
      enabled: curLabelRule.enabled,
      rules: curLabelRule.rules,
      effects: curLabelRule.effects
    })
  }
  //--------------------------------------------------------------------------
  // This function will return the representation of all effects of the
  //  label rule, if it has one.
  //--------------------------------------------------------------------------
  function createEffectRepresentation(effect: effectType) {
    let exists = false
    if (effect.children) {
      exists = true
    }
    return (
      <div key={`${effect.key}${effect.value}`}>
        <LRComponent
          columnName={effect.key}
          value={effect.value}
          effect
        />
        <div className="pl-5">
          {
            exists ?
              effect.children.map((child) => createEffectRepresentation(child)) :
              <div />
          }
        </div>
      </div>
    )
  }
  //--------------------------------------------------------------------------
  // This function will return the actual label rule object.
  //--------------------------------------------------------------------------
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row w-full border border-gray-500 rounded-lg bg-white shadow-2xl my-1">
        <span className="w-2/5 border-r border-gray-500 rounded-l-lg p-1 bg-gray-300">name:</span>
        <PatternMatchedInput
          className="h-max w-full p-1 rounded-r-lg"
          placeholder="Type a name for the label rule."
          type="text"
          id="myText"
          value={curLabelRule.name}
          onChange={(event) => {
            changeName(event.target.value)
          }}
          pattern={re}
          errorMessage="LabelRule name is invalid. It must consist of lower case alphanumeric characters, \'-\' or \'.\' and must start and and with and alphanumeric characters"
        />
      </div>
      <div className="flex flex-row w-full border border-gray-500 rounded-lg bg-white shadow-2xl my-3">
        <span className="w-2/5 border-r border-gray-500 rounded-l-lg p-1 bg-gray-300">description:</span>
        <input
          className="h-max w-full p-1 rounded-r-lg"
          placeholder="Type a description for the label rule."
          type="text"
          id="myText"
          value={curLabelRule.description}
          onChange={(event) => {
            changeDescription(event.target.value)
          }}
        />
      </div>
      <div className="my-1">
        <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl">
          <span className="w-full border-b border-gray-500 rounded-t-lg p-1 bg-gray-300 w-20">Label rules:</span>
          <div className="p-2">
            {curLabelRule.rules.map((el) => {
              return (
                <div key={`${el.key}${el.value}`}>
                  <LRComponent
                    columnName={el.key}
                    value={el.value}
                    deleteCallback={() => {
                      changeRules(curLabelRule.rules.filter((rule) => {
                        return !(rule.key === el.key && rule.value === el.value)
                      }))
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
        {curLabelRule.effects.length !== 0 && (
          <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl my-3">
            <span className="w-full border-b border-gray-500 rounded-t-lg p-1 bg-gray-300 w-20">
              Label rule effects:
            </span>
            <div className="p-2">
              {
                curLabelRule.effects.map((el) => {
                  return createEffectRepresentation(el)
                })
              }
            </div>
          </div>
        )}
        <div className="flex justify-center content-end">
          <div className="content-end">
            <button
              className={
                reTest.test(curLabelRule.name) && curLabelRule.name.length !== 0 ?
                  'bg-[#668957] hover:bg-[#93ac89] text-white border-2 rounded-lg border-[#668957] w-fit p-2 m-1' :
                  'bg-gray-300 text-white border-2 rounded-lg border-gray-300 w-fit p-2 m-1'
              }
              type="button"
              onClick={() => {
                if (reTest.test(curLabelRule.name) && curLabelRule.name.length !== 0) {
                  onButtonClick(curLabelRule)
                }
              }}
            >

              Open the label rule creation tool
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
