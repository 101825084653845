/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef, useState, useEffect } from 'react'
import { BiFilter } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'

import { Checkbox, Resizable, RESIZE_DIRECTIONS } from 'components'
import usePopup from 'hooks/usePopup'
import useTableContext from './useTableContext'
import { filterTableData, getFilterOptions } from './table.utils'
import { TTableColumn } from './types'

const DEFAULT_SIZES = {
  width: 200,
  height: 200
}

const DEFAULT_POSITIONS = {
  left: 36 - DEFAULT_SIZES.width,
  top: 0
}

interface FilterButtonProps {
  tableElement: HTMLTableElement | null
  column: TTableColumn
}

function FilterButton({ tableElement, column }: FilterButtonProps) {
  const { groupChildrenField, data, formatOptions, filterList, setFilterList } =
    useTableContext()

  const keepRef = useRef({ oldLeftPosition: DEFAULT_POSITIONS.left })
  const triggerRef = useRef<HTMLDivElement | null>(null)
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  )
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null
  )

  const [searchValue, setSearchValue] = useState('')
  const [options, setOptions] = useState<string[]>([])
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const [isPreventClickOutside, setPreventClickOutside] = useState(false)
  const [sizes, setSizes] = useState(DEFAULT_SIZES)
  const [leftPosition, setLeftPosition] = useState(DEFAULT_POSITIONS.left)

  const { isOpen, openPopup, closePopup } = usePopup(triggerRef, {
    isPreventClickOutside
  })

  const defaultFilter = useMemo(
    () => filterList.find(({ field }) => field === column.field),
    [column, filterList]
  )

  const defaultOptions = useMemo(() => {
    const options: string[] = []
    const newFilters = filterList.filter(({ field }) => field !== column.field)
    const filteredData = filterTableData(
      data,
      newFilters,
      formatOptions,
      groupChildrenField
    )

    const newOptions = getFilterOptions({
      data: filteredData,
      column,
      formatOptions,
      childrenField: groupChildrenField
    })
    options.push(...newOptions)

    return Array.from(new Set(options).values())
  }, [
    groupChildrenField,
    column,
    data,
    filterList,
    formatOptions,
    groupChildrenField
  ])

  const defaultSelectedOptions = useMemo(
    () => defaultFilter?.values || defaultOptions,
    [defaultFilter, defaultOptions]
  )

  const isSelectedAll = useMemo(
    () => !options.some((option) => !selectedOptions.includes(option)),
    [options, selectedOptions]
  )

  const handleSearchChange = (value: string) => {
    if (value === searchValue) return

    const newOptions = defaultOptions.filter((option) => {
      if (!value) return true
      return option.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    })

    setSearchValue(value)
    setOptions(newOptions)
    setSelectedOptions(value ? newOptions : defaultSelectedOptions)
  }

  const handleClear = () => {
    const newFilters = filterList.filter(({ field }) => field !== column.field)
    setFilterList(newFilters)
    closePopup()
  }

  const handleFilter = () => {
    const newFilters = filterList.filter(({ field }) => field !== column.field)
    const values = selectedOptions.filter((option) => options.includes(option))

    if (!isSelectedAll || searchValue) {
      const { field, format } = column
      newFilters.push({
        field,
        values,
        format: format || ((value: any) => value)
      })
    }

    setFilterList(newFilters)
    closePopup()
  }

  useEffect(() => {
    if (isOpen) {
      setOptions(defaultOptions)
      setSelectedOptions(defaultFilter?.values || defaultOptions)
    } else {
      setSearchValue('')
      setLeftPosition(DEFAULT_POSITIONS.left)
      setSizes(DEFAULT_SIZES)
    }
  }, [isOpen, defaultOptions, defaultFilter])

  // correct filter's position
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!tableElement || !wrapperElement) return
      const { left: tableLeft } = tableElement.getBoundingClientRect()
      const { left: containerLeft } = wrapperElement.getBoundingClientRect()
      const extraLeft = Math.max(tableLeft + 4 - containerLeft, 0)

      if (extraLeft > 0) {
        setLeftPosition(DEFAULT_POSITIONS.left + extraLeft)
        keepRef.current.oldLeftPosition = DEFAULT_POSITIONS.left + extraLeft
      }
    })

    if (tableElement) observer.observe(tableElement)
    return () => observer.disconnect()
  }, [tableElement, wrapperElement])

  return (
    <div
      ref={triggerRef}
      className={`relative w-full h-full flex items-center px-2 ${
        isOpen ? 'bg-white/30' : ''
      }`}
      onClick={openPopup}
    >
      <div className="relative">
        <BiFilter size={20} />
        {defaultFilter && (
          <div className="absolute top-[2px] right-0 w-2 h-2 rounded-full bg-green-500" />
        )}
      </div>

      {isOpen && (
        <div
          ref={setWrapperElement}
          className="absolute z-10 bottom-3 translate-y-full shadow-lg bg-white cursor-auto"
          style={{ left: leftPosition }}
        >
          <Resizable
            className="flex flex-col text-xs text-black font-normal normal-case p-2"
            minSizes={{ ...DEFAULT_SIZES, height: 150 }}
            sizes={sizes}
            onStartResize={() => {
              setPreventClickOutside(true)
              keepRef.current.oldLeftPosition = leftPosition
            }}
            onSizeChange={({ direction, width, height, oldWidth }) => {
              const isLeftSide = [
                RESIZE_DIRECTIONS.LEFT,
                RESIZE_DIRECTIONS.LEFT_BOTTOM
              ].includes(direction)

              if (isLeftSide) {
                setLeftPosition(
                  keepRef.current.oldLeftPosition + (oldWidth - width)
                )
              }
              setSizes({ width, height })
            }}
            onEndResize={() => setPreventClickOutside(false)}
          >
            <div className="relative text-gray-600 w-full px-2">
              <input
                ref={setInputElement}
                className="border-b border-gray-300 focus:border-primary-color bg-transparent pr-5 text-sm focus:outline-none w-full py-1 font-normal"
                type="text"
                placeholder="search"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                value={searchValue}
                onChange={(event) => handleSearchChange(event.target.value)}
              />
              <button
                type="button"
                className="absolute top-1/2 -translate-y-1/2 right-0 p-1 hover:bg-gray-300 rounded-full"
                onClick={() => {
                  handleSearchChange('')
                  inputElement?.focus()
                }}
              >
                <AiOutlineClose size={12} />
              </button>
            </div>
            <div className="overflow-y-auto my-2 grow">
              <div className="hover:bg-gray-100 px-2 rounded">
                <Checkbox
                  label="Select All"
                  checked={isSelectedAll}
                  indeterminate={selectedOptions.length > 0 && !isSelectedAll}
                  onChange={() => {
                    if (isSelectedAll) {
                      setSelectedOptions([])
                    } else {
                      setSelectedOptions(options)
                    }
                  }}
                />
              </div>
              {options.map((label) => (
                <div key={label} className="hover:bg-gray-100 px-2 rounded">
                  <Checkbox
                    label={label}
                    checked={selectedOptions.includes(label)}
                    onChange={(checked) => {
                      const filteredSelectedOptions = selectedOptions.filter(
                        (option) => option !== label
                      )
                      if (checked) {
                        setSelectedOptions([...filteredSelectedOptions, label])
                      } else {
                        setSelectedOptions(filteredSelectedOptions)
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-between">
              <button
                className="text-red-500 px-2 py-1 rounded hover:bg-gray-100"
                type="button"
                onClick={handleClear}
              >
                CLEAR
              </button>
              <div>
                <button
                  className="text-red-500 px-2 py-1 rounded hover:bg-gray-100"
                  type="button"
                  onClick={handleFilter}
                >
                  OK
                </button>
                <button
                  className="ml-1 text-gray-600 px-2 py-1 rounded hover:bg-gray-100"
                  type="button"
                  onClick={closePopup}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </Resizable>
        </div>
      )}
    </div>
  )
}

export default FilterButton
