export const tourConfig = (callbackList: (() => void)[]) => {
  return [
    {
      selector: '[data-tour="fullApp"]',
      content: 'Welcome! You have just taken the first step to understanding more about your data! This is the home page. Here you can access a variety of functionalities: the widgets, reports and labelrules'
    },
    {
      selector: '[data-tour="sidebar"]',
      content: 'This is the sidebar, using this you can change the page. This in turn changes what you can do with the page'
    },
    {
      selector: '[data-tour="topbar"]',
      content: 'The top bar will allow you to refresh the data, search, look at your profile and log out.'
    },
    {
      selector: '[data-tour="addWidgetButton"]',
      content: 'This button will allow you to choose a widget to add to the home screen. These will be remembered the next time you log in.',
      action: () => { callbackList[0]() }
    },
    {
      selector: '[data-tour="addWidgetButtonPopUp"]',
      content: 'These are all the available widgets.'
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'Enjoy!',
      action: () => {
        callbackList[1]()
        callbackList[2]()
      }
    }
  ]
}
