import { ChangeEvent } from 'react'

import { TConfiguration } from 'services/config.interface'
import { Accordion, Input } from 'components'

interface ConfigurationCardProps {
  parentPath: string
  config: TConfiguration
  onChange: (path: string, value: string) => void
}

function ConfigurationCard({
  parentPath,
  config,
  onChange
}: ConfigurationCardProps) {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    onChange(`${parentPath}.settings[${index}].value`, event.target.value)
  }

  return (
    <Accordion defaultExpanded title={config.name}>
      <div className="flex flex-col gap-4">
        {config.settings?.map((setting, index) => (
          <div key={index} className="flex items-center gap-4">
            <p className="w-1/3 text-gray-500">{setting.key}</p>
            <Input
              value={setting.value}
              onChange={(event) => handleChange(event, index)}
            />
          </div>
        ))}

        {config.subitems?.map((config, index) => (
          <ConfigurationCard
            key={index}
            parentPath={`${parentPath}.subitems[${index}]`}
            config={config}
            onChange={onChange}
          />
        ))}
      </div>
    </Accordion>
  )
}

export default ConfigurationCard
