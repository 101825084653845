//--------------------------------------------------------------------------
// dbMakeData.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { v4 as uuid } from 'uuid'
import { jsonData } from './constants';
import data from './demoTableData.json'
//--------------------------------------------------------------------------
// These are functions to parse the data before we can use it in the resource
// explorer.
//--------------------------------------------------------------------------
export const numbers = ['daily_cost', 'weekly_cost', 'monthly_cost', 'effective_cost', 'list_cost', 'pricing_quantity', 'usage_quantity', 'billed_cost', 'list_unit_price']
export const costs = ['daily_cost', 'weekly_cost', 'monthly_cost', 'effective_cost', 'list_cost', 'billed_cost', 'list_unit_price']
let names = Object.getOwnPropertyNames(data.resource[0]).filter((el) => el !== 'updated_flag' && el !== 'data_date' && numbers.findIndex((num) => num === el) === -1)
names = names.sort((a, b) => a.localeCompare(b)).concat(numbers)
export const properties = names.map((name) => {
  return name.toLowerCase().replace(/(_\w)/g, (m) => m.toUpperCase().substr(1))
})
export const costProperties = costs.map((name) => {
  return name.toLowerCase().replace(/(_\w)/g, (m) => m.toUpperCase().substr(1))
})

export function parseCosts() {
  return data.resource.map((el: jsonData) => {
    numbers.forEach((number) => {
      const parsed = Number(el[number as keyof typeof data.resource[0]]).toFixed(2)
      el[number] = parsed
    })
    return el
  })
}

export function makeTableData(): jsonData[] {
  const res: jsonData[] = []
  parseCosts().forEach((el: jsonData) => {
    const obj: jsonData = {
      id: uuid()
    }
    names.forEach((name, idx) => {
      obj[properties[idx]] = el[names[idx]]
    })
    res.push(obj)
  })
  return res
}
