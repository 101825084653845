import { TReportFilterData, TReportOperator } from 'services/report.interface'

type TReportOperatorOption = {
  label: string
  value: TReportOperator
}

export const REPORT_FILTER_TYPE: Record<
  'DEFAULT' | 'LABEL' | 'ANNOTATION',
  TReportFilterData['type']
> = {
  DEFAULT: 'default',
  LABEL: 'label',
  ANNOTATION: 'annotation'
}

export const FIELD_OPTIONS = [
  { label: 'Resource ID', value: 'resource_id', type: 'text' },
  { label: 'Resource Name', value: 'resource_name', type: 'text' },
  { label: 'Resource Type', value: 'resource_type', type: 'text' },
  { label: 'Service Name', value: 'service_name', type: 'text' },
  { label: 'Provider', value: 'provider', type: 'text' },
  { label: 'Billing Account ID', value: 'billing_account_id', type: 'text' },
  { label: 'Billing Account Name', value: 'billing_account_name', type: 'text' },
  { label: 'Daily cost', value: 'daily_cost', type: 'number' },
  { label: 'Weekly cost', value: 'weekly_cost', type: 'number' },
  { label: 'Monthly cost', value: 'monthly_cost', type: 'number' }
]

export const OPERATOR_OPTIONS: TReportOperatorOption[] = [
  { label: 'equal to', value: 'EQUALS' },
  { label: 'not equal to', value: 'NOTEQUALS' }
]

export const NUMBER_OPERATOR_OPTIONS: TReportOperatorOption[] = [
  { label: 'higher than', value: 'GREATERTHAN' },
  { label: 'lower than', value: 'LESSTHAN' }
]

export const TEXT_OPERATOR_OPTIONS: TReportOperatorOption[] = [
  { label: 'contains', value: 'CONTAINS' },
  { label: 'starts with', value: 'STARTSWITH' },
  { label: 'ends with', value: 'ENDSWITH' }
]
