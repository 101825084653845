import { ReactNode } from 'react'

// import backgroundSvg from 'assets/cloud-vue/Pattern3.svg'
import backgroundPattern from 'assets/cloud-vue/Pattern6.svg'
import productLogoImg from 'assets/cloud-vue/CloudVue-logo-landscape-v1.svg'
import domeLogoImg from 'assets/CloudVue-logo-square.png'

interface AuthFormLayoutProps {
  children: ReactNode
}

function AuthFormLayout({ children }: AuthFormLayoutProps) {
  return (
    <div
      className="h-screen flex items-center justify-center overflow-hidden p-8"
      style={{
        backgroundColor: '#e9f3e5',
        backgroundPosition: 'top center',
      }}
    >
      <div className="flex flex-col lg:flex-row rounded-md lg:bg-[#668957] w-full lg:w-auto max-w-[500px] lg:max-w-none overflow-hidden">
        <div className="relative flex flex-col items-center justify-between px-4 py-12 min-w-[400px]">
          <div className="z-10 flex flex-col items-center justify-center gap-4">
            <img className="h-28" src={productLogoImg} alt="logo" />
            <p className="text-gray-200 font-bold text-xl">
              Cloud cost management and optimisation
            </p>
          </div>
          <div className="z-0 flex flex-col items-center justify-center gap-4">
            <img className="h-28" src={domeLogoImg} alt="logo" />
          </div>
          <div className="absolute hidden lg:block top-0 left-0 ">
            <img src={backgroundPattern} alt="CloudView" className="object-cover min-w-[400px]  min-h-[500px]" />
          </div>
        </div>

        <div className="lg:bg-primary-color lg:p-12 lg:min-h-[500px] w-full lg:min-w-[600px] lg:max-w-[600px]">
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthFormLayout
