import { BiError } from 'react-icons/bi'

import SpinnerLoader, { SpinnerLoaderProps } from 'components/spinner-loader'
import { TFetchStatus } from 'services/fetch.interface'
import { mapClasses } from 'utils'

export const FETCH_STATUS = {
  START: { isLoading: true, isError: false },
  SUCCESS: { isLoading: false, isError: false },
  FAIL: { isLoading: false, isError: true }
}

interface FetchStatusProps extends Omit<SpinnerLoaderProps, 'isLoading'> {
  direction?: 'row' | 'column'
  size?: 'medium' | 'small'
  hideMessage?: boolean
  status?: TFetchStatus
  retry?: () => void
}

function FetchStatus({
  className,
  height = 'auto',
  direction = 'column',
  size = 'medium',
  hideMessage = false,
  status,
  retry,
  children
}: FetchStatusProps) {
  if (status?.isLoading) {
    return (
      <SpinnerLoader
        className={className}
        isLoading
        height={height}
        spinnerSize={size === 'small' ? 20 : undefined}
      >
        {children}
      </SpinnerLoader>
    )
  }

  if (!status?.isError) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <div
      className={mapClasses(
        'w-full h-full flex justify-center items-center py-4',
        className
      )}
      style={{ height }}
    >
      <div
        className={mapClasses(
          'flex gap-1 items-center',
          direction === 'column' && 'flex-col'
        )}
      >
        <BiError
          className={mapClasses(
            'fill-red-500',
            size === 'small' && 'w-5 h-5',
            size === 'medium' && 'w-8 h-8'
          )}
        />
        {!hideMessage && (
          <div className="text-red-500">Error fetching data</div>
        )}
        <div className="cursor-pointer text-blue-500 underline" onClick={retry}>
          Retry
        </div>
      </div>
    </div>
  )
}

export default FetchStatus
