//--------------------------------------------------------------------------
// ReportEditor.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { BarLineChart, ButtonIcon, Modal, ModalBody, ModalHeader, SpinnerLoader, TTableData } from 'components'
import {
  useEffect,
  // useMemo,
  useState
} from 'react'
import { v4 as uuid } from 'uuid'
import { TCostHistoryQueryResponse, TPlotSeries } from 'services/costhistory.interface'
import { axios } from 'utils/axios'
import { useQuery } from 'react-query'
import { toast, Bounce } from 'react-toastify'
import { TReportFilterDisplay } from 'services/report.interface'
import { TQueryRequest } from 'services/commons.interface'
import { convertReportFilterDisplayToQuery, convertTFocusGroupToBarChartSeries, convertTFocusGroupToBarChartSeriesKey } from 'utils/dataConverter'
import { TCurrency } from 'services/cost.interface'
import { AreaChartSeries } from '@mantine/charts'
import { dateFormatChanger } from 'features/projects/project-labeled-data-widget/chartHelperFunctions'
import { FaChartBar, FaChartLine, FaChartArea, } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5';
// import { focusTableColumns } from './constants'
import { jsonData } from './ReportEditor'
import { MakeTable } from './mutation-new-report-modal/MakeTable'

//--------------------------------------------------------------------------
// The interface of the Modal that shows the details for a report.
//--------------------------------------------------------------------------
interface DetailsModalProps {
  reportParam?: TReportFilterDisplay
  currency: TCurrency
  onClose: () => void
}
//--------------------------------------------------------------------------
// The function that creates the Modal that shows the details for a report.
//--------------------------------------------------------------------------
export function DetailsModal({
  reportParam,
  currency,
  onClose
}: DetailsModalProps) {
  //--------------------------------------------------------------------------
  // All states used in the DetailsModal.
  //--------------------------------------------------------------------------
  // const formatOptions = useMemo(() => ({ currency }), [currency])
  const [dataProcessed, setDataProcessed] = useState<boolean>(true)
  const [queryKey, setQueryKey] = useState<string>('')
  const [queryRequest, setQueryRequest] = useState<TQueryRequest>()
  const [focusData, setFocusData] = useState<jsonData[]>([])
  const [focusField, setFocusField] = useState<string>('totalUsageQuantity')
  const [seriesData, setSeriesData] = useState<TPlotSeries[] | object[]>([])
  const [seriesDataKey, setSeriesDataKey] = useState<AreaChartSeries[]>([])
  const [tableData, setTableData] = useState<TTableData[]>([])
  const [chartType, setChartType] = useState<'line' | 'bar' | 'area' | undefined>('line')
  const [focusDate, setFocusDate] = useState<string>('')

  //--------------------------------------------------------------------------
  // If the data is loaded in, we configure the aformentioned states.
  //--------------------------------------------------------------------------
  useEffect(() => {
    if (!reportParam) return
    if (dataProcessed) {
      setFocusField(reportParam?.chart?.chartValue || 'totalUsageQuantity')
      setDataProcessed(false)
      setQueryRequest(convertReportFilterDisplayToQuery(reportParam))
      setQueryKey(uuid())
    }
  }, [reportParam, queryKey])

  //--------------------------------------------------------------------------
  // This is the query that will fetch the data that will be used for both
  // the table and the charts.
  //--------------------------------------------------------------------------
  useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!dataProcessed && queryRequest) {
          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setFocusData(res.data.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              toast.error(`Error code ${error.status}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  //--------------------------------------------------------------------------
  // If focusData would have changed, there will be two options:
  // - The data is not valid, makes sure that the chart and table are cleared.
  // - The data is valid, parse said data to formats that will be usable in the
  // charts and tables.
  //--------------------------------------------------------------------------
  useEffect(() => {
    if (!Array.isArray(focusData) || focusData.length === 0) {
      // **************
      // Clear the data
      // **************
      setTableData([])
      setSeriesData([])
      setDataProcessed(true)
      return
    }
    const tableData: jsonData[] = []
    //--------------------------------------------------------------------------
    // Check if focusDate is different from '', if so this means a user has
    // selected a "column" or date in other words. we will now filter on that
    // date.
    //--------------------------------------------------------------------------
    let dataToWorkWith = focusData
    if (focusDate !== '') {
      dataToWorkWith = dataToWorkWith.filter((el) => {
        const temp: jsonData = Object.entries(el)
        return temp[0][1].timestamp === focusDate
      })
    }
    dataToWorkWith.forEach((key) => {
      Object.entries(key).forEach(([, value]) => {
        value.timestamp = dateFormatChanger(value.timestamp as any)
        tableData.push(value)
      })
    })
    const barSeries: jsonData[] = convertTFocusGroupToBarChartSeries(
      focusData,
      'timestamp',
      focusField
    )
    const barSeriesKey = convertTFocusGroupToBarChartSeriesKey(
      focusData
    )
    setTableData(tableData)
    setSeriesData(barSeries.map((el) => {
      if (el.date) el.date = dateFormatChanger(el.date)
      const names = Object.getOwnPropertyNames(el).filter((name) => name !== 'date')
      names.forEach((name) => {
        if (Number(el[name])) {
          el[name] = Number(Number(el[name]).toFixed(2))
        }
      })
      return el
    }))
    setSeriesDataKey(barSeriesKey)
  }, [focusData, focusDate])
  //--------------------------------------------------------------------------
  // Splits the modal in two halfs:
  // - One that shows the charts
  // - One that shows the table of the charts.
  // It is for this that the reference defined above will be used.
  //--------------------------------------------------------------------------
  return (
    <Modal isOpen onClose={onClose} disableBackdropClick>
      <ModalHeader onClose={onClose}>
        <span>Report details</span>
      </ModalHeader>
      <ModalBody className="p-3 w-[80vw] h-[700px]">
        <SpinnerLoader isLoading={!dataProcessed}>
          <div className={`h-9 w-fit ${focusDate !== '' ? 'border-2 border-[#668957] rounded-lg bg-white shadow-2xl' : ''}`}>
            {
              focusDate !== '' ? (
                <span className="flex flex-row">
                  Filter by date: {focusDate}
                  <ButtonIcon
                    className="h-full py-1"
                    onClick={() => {
                      setFocusDate('')
                    }}
                  >
                    <IoClose size={20} />
                  </ButtonIcon>
                </span>
              )
                :
                <span />
            }
          </div>
          <div className="w-full h-[600px] flex py-1">
            <div className="w-1/2 pr-3">
              <div className="h-full border-2 border-[#668957] rounded-lg bg-white shadow-2xl">
                <div className="flex w-full border-b border-[#668957] rounded-t-sm p-1 bg-[#668957] w-20 h-12">
                  <span className="w-1/2 text-white">
                    Chart:
                  </span>
                  <span className="flex justify-end w-1/2">
                    <div className="align-center">
                      <button
                        type="button"
                        className="border-2 border-gray-500 p-1 rounded-lg bg-white"
                        onClick={() => {
                          setChartType('bar')
                        }}
                      >
                        <FaChartBar size={20} />
                      </button>
                      <button
                        type="button"
                        className="border-2 border-gray-500 p-1 mx-1 rounded-lg bg-white"
                        onClick={() => {
                          setChartType('line')
                        }}
                      >
                        <FaChartLine size={20} />
                      </button>
                      <button
                        type="button"
                        className="border-2 border-gray-500 p-1 rounded-lg bg-white"
                        onClick={() => {
                          setChartType('area')
                        }}
                      >
                        <FaChartArea size={20} />
                      </button>
                    </div>
                  </span>
                </div>
                <BarLineChart
                  height={500}
                  isLoading={false}
                  data={seriesData}
                  dataSeries={seriesDataKey}
                  chartType={chartType}
                  currency={currency}
                  onClick={(event) => {
                    if (Object.getOwnPropertyNames(event).length !== 0) {
                      setFocusDate(event.activePayload[0].payload.date)
                    }
                  }}
                />
              </div>
            </div>

            <div className="w-1/2 h-full border-2 border-[#668957] rounded-lg bg-white shadow-2xl h-[600px] overflow-y-auto">
              {tableData.length !== 0 && (
                <MakeTable height={473} tableData={tableData} />
              )}
            </div>
          </div>
        </SpinnerLoader>
      </ModalBody>
    </Modal>
  )
}
