import { returnElementByTag } from 'features/projects/project-summary-widgets/summaryWidgetHelperFunctions';
import useAuthentication from 'hooks/useAuthentication'

export function SummaryBar() {
  const { currency } = useAuthentication()
  return (
    <div className="flex flex-row bg-white rounded-lg w-max border-b border-[#5b7b4e]">
      <div className="flex">
        <div className=" border-r border-gray-200">
          {returnElementByTag('ThisMonthSpend', 'ThisMonthSpend', currency)}
        </div>
        <div className="border-r border-gray-200">
          {returnElementByTag('PreviousMonthSpend', 'PreviousMonthSpend', currency)}
        </div>
        <div className="w-max">
          {returnElementByTag('TopServiceBySpend', 'TopServiceBySpend', currency)}
        </div>
      </div>
      <div className="w-1/2 border-l border-gray-200">
        {returnElementByTag('RegionPercentage', 'RegionPercentage', currency)}
      </div>
    </div>
  )
}
