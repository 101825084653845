// Creates a big amount of colours that have to be different. These colours are a bit guided
// in their values to make them still look good.==
// import { DEFAULT_THEME } from '@mantine/core';

// type color = { hue: number, sat: number, lum: number }
function CREATE_CHART_COLORS() {
  // const themes = DEFAULT_THEME.colors
  const res: string[] = ['#658856', '#1c9820', '#087394', '#58b5e1', '#1c4bb4', '#997cfb', '#702cb4', '#dc58ea', '#735377', '#c78bb9']

  // for (let sat = 65; sat >= 35; sat -= 15) {
  //   for (let lum = 65; lum >= 35; lum -= 15) {
  //     for (let hue = 0; hue <= 320; hue += 40) {
  //       const code = { hue, sat, lum }
  //       res.push(code)
  //     }
  //   }
  // }
  // return res.map((el) => {
  //   return `hsl(${el.hue}, ${el.sat}%, ${el.lum}%)`
  // })
  // const tags = Object.getOwnPropertyNames(themes).filter((el) => el !== 'dark')
  // for (let num = 9; num > 3; num--) {
  //   tags.forEach((color) => {
  //     res.push(themes[color][num])
  //   })
  // }
  return res
}
export const CHART_COLORS = CREATE_CHART_COLORS()
