import { useState } from 'react'

import { TEntityStatus } from 'services/commons.interface'
import { Popover } from 'components'

interface EntityStatusCircleProps {
  status?: TEntityStatus
}
const getStatus = (status?: TEntityStatus) => {
  switch (status?.healthy) {
    case true:
      return 'bg-green-400'
    case false:
      return 'bg-red-400'
    default:
      return 'bg-amber-400'
  }
}
function EntityStatusCircle({ status }: EntityStatusCircleProps) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

  return (
    <>
      <div
        ref={setAnchorElement}
        className={`flex items-center justify-center w-6 h-6 rounded-full ${getStatus(status)}`}
      />
      {!!status?.message && (
        <Popover mode="hover" placement="top-end" anchorElement={anchorElement}>
          <span>{status.message}</span>
        </Popover>
      )}
    </>
  )
}

export default EntityStatusCircle
