import { MRT_Icons } from 'mantine-react-table';
import { FaBars, FaChevronDown, FaChevronLeft, FaChevronRight, FaColumns, FaCompress, FaEdit, FaEllipsisH, FaEllipsisV, FaExpand, FaEyeSlash, FaFilter, FaLayerGroup, FaSearch, FaSearchMinus, FaSort, FaSortDown, FaSortUp, FaTextWidth } from 'react-icons/fa';
import { FaAnglesDown, FaCircleXmark, FaBarsStaggered, FaFloppyDisk, FaFilterCircleXmark, FaGrip, FaX, FaThumbtack } from 'react-icons/fa6';

export const fontAwesomeIcons: Partial<MRT_Icons> = {

  IconArrowAutofitContent: (props: any) => (
    <FaTextWidth {...props} />
  ),

  IconArrowsSort: (props: any) => <FaSort {...props} />,

  IconBoxMultiple: (props: any) => (
    <FaLayerGroup {...props} />
  ),

  IconChevronDown: (props: any) => (
    <FaChevronDown {...props} />
  ),

  IconChevronLeft: (props: any) => (
    <FaChevronLeft {...props} />
  ),

  IconChevronRight: (props: any) => (
    <FaChevronRight {...props} />
  ),

  IconChevronsDown: (props: any) => (
    <FaAnglesDown {...props} />
  ),

  IconCircleX: (props: any) => (
    <FaCircleXmark {...props} />
  ),

  IconClearAll: (props: any) => (
    <FaBarsStaggered {...props} />
  ),

  IconColumns: (props: any) => <FaColumns {...props} />,

  IconDeviceFloppy: (props: any) => (
    <FaFloppyDisk {...props} />
  ),

  IconDots: (props: any) => <FaEllipsisH {...props} />,

  IconDotsVertical: (props: any) => (
    <FaEllipsisV {...props} />
  ),

  IconEdit: (props: any) => <FaEdit {...props} />,

  IconEyeOff: (props: any) => <FaEyeSlash {...props} />,

  IconFilter: (props: any) => <FaFilter {...props} />,

  IconFilterOff: (props: any) => (
    <FaFilterCircleXmark {...props} />
  ),

  IconGripHorizontal: (props: any) => (
    <FaGrip {...props} />
  ),

  IconMaximize: (props: any) => <FaExpand {...props} />,

  IconMinimize: (props: any) => (
    <FaCompress {...props} />
  ),

  IconPinned: (props: any) => <FaThumbtack {...props} />,

  IconPinnedOff: (props: any) => (
    <FaThumbtack {...props} />
  ),

  IconSearch: (props: any) => <FaSearch {...props} />,

  IconSearchOff: (props: any) => (
    <FaSearchMinus {...props} />
  ),

  IconSortAscending: (props: any) => (
    <FaSortUp {...props} />
  ),

  IconSortDescending: (props: any) => (
    <FaSortDown {...props} />
  ),

  IconBaselineDensityLarge: (props: any) => (
    <FaBars {...props} />
  ),

  IconBaselineDensityMedium: (props: any) => (
    <FaBars {...props} />
  ),

  IconBaselineDensitySmall: (props: any) => (
    <FaBars {...props} />
  ),

  IconX: (props: any) => <FaX {...props} />,
};
