import { BiRightArrowAlt } from 'react-icons/bi'

interface cardProps {
  heading: string
  description: string
  icon: any
}

function ClusterCard({ heading, description, icon }: cardProps) {
  return (
    <div className="desktopMini:w-2/9 xs:w-full border border-dark-grey rounded-md my-2 xs:mr-0 desktopMini:mr-3 px-4 py-3 flex flex-col justify-between hover:bg-gray-100 h-36 savings">
      <div className="flex flex-col">
        <p className="text-sm font-semibold text-gray-800">{heading}</p>
        <p className="text-sm text-primary-text-color py-2">{description}</p>
      </div>
      <div className="flex justify-between items-center">
        {icon}
        <div className="flex cursor-pointer hover:text-primary-text-color">
          <span className="text-sm">Explore settings</span>
          <BiRightArrowAlt size={24} className="pl-1" />
        </div>
      </div>
    </div>
  )
}

export default ClusterCard
