import { MdDragIndicator } from 'react-icons/md';
import { ButtonIcon, GraphCard } from 'components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { DraggableData } from './propsInterface';

export function Draggable(props: DraggableData) {
  const { id, children, depth } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id
  })
  const style = {
    width: '100%',
    backgroundColor: 'white',
    display: 'inline-flex',
    transform: CSS.Transform.toString(transform),
    transition,
    paddingLeft: `${40 * depth}px`

  };
  return (
    <div ref={setNodeRef} style={style}>
      <GraphCard>
        <div style={{ display: 'inline-flex' }}>
          <ButtonIcon {...listeners} {...attributes} style={{ width: '30px' }}>
            <MdDragIndicator size={20} />
          </ButtonIcon>
          {children}
        </div>
      </GraphCard>
    </div>
  );
}
