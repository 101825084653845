import React from 'react'
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown
} from 'react-icons/md'

import ButtonIcon from 'components/button-icon'
import useTableContext from './useTableContext'

function ToggleExtendAllButton() {
  const { groupChildrenField, extendRowIdList, toggleExtendAll } =
    useTableContext()

  const handleToggleExtendAll = (event: React.MouseEvent) => {
    event.stopPropagation()
    toggleExtendAll()
  }

  if (!groupChildrenField) return null

  return (
    <ButtonIcon
      color="error"
      className="text-white mr-1 "
      onClick={handleToggleExtendAll}
    >
      {extendRowIdList.length ? (
        <MdOutlineKeyboardArrowDown className="inline text-lg" />
      ) : (
        <MdOutlineKeyboardArrowRight className="inline text-lg" />
      )}
    </ButtonIcon>
  )
}

export default ToggleExtendAllButton
