import { ReactNode } from 'react'

interface ModalFooterProps {
  className?: string
  children: ReactNode
}

function ModalFooter({ className, children }: ModalFooterProps) {
  return (
    <div
      className={className}
    >
      {children}
    </div>
  )
}

export default ModalFooter
