import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { axios, mapClasses } from 'utils'
import useAuthentication from 'hooks/useAuthentication'

import Spinner from 'components/spinner'
import { Input } from 'components'

function SignupForm() {
  const navigate = useNavigate()
  const { isLoading } = useAuthentication()

  const [isPosting, setPosting] = useState(false)

  const { formState, register, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      company: '',
      email: ''
    }
  })

  const handleSignup = handleSubmit(async (payload) => {
    setPosting(true)
    try {
      const { data } = await axios.post<{ message: string }>('/signup', payload)
      toast.success(data?.message || 'You will receive an email shortly')
      navigate('/login')
    } catch {
      toast.error('Signup failed')
    } finally {
      setPosting(false)
    }
  })

  const isDisabled = !formState.isDirty || !formState.isValid

  return (
    <div className="relative h-full">
      <div
        className={mapClasses(
          'w-full h-full flex flex-col gap-8 bg-white rounded-md p-12',
          (isLoading || isPosting) && 'blur-sm'
        )}
      >
        <div className="flex flex-col items-center">
          <h1 className="text-3xl font-thin text-gray-700">Sign up</h1>
        </div>

        <div className="grow w-full flex flex-col justify-center gap-8">
          <div className="flex flex-col gap-4">
            <Input
              label="First name*"
              errorMessage={formState.errors.firstName?.message}
              {...register('firstName', {
                required: 'required'
              })}
            />
            <Input
              label="Last name*"
              errorMessage={formState.errors.lastName?.message}
              {...register('lastName', {
                required: 'required'
              })}
            />
            <Input
              label="Company*"
              errorMessage={formState.errors.company?.message}
              {...register('company', {
                required: 'required'
              })}
            />
            <Input
              label="Email* (Please use your Google Account)"
              errorMessage={formState.errors.email?.message}
              {...register('email', {
                required: 'required',
                pattern: {
                  value: /\S+@\S+[.]\S+$/,
                  message: 'Invalid email'
                }
              })}
            />

            <button
              type="button"
              disabled={isDisabled}
              className={mapClasses(
                'rounded-lg  p-2 mt-4 text-white',
                isDisabled ? 'bg-gray-300' : 'bg-blue-500 hover:bg-blue-600'
              )}
              onClick={handleSignup}
            >
              Signup
            </button>
          </div>

          <div className="relative border-gray-100 text-gray-400">
            <div className="absolute left-1/2 -translate-x-1/2 border-t w-1/2" />
            <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-3">
              OR
            </div>
          </div>

          <div className="flex justify-center gap-1">
            <span className="font-light">Already have an account?</span>
            <Link className="text-blue-600 hover:underline" to="/login">
              Sign in
            </Link>
          </div>
        </div>
      </div>

      {(isLoading || isPosting) && (
        <div className="absolute z-10 left-0 top-0 w-full h-full flex justify-center items-center bg-white bg-opacity-10 rounded-lg">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default SignupForm
