export type TYamlLineData = {
  prefix?: 'space' | 'dash'
  level: number
  field: string
  value: string | number | null
}

interface YamlLineProps {
  lineData: TYamlLineData
}

const LEVEL_SPACING = 24

function YamlLine({ lineData }: YamlLineProps) {
  const { prefix, level, field, value } = lineData

  return (
    <div
      className="whitespace-nowrap"
      style={{ paddingLeft: (prefix ? level - 1 : level) * LEVEL_SPACING }}
    >
      {prefix && (
        <span
          className="inline-block pr-1 text-right"
          style={{ width: LEVEL_SPACING }}
        >
          {prefix === 'dash' && '-'}
        </span>
      )}
      <span className="text-[#5DA08F]">{field}</span>
      {field && <span className="mr-2">:</span>}
      <span className="text-[#7EE787] whitespace-nowrap">{value}</span>
    </div>
  )
}

export default YamlLine
