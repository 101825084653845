import { RiRepeat2Fill } from 'react-icons/ri'
import useProject from 'hooks/useProject'
// ****************************
// Sign in button for Key cloak
// ****************************
function ProjectReloadButton() {
  // *********
  // Constants
  // *********
  const { fetchProjects } = useProject()

  // ***********************
  // Render the login button
  // ***********************
  return (
    <div
      onClick={fetchProjects}
      className="hidden phone:flex px-3 flex-col items-center cursor-pointer"
    >
      <RiRepeat2Fill size={30} className="text-icon-color" />
      <span className="text-sm font-medium text-icon-color">Refresh</span>
    </div>
  )
}

export default ProjectReloadButton
