import Keycloak from 'keycloak-js'
import env from 'env'

// ***********************************************************************************
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// ***********************************************************************************
const REACT_KEY_CLOAK_URL = env.REACT_APP_KEY_CLOAK_URL || 'https://keycloak.dome.dev'
const REACT_KEY_CLOAK_REALM = env.REACT_APP_KEY_CLOAK_REALM || 'cloudvue'
const REACT_KEY_CLOAK_CLIENT_ID = env.REACT_APP_KEY_CLOAK_CLIENT_ID || 'cloudvue-react'
const keycloakSetting = {
  url: REACT_KEY_CLOAK_URL,
  realm: REACT_KEY_CLOAK_REALM,
  clientId: REACT_KEY_CLOAK_CLIENT_ID
}

const KeyCloak = new Keycloak(keycloakSetting)

export default KeyCloak
