import { TCurrency } from 'services/cost.interface'

export const CURRENCY: Record<string, TCurrency['value']> = {
  DOLLAR: 'DOLLAR',
  EURO: 'EURO'
}

export const CURRENCY_SYMBOL: Record<TCurrency['value'], TCurrency['symbol']> = {
  DOLLAR: '$',
  EURO: '€'
}
