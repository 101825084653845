//--------------------------------------------------------------------------
// index.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { SyntheticEvent, useEffect, useState } from 'react';
import { useElementSize } from '@mantine/hooks';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MutationNewLabelRulesModal from 'pages/label-rules/mutation-new-label-rule-model';
import { labelRuleType, ruleType, ruleTypeWithId } from 'pages/label-rules/queryHelperFunctions';
import { axios } from 'utils';
import { v4 as uuid } from 'uuid'
import { FaPlus } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { toast, Bounce } from 'react-toastify';
import { LabelRuleCreator } from './LabelRuleCreator';
import { MakeTable } from './MakeTable';
// import { makeTableData } from './dbMakeData';

///--------------------------------------------------------------------------
// The function that creates the resource explorer.
//--------------------------------------------------------------------------
export function ResourceExplorer() {
  ///--------------------------------------------------------------------------
  // The states used throughout the function and its functionalities.
  //--------------------------------------------------------------------------
  const [initialName, setInitalName] = useState<string>('')
  const [curLabelRule, setCurLabelRule] = useState<labelRuleType>({
    name: '',
    description: '',
    enabled: true,
    rules: [],
    effects: []
  })
  const [showMutationModal, setShowMutationModal] = useState<boolean>(false)
  const [tab, setTab] = useState<string | null>('+')
  const [value, setValue] = useState(0);

  const [labelRuleData, setLabelRuleData] = useState<labelRuleType[]>([])
  const [labelRuleDataProcessed, setLabelRuleDataProcessed] = useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>(uuid)
  const [ruleToAdd, setRuleToAdd] = useState<ruleType | undefined>()

  ///--------------------------------------------------------------------------
  // This function will add a rule to the selected label rule when a cell has
  // been clicked.
  //--------------------------------------------------------------------------
  function addRule() {
    if (ruleToAdd) {
      const index =
        curLabelRule.rules.findIndex((el) => el.key === ruleToAdd.key &&
          el.operand === ruleToAdd.operand &&
          el.value === ruleToAdd.value)
      if (index === -1) {
        const rules = curLabelRule.rules.concat(ruleToAdd)
        setCurLabelRule({
          name: curLabelRule.name,
          description: curLabelRule.description,
          enabled: curLabelRule.enabled,
          rules,
          effects: curLabelRule.effects
        })
      }
    }
  }

  useEffect(() => {
    addRule()
    setRuleToAdd(undefined)
  }, [ruleToAdd])

  ///--------------------------------------------------------------------------
  // This is the query that will fetch all the existing label-rules.
  //--------------------------------------------------------------------------
  useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!labelRuleDataProcessed) {
          await axios
            .get('/label-rules')
            .then((res) => {
              setLabelRuleData(res.data)
              if (tab !== '') {
                const response: labelRuleType[] = res.data
                const labelRule = response.find((el) => el.name === curLabelRule.name)
                if (labelRule) setCurLabelRule(labelRule)
                else {
                  setTab('')
                  setCurLabelRule({
                    name: '',
                    description: '',
                    enabled: true,
                    rules: [],
                    effects: []
                  })
                }
              }
              setValue(res.data.length)
              setLabelRuleDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No label-rules have been created yet.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  ///--------------------------------------------------------------------------
  // This is a reference that will be added to a div in order to know the
  // height, this so that we can make sure the object and its components
  // correctly respond to the height changes.
  //--------------------------------------------------------------------------
  const {
    ref,
    height
  } = useElementSize();

  ///--------------------------------------------------------------------------
  // This is the actual object of the resource explorer page.
  //--------------------------------------------------------------------------
  return (
    <div ref={ref} className="w-7/8 p-5 flex h-full">
      <div className="flex-auto w-4/6 h-full">
        <div className="border border-gray-200 rounded-lg bg-white shadow-md h-full pt-1">
          <MakeTable
            height={height}
            addLabelRuleCallBack={(rule: ruleTypeWithId) => {
              setRuleToAdd(rule)
            }}
          />
        </div>
      </div>
      <div className="w-2/6 h-full border border-gray-200 rounded-lg bg-white shadow-md ml-2 pt-1">
        <div className="w-full border-b border-gray-200 text-gray-800 flex justify-between h-7 px-1">
          Choose a label rule to edit or create a new one:
        </div>
        <div className="w-full border-b-2 border-[#668957]">
          <span className="text-gray-500 text-sm px-2">Label rules:</span>
          <Tabs
            value={value}
            onChange={(event: SyntheticEvent, newValue: number) => {
              setValue(newValue);
            }}
            sx={{
              '& .Mui-selected': {
                color: '#668957',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#668957',
                color: '#668957'
              }
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable force tabs example"
          >
            {
              labelRuleData.concat([{
                name: '',
                description: '',
                enabled: true,
                rules: [],
                effects: []
              }]).map((labelRule) => {
                return (
                  <Tab
                    label={labelRule.name === '' ? <FaPlus /> : labelRule.name}
                    onClick={() => {
                      setTab(labelRule.name)
                      setInitalName(labelRule.name)
                      setCurLabelRule({
                        name: labelRule.name,
                        description: labelRule.description,
                        enabled: labelRule.enabled,
                        rules: labelRule.rules,
                        effects: labelRule.effects
                      })
                    }}
                  />
                )
              })
            }
          </Tabs>
        </div>
        <div className="p-1 h-5/6">
          <LabelRuleCreator
            labelRule={curLabelRule}
            setLabelRuleCallBack={setCurLabelRule}
            onButtonClick={(labelRule: labelRuleType) => {
              setCurLabelRule(labelRule)
              setShowMutationModal(true)
            }}
          />
        </div>
      </div>
      {(showMutationModal) && (
        <MutationNewLabelRulesModal
          isOpen
          data={undefined}
          update={!(initialName === '')}
          onMutated={() => {
            setLabelRuleDataProcessed(false)
            setCurLabelRule({
              name: '',
              description: '',
              enabled: true,
              rules: [],
              effects: []
            })
            setQueryKey(uuid())
          }}
          onClose={() => {
            setShowMutationModal(false)
          }}
          initialLabelRule={curLabelRule}
          initialName={initialName}
        />
      )}
    </div>
  )
}
