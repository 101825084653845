export function createModal(list: { label: string, func: () => void }[], id:string) {
  return (
    <div key={`theOptionsListOf${id}`}>
      {list.map((el) => {
        return (
          <div
            key={`anOptionLabel${el.label}Of${id}`}
            data-tour={`anOptionLabel${el.label}Of${id}`}
            className="border-b border-gray-100 hover:bg-gray-50 py-2 px-2"
            onClick={() => {
              el.func()
            }}
          >
            {el.label}
          </div>
        )
      })}
    </div>
  )
}
