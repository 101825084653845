export const SPACING = {
  GRAPH_WRAPPER: 20,
  GRAPH_GROUP: 20,
  GRAPH_CARD: 12
}

export const MIN_HEIGHT = {
  GRAPH_CONTENT: 400,
  TABLE: 400
}
