export const tourConfig = (id: string, selector: string, callbackList: (() => void)[]) => {
  if (selector === 'accumulateFalseChart') {
    return [
      {
        selector: `[data-tour="labelSelectForLabeledDataChart${id}"]`,
        content: 'Here it is possible to select a label or field value. This will then fetch the data for that specific value.'
      },
      {
        selector: `[data-tour="windowSelectForLabeledDataChart${id}"]`,
        content: 'Here you can change the interval for which you want to see data. This will then fetch the data for that interval.'
      },
      {
        selector: `[data-tour="accumulateFalseChart${id}"]`,
        content: 'Afterwards your data will be represented here in the form of a stacked bar chart.'
      },
      {
        selector: `[data-tour="theOptionsButtonFor${id}"]`,
        content: 'Press this to open options, here you can change and delete the widget.',
        action: () => { callbackList[0]() }
      },
      {
        selector: `[data-tour="anOptionLabelChange to pie chartOf${id}"]`,
        content: 'Pressing this will change the widget to an aggregated view of the data.',
      },
      {
        selector: `[data-tour="LabeledDataChart${id}"]`,
        content: 'This is said view.',
        action: () => {
          callbackList[1]()
          callbackList[2]()
        }
      },
      {
        selector: `[data-tour="LabeledDataChart${id}"]`,
        content: 'Enjoy discovering your data',
        action: () => {
          callbackList[3]()
          callbackList[4]()
        }
      }
    ]
  } return [
    {
      selector: `[data-tour="labelSelectForLabeledDataChart${id}"]`,
      content: 'Here it is possible to select a label or field value. This will then fetch the data for that specific value aggregated over the last month.'
    },
    {
      selector: `[data-tour="accumulateTrueChart${id}"]`,
      content: 'Afterwards your data will be represented here in the form of a stacked bar chart. The Pie pieces are clickable which let you delve deeper in the chosen data.'
    },
    {
      selector: `[data-tour="accumulateTrueChartPath${id}"]`,
      content: 'After clicking on a piece you will be able to see the path growing, when you click on a section of this path you are able to go back to that place in the path.'
    },
    {
      selector: `[data-tour="theOptionsButtonFor${id}"]`,
      content: 'Press this to open options, here you can change and delete the widget.',
      action: () => { callbackList[0]() }
    },
    {
      selector: `[data-tour="anOptionLabelChange to bar chartOf${id}"]`,
      content: 'Pressing this will change the widget to a time interval view of the data.',
      // action: () => { callbackList[0]() }
    },
    {
      selector: `[data-tour="LabeledDataChart${id}"]`,
      content: 'This is said view.',
      action: () => {
        callbackList[1]()
        callbackList[2]()
      }
    },
    {
      selector: `[data-tour="LabeledDataChart${id}"]`,
      content: 'Enjoy discovering your data',
      action: () => {
        callbackList[3]()
        callbackList[4]()
      }
    }
  ]
}
