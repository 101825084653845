import { WidgetData } from './widgetStatev2'

interface DeletionModalProps {
  isOpen: boolean,
  id: string,
  widgetObject: WidgetData
  changeId: () => void
}
export function DeletionModal({
  isOpen,
  id,
  changeId,
  widgetObject
}: DeletionModalProps) {
  if (!isOpen) return null
  return (
    <div>
      <div className="text-center">
        Delete widget?
      </div>
      <div className="flex py-2">
        <div className="px-1 flex justify-items-center">
          <div
            // type="button"
            className="bg-white hover:bg-gray-600 text-black hover:text-white border-2 rounded-lg border-black w-16"
            onClick={() => {
              changeId()
            }}
          >
            Cancel
          </div>
        </div>
        <div className="px-3">
          <div
            // type="button"
            className="bg-[#BB0A1E] hover:bg-[#c83a4a] text-white border-2 rounded-lg border-[#BB0A1E] w-16"
            onClick={() => {
              widgetObject.deleteFromWidgetList(id)
              changeId()
            }}
          >
            Delete
          </div>
        </div>
      </div>
    </div>

  )
}
