import { useEffect, useState, useCallback, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import useExternalCost from 'hooks/useExternalCost'

import {
  SpinnerLoader,
  FetchStatus,
  FETCH_STATUS,
  GraphCard,
  GraphCardContent,
  GraphCardGroup,
  GraphCardWrapper,
  Table,
  TTableColumn,
  TTableData
} from 'components'
import useAuthentication from 'hooks/useAuthentication'
import { mergeAllocationTotals, formatCost } from 'utils'

const columns: TTableColumn[] = [
  { name: 'Provider', field: 'environment', width: 170 },
  { name: 'Product', field: 'name', width: 300 },
  {
    name: 'Cost',
    field: 'cost',
    width: 300,
    format: formatCost
  }
]

function ExternalCosts() {
  const { currency } = useAuthentication()
  const [initialLoading, setInitialLoading] = useState(true)
  const [fetchExternalCostStatus, setFetchExternalCostStatus] = useState(
    FETCH_STATUS.START
  )
  const [services, setServices] = useState<{ name: string }[]>([])
  const [monthlyCost, setMonthlyCost] = useState(0)
  const [tableData, setTableData] = useState<TTableData[]>([])

  const { getExternalCost } = useExternalCost()

  const formatOptions = useMemo(() => ({ currency }), [currency])

  const fetchExternalCost = useCallback(() => {
    getExternalCost(
      { accumulate: 'true', window: '30d' },
      setFetchExternalCostStatus
    ).then((externalCostList) => {
      const allocationTotals = mergeAllocationTotals(externalCostList)

      let total = 0
      const tableData: TTableData[] = []
      Object.entries(allocationTotals).forEach(([name, externalCostData]) => {
        total += externalCostData.totalCost
        tableData.push({
          environment: 'AWS',
          name,
          cost: externalCostData.withNull.totalCost
        })
      })

      setMonthlyCost(total)
      setServices(Object.keys(allocationTotals).map((name) => ({ name })))
      setTableData(tableData)
      setInitialLoading(false)
    })
  }, [getExternalCost])

  useEffect(() => {
    setInitialLoading(true)
    fetchExternalCost()
  }, [fetchExternalCost])

  return (
    <GraphCardWrapper>
      {initialLoading ? (
        <Skeleton height={36} containerClassName="w-48" />
      ) : (
        <div className="flex justify-between items-center">
          <p className="text-xl font-medium">External costs</p>
          <button
            type="button"
            className="bg-primary-color text-white rounded-md py-2.5 px-7"
          >
            Configure
          </button>
        </div>
      )}

      <GraphCardGroup>
        <GraphCard isLoading={initialLoading} isHoverable height={86}>
          <div className="grow flex flex-col justify-center items-center">
            <p className="text-3xl font-semibold text-gray-800">
              {services.length}
            </p>
            <p className="text-base font-medium text-gray-800">Services</p>
          </div>
        </GraphCard>
        <GraphCard isLoading={initialLoading} isHoverable height={86}>
          <div className="grow flex flex-col justify-center items-center">
            <SpinnerLoader isLoading={fetchExternalCostStatus.isLoading}>
              <p className="text-3xl font-semibold text-gray-800">
                {formatCost(monthlyCost, { currency })}
              </p>
            </SpinnerLoader>
            <p className="text-base font-medium text-gray-800">Monthly Cost</p>
          </div>
        </GraphCard>
        <GraphCard isHide />
      </GraphCardGroup>

      <GraphCardGroup grow>
        <GraphCard isLoading={initialLoading} isOutline={false}>
          <div className="w-full flex items-center">
            <span className="text-lg font-medium text-gray-800">Services</span>
          </div>
          <GraphCardContent className="overflow-x-auto">
            <FetchStatus
              status={fetchExternalCostStatus}
              retry={fetchExternalCost}
            >
              <Table
                columns={columns}
                data={tableData}
                formatOptions={formatOptions}
              />
            </FetchStatus>
          </GraphCardContent>
        </GraphCard>
      </GraphCardGroup>
    </GraphCardWrapper>
  )
}

export default ExternalCosts
