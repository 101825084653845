import { useState, useEffect } from 'react'
import { ButtonIcon } from 'components'
import {
  HiOutlinePresentationChartBar,
  HiOutlinePresentationChartLine,
  HiOutlineChartBar
} from 'react-icons/hi'
import { mapClasses } from 'utils'

interface ChartButtonGroupProps {
  onClick?: (chartType: 'line' | 'bar' | 'area') => void
  selectedButton?: 'line' | 'bar' | 'area' | undefined
}

function ChartButtonGroup({ selectedButton, onClick }: ChartButtonGroupProps) {
  const [selectedChartType, setSelectedChartType] = useState<
    'line' | 'area' | 'bar'
  >(selectedButton || 'line')

  useEffect(() => {
    if (onClick) {
      onClick(selectedChartType)
    }
  }, [selectedChartType])

  useEffect(() => {
    setSelectedChartType(selectedButton || 'line')
  }, [selectedButton])

  return (
    <div className="py-2">
      <div className="flex bg-white border border-dark-grey rounded-md">
        <input
          type="hidden"
          style={{ display: 'none' }}
          name="chartType"
          id="chartType"
          value={selectedChartType}
        />
        <div
          className={mapClasses(
            'flex items-center border-r border-inherit p-2 cursor-pointer hover:bg-red-500 hover:bg-opacity-10 text-gray-500 hover:text-red-500',
            selectedChartType === 'bar' && 'bg-blue-500'
          )}
        >
          <ButtonIcon
            variant="text"
            color="primary"
            onClick={() => setSelectedChartType('bar')}
          >
            <HiOutlinePresentationChartBar size={30} />
          </ButtonIcon>
        </div>
        <div
          className={mapClasses(
            'flex items-center border-r border-inherit p-2 cursor-pointer hover:bg-red-500 hover:bg-opacity-10 text-gray-500 hover:text-red-500',
            selectedChartType === 'line' && 'bg-blue-500'
          )}
        >
          <ButtonIcon
            variant="text"
            color="primary"
            onClick={() => setSelectedChartType('line')}
          >
            <HiOutlinePresentationChartLine size={30} />
          </ButtonIcon>
        </div>
        <div
          className={mapClasses(
            'flex items-center border-r border-inherit p-2 cursor-pointer hover:bg-red-500 hover:bg-opacity-10 text-gray-500 hover:text-red-500',
            selectedChartType === 'area' && 'bg-blue-500'
          )}
        >
          <ButtonIcon
            variant="text"
            color="primary"
            onClick={() => setSelectedChartType('area')}
          >
            <HiOutlineChartBar size={30} />
          </ButtonIcon>
        </div>
      </div>
    </div>
  )
}

export default ChartButtonGroup
