import { v4 as uuid } from 'uuid'
import {
  TLabelRule,
  TLabelRuleFormData,
  TLabelRuleItemData,
  TLabelRuleRule,
  TLabelRuleEffect,
  TLabelRuleDisplay,
  TLabelRuleEffectsDisplay
} from 'services/label.rule.interface'
import { LABEL_RULE_ELEMENT_TYPE } from './label.rule.constants'
import { effectType } from './queryHelperFunctions'

// ***********************************************
// Update Update Edit or Delete item from the list
// ***********************************************
export const updateRulesList = (
  items: TLabelRuleItemData[],
  updateItem: TLabelRuleItemData,
  deleteItem = false
) => {
  // Find the item in list
  const conditionIndex = items.findIndex(
    ({ id }) => id === updateItem.id
  )

  // If found and deleteItem is true, return the list without the item
  if (deleteItem) {
    if (conditionIndex < 0) return items // not found
    return [
      ...items.slice(0, conditionIndex),
      ...items.slice(conditionIndex + 1)
    ]
  }
  // If not found, return the list and add the new item
  if (conditionIndex < 0) {
    return [...items, updateItem]
  }

  // update the item
  return [
    ...items.slice(0, conditionIndex),
    updateItem,
    ...items.slice(conditionIndex + 1)
  ]
}

// *****************************************
// Convert label rule data to form data type
// *****************************************
export const castLabelRuleToFormData = ((data: TLabelRule) => {
  const rulesData: TLabelRuleItemData[] = []
  const effectData: TLabelRuleItemData[] = []

  data.rules?.forEach((rule) => {
    rulesData.push({
      operand: rule.operand,
      value: rule.value,
      key: rule.key,
      type: LABEL_RULE_ELEMENT_TYPE.RULES,
      id: uuid(),
    })
  })

  data.effects?.forEach((effect) => {
    effectData.push({
      operand: undefined,
      value: effect.value,
      key: effect.key,
      type: LABEL_RULE_ELEMENT_TYPE.EFFECTS,
      id: uuid(),
    })
  })

  const formData: TLabelRuleFormData = {
    name: data.name,
    description: data.description || '',
    enabled: data.enabled,
    rules: rulesData,
    effects: effectData,
    id: uuid(),
  }
  return formData
})

// *****************************************
// Convert form data type to label rule data
// *****************************************
export const castFormDataToLabelRule = ((data: TLabelRuleFormData) => {
  const rulesData: TLabelRuleRule[] = []
  const effectData: TLabelRuleEffect[] = []

  data.rules?.forEach((rule) => {
    rulesData.push({
      operand: rule.operand || 'equals',
      value: rule.value,
      key: rule.key,
    })
  })

  data.effects?.forEach((effect) => {
    effectData.push({
      value: effect.value,
      key: effect.key
    })
  })

  const formData: TLabelRule = {
    name: data.name,
    description: data.description || '',
    enabled: data.enabled,
    rules: rulesData,
    effects: effectData,
  }
  return formData
})

// *****************************************
// Convert label rule data to form data type
// *****************************************
function getEffects(effect: effectType): TLabelRuleEffectsDisplay[] {
  const res: TLabelRuleEffectsDisplay[] = []
  res.push({
    value: effect.value,
    key: effect.key,
    id: uuid(),
  })
  if (effect.children) {
    effect.children.forEach((child) => getEffects(child).forEach((el) => res.push(el)))
  }
  return res
}
export const castLabelRuleToDisplay = ((data: TLabelRule[]) => {
  const newData: TLabelRuleDisplay[] = []
  data.forEach((item) => {
    const formData: TLabelRuleDisplay = {
      name: item.name,
      description: item.description || '',
      enabled: item.enabled,
      rules: [],
      effects: [],
      id: uuid(),
    }
    item.rules?.forEach((rule) => {
      formData.rules.push({
        operand: rule.operand,
        value: rule.value,
        key: rule.key,
        id: uuid(),
      })
    })

    item.effects.forEach((effect) => getEffects(effect as effectType).forEach((el) => {
      formData.effects.push(el)
    }))
    newData.push(formData)
  })

  return newData
})

// *****************************************
// Convert form data type to label rule data
// *****************************************
export const castDisplayToLabelRule = ((data: TLabelRuleDisplay) => {
  const rulesData: TLabelRuleRule[] = []
  const effectData: TLabelRuleEffect[] = []

  data.rules?.forEach((rule) => {
    rulesData.push({
      operand: rule.operand || 'EQUALS',
      value: rule.value,
      key: rule.key,
    })
  })

  data.effects?.forEach((effect) => {
    effectData.push({
      value: effect.value,
      key: effect.key,
    })
  })

  const formData: TLabelRule = {
    name: data.name,
    description: data.description || '',
    enabled: data.enabled,
    rules: rulesData,
    effects: effectData,
  }
  return formData
})

// *****************************************
// Convert form data type to label rule data
// *****************************************
export const enableDisableLabelRule = ((data: TLabelRule) => {
  const rulesData: TLabelRuleRule[] = []
  const effectData: TLabelRuleEffect[] = []

  data.rules?.forEach((rule) => {
    rulesData.push({
      operand: rule.operand || 'EQUALS',
      value: rule.value,
      key: rule.key,
    })
  })

  data.effects?.forEach((effect) => {
    effectData.push({
      value: effect.value,
      key: effect.key,
    })
  })

  const formData: TLabelRule = {
    name: data.name,
    description: data.description || '',
    enabled: !data.enabled,
    rules: rulesData,
    effects: effectData,
  }
  return formData
})
