import {
  createContext,
  ReactElement,
  useCallback,
  useMemo,
} from 'react'
import { TLabelRule } from 'services/label.rule.interface'
import {
  TFetchLabelRulesFunction,
  TFetchLabelRuleFunction,
  TCreateLabelRuleFunction,
  TUpdateLabelRuleFunction,
  TDeleteLabelRuleFunction,
  TEnableLabelRuleFunction,
} from 'services/fetch.interface'
import { FETCH_STATUS } from 'components'
import { apiErrorHandler, axios } from 'utils'

// *************************
// Interface for the context
// *************************
interface LabelRuleContextType {
  getLabelRulesFilter: TFetchLabelRulesFunction
  getLabelRuleFilter: TFetchLabelRuleFunction
  createLabelRule: TCreateLabelRuleFunction
  updateLabelRule: TUpdateLabelRuleFunction
  deleteLabelRule: TDeleteLabelRuleFunction
  patchLabelRule: TEnableLabelRuleFunction
}

// **********************************
// Default value for LabelRuleContext
// **********************************
const BLANK_LABEL_RULE: TLabelRule = { name: '', rules: [], description: '', enabled: false, effects: [] }

// ****************
// LabelRuleContext
// ****************
const LabelRuleContext = createContext<LabelRuleContextType>({} as LabelRuleContextType)

// ************************
// LabelRuleContextProvider
// ************************
export function LabelRuleContextProvider({
  children
}: {
  children: ReactElement
}) {
  // ************************
  // Get a list of LabelRules
  // ************************
  const getLabelRulesFilter = useCallback<TFetchLabelRulesFunction>(
    async (_, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<TLabelRule[]>('/label-rules')
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || []
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return []
      }
    },
    []
  )

  // ****************
  // Get a LabelRules
  // ****************
  const getLabelRuleFilter = useCallback<TFetchLabelRuleFunction>(
    async ({ labelRuleName }, updateStatus) => {
      try {
        const url = `/label-rules/${labelRuleName}`
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<TLabelRule>(url)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || BLANK_LABEL_RULE
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return BLANK_LABEL_RULE
      }
    },
    []
  )

  // *******************
  // Create a LabelRules
  // *******************
  const createLabelRule = useCallback<TCreateLabelRuleFunction>(
    async (body, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.post<TLabelRule>('/label-rules', body)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || BLANK_LABEL_RULE
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return BLANK_LABEL_RULE
      }
    },
    []
  )

  // *****************
  // Update LabelRules
  // *****************
  const updateLabelRule = useCallback<TUpdateLabelRuleFunction>(
    async ({ labelRuleName, body }, updateStatus) => {
      try {
        const url = `/label-rules/${labelRuleName}`
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.put<TLabelRule>(url, body)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || BLANK_LABEL_RULE
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return BLANK_LABEL_RULE
      }
    },
    []
  )

  // *****************
  // Delete LabelRules
  // *****************
  const deleteLabelRule = useCallback<TDeleteLabelRuleFunction>(
    async ({ labelRuleName }, updateStatus) => {
      try {
        const url = `/label-rules/${labelRuleName}`
        updateStatus?.(FETCH_STATUS.START)
        await axios.delete<TLabelRule>(url)
        updateStatus?.(FETCH_STATUS.SUCCESS)
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
      }
    },
    []
  )

  // *************************
  // Enable/Disable LabelRules
  // *************************
  const patchLabelRule = useCallback<TEnableLabelRuleFunction>(
    async ({ labelRuleName, body }, updateStatus) => {
      try {
        const url = `/label-rules/${labelRuleName}`
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.patch<TLabelRule>(url, body)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data || BLANK_LABEL_RULE
      } catch (error) {
        updateStatus?.(FETCH_STATUS.FAIL)
        apiErrorHandler(error)
        return BLANK_LABEL_RULE
      }
    },
    []
  )

  // ********************************************
  // Pass the methods to the value of the context
  // ********************************************
  const value = useMemo<LabelRuleContextType>(
    () => ({
      getLabelRulesFilter,
      getLabelRuleFilter,
      createLabelRule,
      updateLabelRule,
      deleteLabelRule,
      patchLabelRule,
    }),
    [
      getLabelRulesFilter,
      getLabelRuleFilter,
      createLabelRule,
      updateLabelRule,
      deleteLabelRule,
      patchLabelRule,
    ]
  )
  return (
    <LabelRuleContext.Provider value={value}>{children}</LabelRuleContext.Provider>
  )
}

export default LabelRuleContext
