import { RiLogoutCircleRLine } from 'react-icons/ri'
import useAuthentication from 'hooks/useAuthentication'

// ****************************
// Sign in button for Key cloak
// ****************************
function LogOutKeyCloakButton() {
  // *********
  // Constants
  // *********
  const { LogOut } = useAuthentication()

  // ***********************
  // Render the login button
  // ***********************
  return (
    <div
      onClick={LogOut}
      className="hidden phone:flex px-3 flex-col items-center cursor-pointer"
    >
      <RiLogoutCircleRLine size={30} className="text-icon-color" />
      <span className="text-sm font-medium text-icon-color">Logout</span>
    </div>
  )
}

export default LogOutKeyCloakButton
