import {
  useEffect,
  useState
} from 'react'
// import { MdAddToPhotos } from 'react-icons/md';
import {
  GraphCardWrapper,
  // GraphCardGroup,
  SkeletonWrapper,
  SpinnerLoader
} from 'components'
import useProject from 'hooks/useProject'

import './index.scss'
import { DragDropComponent } from 'features/projects/project-widget/widgetDragFunctionality';
import { axios } from 'utils';
import { v4 as uuid } from 'uuid'
import { useQuery } from 'react-query';
import { WidgetData } from 'features/projects/project-widget/widgetStatev2';
import { useTour } from '@reactour/tour';
import { Accordion } from '@mantine/core';

import { TutorialContainer } from 'features/projects/project-tutorial';
import { Bounce, toast } from 'react-toastify';
import { tourConfig } from './tour';
import { ButtonWithModal } from './ButtonWithModal';
import { SummaryBar } from './SummaryBar';

function Home() {
  const { setSteps } = useTour()
  const groceries = [
    {
      emoji: '🍎',
      value: 'Apples',
      description:
        SummaryBar(),
    }
  ];
  const [opened, setOpened] = useState<string>('')
  const items = groceries.map((item) => (
    <Accordion.Item className="border-b-0" key={item.value} value={item.value}>
      {/* <Accordion.Control icon={item.emoji}>{item.value}</Accordion.Control> */}
      <Accordion.Panel>{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  // const [summaryWidgets, setSummaryWidgets] = useState<{ label: string, id: string }[]>([
  //   { label: 'PreviousMonthSpend', id: '1' },
  //   { label: 'ThisMonthSpend', id: '2' },
  //   { label: 'TopServiceBySpend', id: '3' },
  //   { label: 'RegionPercentage', id: '4' }
  // ])
  // const [summaryAddView, setSummaryAddView] = useState<boolean>(true)
  // const changeSummaryAddView = () => {
  //   setSummaryAddView(!summaryAddView)
  // }
  // const addSummaryWidget = (label: string) => {
  //   setSummaryWidgets(summaryWidgets.concat({ label, id: uuid() }))
  // }
  // const deleteSummaryWidget = (id: string) => {
  //   const spliced = summaryWidgets.filter((el) => el.id !== id)
  //   setSummaryWidgets(spliced)
  // }
  // // const [parent, setParent] = useState<string | null>(null);
  // function handleDragEnd({ active, over }: DragEndEvent) {
  //   if (over !== null) {
  //     if (active.id !== over.id) {
  //       const oldIndex = summaryWidgets.findIndex((el) => el.id === active.id);
  //       const newIndex = summaryWidgets.findIndex((el) => el.id === over.id);

  //       setSummaryWidgets(arrayMove(summaryWidgets, oldIndex, newIndex));
  //     }
  //   }
  // }
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>(uuid())
  const { initialLoading } = useProject()
  const [reload, setReload] = useState(1);
  const [show, setShow] = useState(false);
  const [labels, setLabels] = useState<[]>([])
  const [widgetObject, setWidgetObject] = useState<WidgetData>(new WidgetData())
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [widgetsLoaded, setWidgetsLoaded] = useState<boolean>(false)
  const [widgetsNeedUpdating, setWidgetsNeedUpdating] = useState<boolean>(false)
  const [fieldsProcessed, setFieldsProcessed] = useState<boolean>(false)
  const [fields, setFields] = useState<string[]>([])
  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }
  const toggleShow = () => {
    setShow(!show)
  }
  const toggleShowOff = () => {
    setShow(false)
  }

  const AddLabeledDataChart = (tag: string) => {
    widgetObject.createWidget(tag)
    setReload(reload + 1)
  }
  const updateCallback = () => {
    setWidgetsNeedUpdating(true)
    setQueryKey(uuid())
  }
  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .get('/labels')
            .then((res) => {
              setLabels(res.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (!widgetsLoaded && !widgetObject.initiallyLoaded) {
          await axios
            .get('/store/widgets')
            .then((res) => {
              setWidgetsLoaded(true)
              const temp = new WidgetData()
              temp.setCallback(() => { updateCallback() })
              temp.setWidgets(JSON.parse(res.data.storeValue).widgets)
              temp.setInitiallyLoaded()
              setWidgetObject(temp)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('Widgets are not set up yet.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (widgetsNeedUpdating) {
          await axios
            .put('/store/widgets', { widgets: widgetObject.widgets })
            .then(() => {
              setWidgetsNeedUpdating(false)
              widgetObject.resourceUpdateFinished()
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('Cannot update the widgets.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (!fieldsProcessed) {
          await axios
            .get('/fields')
            .then((res) => {
              let arr: string[] = res.data
              arr = arr.map((str) => {
                const splittesString = str.split('_')
                let res = ''
                splittesString.forEach((part) => {
                  if (res === '') {
                    res = part.charAt(0).toUpperCase() + part.slice(1)
                  } else {
                    res = `${res} ${part}`
                  }
                })
                return res
              })
              setFields(arr)
              setFieldsProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No resources available.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    if (setSteps) {
      setSteps(
        tourConfig(
          [toggleShow, toggleShowOff, changeTutorialUpdater]
        )
      )
    }
  }, [widgetObject])

  window.addEventListener('mouseup', () => {
    if (widgetObject.widgetChanged) {
      widgetObject.setWidgetChanged(false)
      setWidgetsNeedUpdating(true)
      setQueryKey(uuid())
    }
  })
  // const sensors = useSensors(
  //   useSensor(PointerSensor),
  //   useSensor(KeyboardSensor, {
  //     coordinateGetter: sortableKeyboardCoordinates,
  //   })
  // );

  if (error) return <div>An error has occurred</div>
  return (
    <div data-tour="theHomePage">
      <TutorialContainer
        tag="Homepage"
        updater={tutorialUpdater}
        tourConfig={
          tourConfig(
            [toggleShow, toggleShowOff, changeTutorialUpdater]
          )
        }
      >
        <div>
          <Accordion color="#5b7b4e" className="flex bg-[#5b7b4e] shadow-inner border border-[#5b7b4e] justify-center h-full p-0 m-0" value={opened}>
            {items}
          </Accordion>
          <GraphCardWrapper noPadding spacing={0}>
            <SkeletonWrapper isLoading={initialLoading} height={20} width={200}>
              <div className="flex items-center justify-between">
                <span className="text-xl font-medium">Dashboard</span>
                <div className="flex justify-end mr-3">
                  <button
                    className="bg-[#5b7b4e] text-white p-2 rounded-b-lg justify-end"
                    type="button"
                    onClick={() => {
                      if (opened === '') {
                        setOpened('Apples')
                      } else {
                        setOpened('')
                      }
                    }}
                  >
                    show summary
                  </button>
                </div>
              </div>
            </SkeletonWrapper>

            <ButtonWithModal
              title="Add widget"
              listOfNames={[
                {
                  label: 'predifined:',
                  lst: [
                    'Total spend by product',
                    'Spend for previous Month and trends'
                  ]
                },
                {
                  label: 'configurable:',
                  lst: [
                    'Aggregate cost by widget',
                    'Group cost by widget',
                    'Optiminzation Widget',
                  ]
                }
              ]}
              listOfCallback={[
                {
                  label: 'predifined:',
                  lst: [
                    () => { AddLabeledDataChart('predefineWidgetAccTrue') },
                    () => { AddLabeledDataChart('predefineWidgetAccFalse') }
                  ]
                },
                {
                  label: 'configurable:',
                  lst: [
                    () => { AddLabeledDataChart('projectTable') },
                    () => { AddLabeledDataChart('cumulativeCost') },
                    () => { AddLabeledDataChart('optimizationWidget') }
                  ]
                }
              ]}
              ctr={widgetObject.widgets.length}
              maxAmount={999}
            />
            <div className="xs:p-5 tablet:p-5">
              <SpinnerLoader isLoading={!dataProcessed
                || !fieldsProcessed
                || !widgetsLoaded}
              >
                <div className="py-5">
                  {(dataProcessed && fieldsProcessed && widgetsLoaded) && (
                    <DragDropComponent
                      labels={labels}
                      fields={fields}
                      widgetObject={widgetObject}
                    />
                  )}
                </div>
              </SpinnerLoader>
            </div>
          </GraphCardWrapper>
        </div>
      </TutorialContainer>
    </div>
  )
}

export default Home
