import { ReactNode } from 'react'
import Skeleton, { SkeletonProps } from 'react-loading-skeleton'

export interface SkeletonWrapperProps extends SkeletonProps {
  isLoading: boolean
  children: ReactNode
}

function SkeletonWrapper({
  isLoading,
  children,
  ...props
}: SkeletonWrapperProps) {
  if (!isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return <Skeleton {...props} />
}

export default SkeletonWrapper
