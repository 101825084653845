import { TTableColumn } from 'components'
import { formatCost, formatDateRange, formatNilValue, formatNumber } from 'utils'

export const REPORT_FILTER_TABLE_COLUMNS: TTableColumn[] = [
  {
    name: 'Report',
    field: 'name',
    width: '33%',
    format: formatNilValue
  },
  {
    name: 'Date range',
    field: 'chart.window',
    width: '33%',
    format: formatDateRange
  },
  {
    name: 'Description',
    field: 'description',
    width: '33%',
    format: formatNilValue
  }
]

export const REPORT_TABLE_COLUMNS: TTableColumn[] = [
  { name: 'Project', field: 'project', width: 200 },
  {
    name: 'Application',
    field: 'application',
    width: 200,
    format: formatNilValue
  },
  {
    name: 'Component',
    field: 'component',
    width: 200,
    format: formatNilValue
  },
  {
    name: 'dailyCost',
    field: 'dailyCost',
    width: 200,
    format: formatCost
  },
  {
    name: 'weeklyCost',
    field: 'weeklyCost',
    width: 200,
    format: formatCost
  },
  {
    name: 'monthlyCost',
    field: 'monthlyCost',
    width: 200,
    format: formatCost
  }
]

const colWidth = 250
export const focusTableColumns: TTableColumn[] = [
  { name: 'timestamp', field: 'timestamp', width: colWidth },
  { name: 'resourceId', field: 'resourceId', width: colWidth },
  { name: 'provider', field: 'provider', width: colWidth },
  { name: 'serviceName', field: 'serviceName', width: colWidth },
  // { name: 'invoiceIssuer', field: 'invoiceIssuer', width: 300 },
  // { name: 'resourceName', field: 'resourceName', width: 300 },
  // { name: 'billingAccountName', field: 'billingAccountName', width: 300 },
  { name: 'Account Id', field: 'billingAccountId', width: colWidth },
  {
    name: 'Usage Qty',
    field: 'totalUsageQuantity',
    width: colWidth,
    format: formatNumber
  },
  {
    name: 'Effective Cost',
    field: 'totalEffectiveCost',
    width: colWidth,
    format: formatCost
  },
  {
    name: 'Pricing Unit',
    field: 'totalPricingUnit',
    width: colWidth,
    format: formatNumber
  },
  {
    name: 'Billed Cost',
    field: 'totalBilledCost',
    width: colWidth,
    format: formatCost
  },
  {
    name: 'List Cost',
    field: 'totalListCost',
    width: colWidth,
    format: formatCost
  },
  { name: 'Pricing Qty', field: 'totalPricingQuantity', width: colWidth },
  {
    name: 'List Unit Price',
    field: 'totalListUnitPrice',
    width: colWidth,
    format: formatCost
  }
]
