import { Navigate } from 'react-router-dom'
import useAuthentication from 'hooks/useAuthentication'
import SigninForm from './SigninForm'

function Signin() {
  const { isLoading, isAuthenticated } = useAuthentication()

  if (!isLoading && isAuthenticated) {
    return <Navigate to="/" />
  }

  return (
    <SigninForm />
  )
}

export default Signin
