import { jsonData } from './constants'

export const initialVisibility = {
  appTags: false,
  availabilityZone: false,
  billingAccountId: false,
  billingAccountName: false,
  billingCurrency: false,
  chargeCategory: false,
  chargeDescription: false,
  chargeFrequency: true,
  chargeSubcategory: false,
  commitmentDiscountCategory: false,
  commitmentDiscountId: false,
  commitmentDiscountName: false,
  commitmentDiscountType: false,
  environmentTags: false,
  invoiceIssuer: true,
  pricingCategory: false,
  pricingUnit: false,
  provider: true,
  publisher: false,
  region: true,
  resourceId: false,
  resourceName: false,
  resourceType: false,
  serviceCategory: true,
  serviceName: true,
  skuId: false,
  skuPriceId: false,
  subAccountId: false,
  subAccountName: false,
  usageUnit: false,
  dailyCost: false,
  weeklyCost: false,
  monthlyCost: false,
  effectiveCost: true,
  listCost: false,
  pricingQuantity: false,
  usageQuantity: false,
  billedCost: false,
  listUnitPrice: false
}

export const focusInitialColumns = {
  availabilityZone: false,
  billingAccountId: false,
  billingAccountName: false,
  billingCurrency: false,
  billingPeriodEnd: false,
  billingPeriodStart: false,
  chargeCategory: false,
  chargeDescription: false,
  chargeFrequency: true,
  chargePeriodEnd: false,
  chargePeriodStart: false,
  chargeSubcategory: false,
  commitmentDiscountCategory: false,
  commitmentDiscountId: false,
  commitmentDiscountName: false,
  commitmentDiscountType: false,
  invoiceIssuer: true,
  pricingCategory: false,
  pricingUnit: false,
  provider: true,
  publisher: false,
  region: true,
  resourceId: false,
  resourceName: false,
  resourceType: false,
  serviceCategory: true,
  serviceName: true,
  skuId: false,
  skuPriceId: false,
  subAccountId: false,
  subAccountName: false,
  usageUnit: false,
  effectiveCost: true,
  listCost: false,
  pricingQuantity: false,
  usageQuantity: false,
  billedCost: false,
  listUnitPrice: false
}

export const focusAllTrueColumns = {
  availabilityZone: true,
  billingAccountId: true,
  billingAccountName: true,
  billingCurrency: true,
  billingPeriodEnd: true,
  billingPeriodStart: true,
  chargeCategory: true,
  chargeDescription: true,
  chargeFrequency: true,
  chargePeriodEnd: true,
  chargePeriodStart: true,
  chargeSubcategory: true,
  commitmentDiscountCategory: true,
  commitmentDiscountId: true,
  commitmentDiscountName: true,
  commitmentDiscountType: true,
  invoiceIssuer: true,
  pricingCategory: true,
  pricingUnit: true,
  provider: true,
  publisher: true,
  region: true,
  resourceId: true,
  resourceName: true,
  resourceType: true,
  serviceCategory: true,
  serviceName: true,
  skuId: true,
  skuPriceId: true,
  subAccountId: true,
  subAccountName: true,
  usageUnit: true,
  effectiveCost: true,
  listCost: true,
  pricingQuantity: true,
  usageQuantity: true,
  billedCost: true,
  listUnitPrice: true
}

export function toQueryString(columns: jsonData) {
  const listOfStrings = Object.getOwnPropertyNames(columns).map((el) => {
    if (columns[el as keyof typeof columns]) {
      return `${el}, `
    } return ''
  })
  let res = ''
  listOfStrings.forEach((el) => {
    res += el
  })
  return res
}
export function emptyObject() {
  const newObject: jsonData = {}
  Object.getOwnPropertyNames(initialVisibility).forEach((el) => {
    if (initialVisibility[el as keyof typeof initialVisibility]) {
      newObject[el] = undefined
    }
  })
  return newObject
}
