import { useEffect, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import _ from 'lodash'
import { HiOutlineChevronDoubleLeft } from 'react-icons/hi'

import { TFetchStatus } from 'services/fetch.interface'
import { TComponent } from 'services/component.interface'
import { ButtonIcon, GraphCard, GraphCardGroup } from 'components'
import AvailableComponentTable from './AvailableComponentTable'
import SelectedComponentTable from './SelectedComponentTable'
import ComponentYamlContent from './ComponentYamlContent'

type TComponentTableKey = 'selected' | 'available'

interface UpdateComponentsProps {
  isLoading: boolean
  fetchUncategorizedComponentsStatus: TFetchStatus
  fetchApplicationDetailStatus: TFetchStatus
  selectedList: TComponent[]
  availableList: TComponent[]
  onComponentsChange: (components: TComponent[]) => void
  fetchUncategorizedComponents: () => void
  fetchApplicationDetail: () => void
}

function UpdateComponents({
  isLoading,
  fetchUncategorizedComponentsStatus,
  fetchApplicationDetailStatus,
  selectedList,
  availableList: availableListProp,
  onComponentsChange,
  fetchUncategorizedComponents,
  fetchApplicationDetail
}: UpdateComponentsProps) {
  const [availableList, setAvailableList] = useState(availableListProp)
  const [selectingAvailableIndexList, setSelectingAvailableIndexList] =
    useState<number[]>([])
  const [yamlContent, setYamlContent] = useState<unknown>()

  const updateList = (tableKey: TComponentTableKey, list: TComponent[]) => {
    if (tableKey === 'selected') {
      onComponentsChange([...list])
    } else {
      setAvailableList([...list])
    }
  }

  const moveComponent = (
    source: {
      list: TComponent[]
      index: number
      key: TComponentTableKey
    },
    destination: {
      list: TComponent[]
      index: number
      key: TComponentTableKey
    }
  ) => {
    const newSourceList = source.list.filter((_, idx) => idx !== source.index)
    const newDestinationList = [...destination.list]
    newDestinationList.splice(destination.index, 0, source.list[source.index])

    updateList(source.key, newSourceList)
    updateList(destination.key, newDestinationList)
  }

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (_.isNil(destination)) return

    const sourceId = source.droppableId as TComponentTableKey
    const destinationId = destination.droppableId as TComponentTableKey

    if (sourceId === destinationId && destination.index === source.index) return

    const sourceList = sourceId === 'selected' ? selectedList : availableList
    const destinationList =
      destinationId === 'selected' ? selectedList : availableList

    // moving inside column
    if (sourceList === destinationList) {
      const newList = sourceList.filter((_, idx) => idx !== source.index)
      newList.splice(destination.index, 0, sourceList[source.index])

      updateList(sourceId, newList)
      return
    }

    moveComponent(
      { list: sourceList, index: source.index, key: sourceId },
      { list: destinationList, index: destination.index, key: destinationId }
    )
  }

  const handleSelect = () => {
    const movingList = availableList.filter((_, index) => {
      return selectingAvailableIndexList.includes(index)
    })
    const newAvailableList = availableList.filter((_, index) => {
      return !selectingAvailableIndexList.includes(index)
    })

    onComponentsChange([...selectedList, ...movingList])
    setAvailableList(newAvailableList)
    setSelectingAvailableIndexList([])
  }

  const handleUnselect = (index?: number) => {
    if (_.isNil(index)) return
    moveComponent(
      { list: selectedList, index, key: 'selected' },
      { list: availableList, index: availableList.length, key: 'available' }
    )
  }

  useEffect(() => setAvailableList(availableListProp), [availableListProp])

  return (
    <GraphCard isLoading={isLoading} grow={1} isOutline={false}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="grow flex gap-4">
          <GraphCardGroup grow>
            <SelectedComponentTable
              fetchStatus={fetchApplicationDetailStatus}
              list={selectedList}
              onClickInfo={setYamlContent}
              onClickDelete={handleUnselect}
              retry={fetchApplicationDetail}
            />
          </GraphCardGroup>

          <div className="shrink-0 flex flex-col items-center justify-center gap-4">
            <ButtonIcon className="p-3" onClick={() => handleSelect()}>
              <HiOutlineChevronDoubleLeft size={24} />
            </ButtonIcon>
          </div>

          <GraphCardGroup grow>
            <AvailableComponentTable
              fetchStatus={fetchUncategorizedComponentsStatus}
              list={availableList}
              selectingIndexList={selectingAvailableIndexList}
              setSelectingIndexList={setSelectingAvailableIndexList}
              onClickInfo={setYamlContent}
              retry={fetchUncategorizedComponents}
            />
          </GraphCardGroup>
        </div>
      </DragDropContext>

      {!!yamlContent && (
        <ComponentYamlContent
          yamlData={yamlContent}
          onClose={() => setYamlContent(null)}
        />
      )}
    </GraphCard>
  )
}

export default UpdateComponents
