import { StepType, useTour } from '@reactour/tour'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { axios } from 'utils'
import { v4 as uuid } from 'uuid'
import { Bounce, toast } from 'react-toastify'
import { addTutorialToChache, isStringPartOfCache, tutorialCache } from '../project-widget/widgetDataCaching'

interface TutorialContainerProps {
  tag: string
  children: JSX.Element
  updater: boolean
  // eslint-disable-next-line no-undef
  tourConfig: React.SetStateAction<StepType[]>
}
export function TutorialContainer({
  tag,
  children,
  updater,
  tourConfig
}: TutorialContainerProps) {
  const [tutorialData, setTutorialData] = useState<string[]>()
  const [tutorialDataLoaded, setTutorialDataLoaded] = useState<boolean>(false)
  const [tutorialUpdateChecked, setTutorialUpdateChecked] = useState<boolean>(true)
  const { setIsOpen, setCurrentStep, setSteps } = useTour()
  const [queryKey, setQueryKey] = useState<string>(uuid())

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!tutorialDataLoaded) {
          if (isStringPartOfCache(tag)) {
            setTutorialData(tutorialCache)
            setTutorialDataLoaded(true)
            return
          }
          await axios
            .get('/store/tutorials')
            .then((res) => {
              setTutorialData(JSON.parse(res.data.storeValue).tutorials)
              addTutorialToChache(tag)
              setTutorialDataLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('Tutorials are not set up yet.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if ((!tutorialUpdateChecked)) {
          // const queryRequest = {
          //   key: 'tutorials',
          //   eMail: email,
          //   pathToSetting: 'body.tutorials',
          //   value: tutorialData?.concat(tag)
          // }
          setTutorialUpdateChecked(true)
          await axios
            .put('/store/tutorials', { tutorials: tutorialData?.concat(tag) })
            .then(() => {
              setTutorialData(tutorialData?.concat(tag))
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('Could not update the tutorials.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    if (tutorialData) {
      const index = tutorialData.findIndex((el) => el === tag)
      if (updater && index === -1) {
        setQueryKey(uuid())
        setTutorialUpdateChecked(false)
      }
    }
  }, [updater])
  useEffect(() => {
    if (tutorialData) {
      // console.log(tutorialData)
      const index = tutorialData.findIndex((el) => el === tag)
      if (index === -1) {
        if (setSteps) setSteps(tourConfig)
        setCurrentStep(0)
        setIsOpen(true)
      }
    }
  }, [tutorialData])
  if (error) return <div>An error has occurred</div>
  return (
    <div>
      {children}
    </div>
  )
}
