import { HTMLAttributes, forwardRef } from 'react'

import { mapClasses } from 'utils'

interface ButtonIconProps extends HTMLAttributes<HTMLButtonElement> {
  roundedFull?: boolean
  color?: 'primary' | 'error'
  variant?: 'text' | 'contained'
}

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  (
    {
      roundedFull,
      color = 'primary',
      variant = 'text',
      className,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type="button"
        className={mapClasses(
          'cursor-pointer hover:bg-opacity-10 p-0.5',
          roundedFull ? 'rounded-full' : 'rounded-lg',
          // text
          variant === 'text' &&
            color === 'primary' &&
            'text-primary-text-color hover:bg-primary-color',
          variant === 'text' &&
            color === 'error' &&
            'text-red-500 hover:bg-red-500',
          // contained
          variant === 'contained' &&
            color === 'primary' &&
            'text-primary-text-color bg-primary-color bg-opacity-10',
          variant === 'contained' &&
            color === 'error' &&
            'text-red-500 bg-red-500 bg-opacity-10',
          className
        )}
        {...props}
      >
        {children}
      </button>
    )
  }
)

export default ButtonIcon
