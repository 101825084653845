import { newTFocusGroup, TFocusGroup } from 'services/costhistory.interface';

let Cache: { tag: string, data: newTFocusGroup[] | TFocusGroup[] }[] = []
export const tutorialCache: string[] = []

export function setWidgetData(
  oldTag: string,
  tag: string,
  data: newTFocusGroup[] | TFocusGroup[]
) {
  if (oldTag.length !== 0) Cache = Cache.filter((el) => el.tag !== oldTag);
  Cache.push({ tag, data });
}
export function getWidgetData(tag: string): newTFocusGroup[] | TFocusGroup[] {
  const res = Cache.find((el) => el.tag === tag);
  if (res !== null && res) return res.data
  return []
}

export function addTutorialToChache(str: string) {
  if (tutorialCache.findIndex((el) => el === str) === -1) tutorialCache.push(str)
}

export function isStringPartOfCache(str: string) {
  if (tutorialCache.findIndex((el) => el === str) === -1) return false
  return true
}
