import { SelectHTMLAttributes } from 'react'
import { BsChevronDown } from 'react-icons/bs'

import { mapClasses } from 'utils'

interface SelectProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  size?: 'sm' | 'md' | 'lg' |''
  widthStyle?: string
  options: { label: string; value: string | number }[]
}

function Select({
  size = 'lg',
  options,
  widthStyle = 'w-full',
  className,
  ...props
}: SelectProps) {
  return (
    <div
      className={mapClasses(
        'relative',
        'overflow-hidden',
        widthStyle,
        className
      )}
    >
      <select
        {...props}
        className={mapClasses(
          'relative z-5 appearance-none cursor-pointer',
          'bg-transparent border border-dark-grey focus-visible:outline-none focus:border-blue-500',
          size === 'lg' && 'h- px-3 py-1.5',
          size === 'md' && 'px-3 py-1',
          size === 'sm' && 'px-3 py-1 text-sm',
          'rounded-lg w-full'
        )}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <div className="absolute top-1/2 -translate-y-1/2 z-0 right-3 text-gray-500">
        <BsChevronDown size={14} />
      </div>
    </div>
  )
}

export default Select
