import { useMemo, useState } from 'react'
import { TiArrowUpThick, TiArrowDownThick } from 'react-icons/ti'
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot
} from 'react-beautiful-dnd'

import { mapClasses } from 'utils'
import useTableContext from './useTableContext'
import { TableContextProvider, TableContextProviderProps } from './Context'

import ToggleExtendAllButton from './ToggleExtendAllButton'
import FilterButton from './FilterButton'
import TableBodyRow from './TableBodyRow'
import { filterTableData, sortTableData } from './table.utils'
import { TTableData } from './types'

interface TableBodyProps {
  fullHeight?: boolean
  minHeight?: number
  droppableProvided?: DroppableProvided
  droppableState?: DroppableStateSnapshot
  tableData: TTableData[]
}

function TableBody({
  // fullHeight,
  // minHeight,
  droppableProvided,
  droppableState,
  tableData
}: TableBodyProps) {
  return (
    <tbody
      {...droppableProvided?.droppableProps}
      ref={droppableProvided?.innerRef}
      className={mapClasses(
        'bg-white divide-y divide-gray-300 overflow-y-auto block border border-gray-300',
        droppableState?.isDraggingOver && '!border-primary-color h-full'
      )}
      // style={{
      //   height: fullHeight ? 'calc(100% - 48px)' : undefined,
      //   // minHeight: minHeight ? minHeight - 60 : undefined
      // }}
    >
      {tableData.map((rowData) => (
        <TableBodyRow
          key={rowData._id}
          rowData={rowData}
          draggableId={rowData._id}
          draggableIndex={rowData._index}
        />
      ))}
    </tbody>
  )
}

function TableBase() {
  const {
    droppableId,
    groupChildrenField,
    minHeight,
    fullHeight,
    columns,
    data,
    formatOptions,
    filterList,
    order,
    setOrder,
    orderBy,
    setOrderBy
  } = useTableContext()

  const [tableElement, setTableElement] = useState<HTMLTableElement | null>(
    null
  )

  const filteredData = useMemo(
    () => filterTableData(data, filterList, formatOptions, groupChildrenField),
    [data, filterList, columns, formatOptions, groupChildrenField]
  )

  const tableData = useMemo(() => {
    if (!order || !orderBy) return filteredData || []

    // sort
    return sortTableData(filteredData, order, orderBy)
  }, [filteredData, order, orderBy])

  const handleOrder = (newOrderBy: string) => {
    if (!newOrderBy) return

    let newOrder = ''

    if (orderBy !== newOrderBy) newOrder = 'asc'
    else if (order === '') newOrder = 'asc'
    else if (order === 'asc') newOrder = 'desc'

    setOrder(newOrder)
    setOrderBy(newOrderBy)
  }

  return (
    <table
      ref={setTableElement}
      className={`max-w-full max-h-full ${fullHeight ? 'h-full' : ''}`}
    >
      <thead className="bg-primary-color w-full py-4">
        <tr className="[&>*:last-child]:border-primary-color">
          {columns.map((column, index) => (
            <th
              style={{ width: column.width }}
              key={`table-head-${index}`}
              className={`border-r break-words border-white text-left text-xs font-bold text-white uppercase tracking-wider default_color select-none p-0 ${
                column.filterable || column.sortable ? 'cursor-pointer' : ''
              }`}
            >
              <div className="flex items-stretch ">
                <div
                  className={`grow flex justify-between items-center gap-2 pl-2 py-4 ${
                    column.filterable ? 'pr-0' : 'pr-2'
                  }`}
                  onClick={() => column.sortable && handleOrder(column.field)}
                >
                  <span className="flex items-center">
                    {index === 0 && <ToggleExtendAllButton />}
                    <span>{column.name}</span>
                  </span>
                  {column.sortable && orderBy === column.field && (
                    <div className="flex gap-1 items-center">
                      {order === 'asc' && <TiArrowUpThick size={16} />}
                      {order === 'desc' && <TiArrowDownThick size={16} />}
                    </div>
                  )}
                </div>
                {column.filterable && (
                  <div>
                    <FilterButton tableElement={tableElement} column={column} />
                  </div>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      {!droppableId ? (
        <TableBody
          minHeight={minHeight}
          fullHeight={fullHeight}
          tableData={tableData}
        />
      ) : (
        <Droppable droppableId={droppableId}>
          {(provided, droppableState) => (
            <TableBody
              minHeight={minHeight}
              fullHeight={fullHeight}
              tableData={tableData}
              droppableProvided={provided}
              droppableState={droppableState}
            />
          )}
        </Droppable>
      )}
    </table>
  )
}

function Table({ ...props }: Omit<TableContextProviderProps, 'children'>) {
  return (
    <TableContextProvider {...props}>
      <TableBase />
    </TableContextProvider>
  )
}

export default Table
