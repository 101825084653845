import ReactDOM from 'react-dom/client'
import 'react-loading-skeleton/dist/skeleton.css'
import { TourProvider } from '@reactour/tour';
import App from './App'
import './index.scss'
import '@mantine/charts/styles.css';
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<TourProvider steps={[]}><App /></TourProvider>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
