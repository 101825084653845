import { useMemo } from 'react'
import { HiOutlineTrash, HiOutlineInformationCircle } from 'react-icons/hi'

import { TFetchStatus } from 'services/fetch.interface'
import { TComponent } from 'services/component.interface'
import {
  ButtonIcon,
  FetchStatus,
  GraphCard,
  TTableColumn,
  TTableData,
  Table
} from 'components'
import { BASIC_TABLE_COLUMNS } from '../constants'

const SELECTED_KEY = 'selected'

interface SelectedComponentTableProps {
  fetchStatus: TFetchStatus
  list: TComponent[]
  onClickInfo: (row: TTableData) => void
  onClickDelete: (index?: number) => void
  retry: () => void
}

function SelectedComponentTable({
  fetchStatus,
  list,
  onClickInfo,
  onClickDelete,
  retry
}: SelectedComponentTableProps) {
  const selectedTableColumns = useMemo<TTableColumn[]>(() => {
    return [
      ...BASIC_TABLE_COLUMNS,
      {
        name: '',
        field: '',
        width: 85,
        filterable: false,
        sortable: false,
        render: (__, { row }) => {
          return (
            <div className="flex justify-end gap-2">
              <ButtonIcon onClick={() => onClickInfo(row._rawData || {})}>
                <HiOutlineInformationCircle size={18} />
              </ButtonIcon>
              {row.type === 'external' && (
                <ButtonIcon
                  className="text-red-500"
                  onClick={() => onClickDelete(row._index)}
                >
                  <HiOutlineTrash size={18} />
                </ButtonIcon>
              )}
            </div>
          )
        }
      }
    ]
  }, [onClickDelete])

  return (
    <GraphCard isOutline={false} isLoading={fetchStatus.isLoading}>
      <div className="w-full flex items-center text-lg font-medium text-gray-800 mb-2">
        Application components
      </div>
      <div className="grow overflow-x-auto">
        <FetchStatus height="100%" status={fetchStatus} retry={retry}>
          <Table
            key={SELECTED_KEY}
            droppableId={SELECTED_KEY}
            data={list}
            columns={selectedTableColumns}
          />
        </FetchStatus>
      </div>
    </GraphCard>
  )
}

export default SelectedComponentTable
