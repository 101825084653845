import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { FaUser } from 'react-icons/fa'
import { profile } from '..';

interface showProfileProps {
    showProfileBool: boolean
    data: profile | undefined
    onClose: () => void
}

export function ShowProfile({
  showProfileBool,
  data,
  onClose
}: showProfileProps) {
  return (
    <Modal isOpen={showProfileBool} onClose={onClose} disableBackdropClick>
      <ModalHeader onClose={onClose}>
        <FaUser size={100} className="mt-1 ml-1 text-sb-button-color" />
        {`${data?.name} ${data?.lastName}`}
      </ModalHeader>
      <ModalBody className="px-5 py-1 w-[30vw]">
        <div>{`Company: ${data?.company}`}</div>
        <div>{`E-mail: ${data?.email}`}</div>
      </ModalBody>
    </Modal>
  )
}
