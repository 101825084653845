import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { TApplicationFormData } from 'services/application.interface'
import { TComponent } from 'services/component.interface'
import {
  GraphCardWrapper,
  SkeletonWrapper,
  FETCH_STATUS,
  Input,
  RegExInput,
  GraphCardGroup,
  GraphCard,
  Backdrop,
  Spinner,
  ConfirmModal
} from 'components'
import useProject from 'hooks/useProject'
import useApplication from 'hooks/useApplication'
import { mapClasses } from 'utils'
import UpdateComponents from './update-components'

const PROJECT_LABEL_KEY = 'dome.dev/project'

function ApplicationMutation() {
  const { projectId, applicationId } = useParams()
  const navigate = useNavigate()
  const { initialLoading, fetchProjects } = useProject()
  const {
    getApplicationDetail,
    getUncategorizedComponents,
    createApplication,
    updateApplication,
    deleteApplication
  } = useApplication()
  const re = '^[a-z0-9][a-z0-9.-_]*[a-z0-9]$'
  const [
    fetchUncategorizedComponentsStatus,
    setFetchUncategorizedComponentsStatus
  ] = useState(FETCH_STATUS.SUCCESS)
  const [fetchApplicationDetailStatus, setApplicationDetailStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [mutationApplicationStatus, setMutationApplicationStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [isDeleting, setDeleting] = useState(false)
  const [isValidName, setIsValidName] = useState(false)
  const [availableList, setAvailableList] = useState<TComponent[]>([])
  const [formData, setFormData] = useState<TApplicationFormData>({
    name: '',
    metadata: { annotations: { version: '', description: '' }, labels: {} },
    components: []
  })

  const isDisabled = useMemo(() => {
    return (
      !formData.metadata.labels[PROJECT_LABEL_KEY] ||
      !formData.name ||
      mutationApplicationStatus.isLoading || !isValidName
    )
  }, [formData, isValidName])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData(_.set(_.cloneDeep(formData), name, value))
  }

  const fetchUncategorizedComponents = useCallback(async () => {
    const availableComponentList = await getUncategorizedComponents(
      undefined,
      setFetchUncategorizedComponentsStatus
    )
    setAvailableList(
      availableComponentList.filter(({ type }) => type === 'external')
    )
  }, [getUncategorizedComponents])

  const fetchApplicationDetail = useCallback(async () => {
    if (!projectId || !applicationId) return
    const application = await getApplicationDetail(
      { projectId, applicationId },
      setApplicationDetailStatus
    )

    setFormData((state) => ({
      ...state,
      name: application.name || '',
      metadata: {
        annotations: {
          version: application?.metadata?.annotations?.version || '',
          description: application?.metadata?.annotations?.description || ''
        },
        labels: {
          [PROJECT_LABEL_KEY]:
            application.metadata?.labels?.[PROJECT_LABEL_KEY] || ''
        }
      },
      components: application.components || []
    }))
  }, [projectId, applicationId, getApplicationDetail])

  // ******************************************************
  // Temp fix just to delay the reloading of the page
  // TODO: Remove this once we have websockets implemented
  // ******************************************************
  const handleWait = async (delay: number) => {
    return new Promise((resolve) => {
      setTimeout(resolve, delay)
    })
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (!projectId) return

    event.preventDefault()
    setMutationApplicationStatus({ isLoading: true, isError: false })

    if (applicationId) {
      await updateApplication({ projectId, applicationId, body: formData })
    } else {
      await createApplication({ projectId, body: formData })
    }
    await handleWait(4000) // TODO: Remove this once we have websockets implemented
    await fetchProjects()

    setMutationApplicationStatus({ isLoading: false, isError: false })
    navigate(-1)
  }

  const handleDelete = async () => {
    if (!projectId || !applicationId) return

    setMutationApplicationStatus({ isLoading: true, isError: false })

    await deleteApplication({ projectId, applicationId })
    await fetchProjects()

    setMutationApplicationStatus({ isLoading: false, isError: false })
    setDeleting(false)
    navigate(`/projects/${projectId}`)
  }

  useEffect(() => {
    if (projectId) {
      setFormData((state) => {
        const newState = _.cloneDeep(state)
        newState.metadata.labels[PROJECT_LABEL_KEY] = projectId
        return newState
      })
    }
  }, [projectId])

  useEffect(() => {
    fetchUncategorizedComponents()
  }, [fetchUncategorizedComponents])

  useEffect(() => {
    if (applicationId) {
      fetchApplicationDetail()
      setIsValidName(true)
    }
  }, [applicationId, fetchApplicationDetail])

  return (
    <form className="grow flex flex-col" onSubmit={handleSubmit}>
      <GraphCardWrapper>
        <SkeletonWrapper
          isLoading={initialLoading}
          count={2}
          inline
          height={40}
          className="w-48"
          containerClassName="flex justify-between"
        >
          <div className="flex xs:flex-col desktopMini:flex-row items-start justify-between w-full">
            <p className="text-xl font-medium xs:pb-3">{projectId}</p>
            <div className="flex gap-4 xs:justify-end xs:self-end desktopMini:justify-between">
              {projectId && applicationId && (
                <button
                  type="button"
                  className="text-white rounded-md py-2.5 px-7 bg-primary-color"
                  onClick={() => setDeleting(true)}
                >
                  Delete
                </button>
              )}
              {projectId && (
                <button
                  disabled={isDisabled}
                  type="submit"
                  className={mapClasses(
                    'text-white rounded-md py-2.5 px-7',
                    isDisabled ? 'bg-gray-200' : 'bg-primary-color'
                  )}
                >
                  {applicationId ? 'Update' : 'Create'}
                </button>
              )}
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="bg-red-500 text-white rounded-md py-2.5 px-7"
              >
                Cancel
              </button>
            </div>
          </div>
        </SkeletonWrapper>

        <GraphCardGroup>
          <GraphCard
            isLoading={initialLoading || fetchApplicationDetailStatus.isLoading}
            height={164}
          >
            <div className="grid grid-cols-2 gap-x-8 gap-y-2">
              <Input
                type="text"
                name={`metadata.labels.['${PROJECT_LABEL_KEY}']`}
                label="Project*"
                value={formData.metadata.labels[PROJECT_LABEL_KEY]}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="metadata.annotations.description"
                label="Description"
                value={formData.metadata.annotations.description}
                onChange={handleChange}
              />
              <RegExInput
                type="text"
                name="name"
                label="Name*"
                pattern={re}
                errorMessage="Application name is invalid. It must consist of lower case alphanumeric characters, \'-\' or \'.\' and must start and and with and alphanumeric characters"
                value={formData.name}
                onValidated={setIsValidName}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="metadata.annotations.version"
                label="Version"
                value={formData.metadata.annotations.version}
                onChange={handleChange}
              />
            </div>
          </GraphCard>
        </GraphCardGroup>

        <GraphCardGroup grow>
          <UpdateComponents
            isLoading={initialLoading}
            fetchUncategorizedComponentsStatus={
              fetchUncategorizedComponentsStatus
            }
            fetchApplicationDetailStatus={fetchApplicationDetailStatus}
            selectedList={formData.components}
            availableList={availableList}
            onComponentsChange={(components) => {
              setFormData((state) => ({ ...state, components }))
            }}
            fetchUncategorizedComponents={fetchUncategorizedComponents}
            fetchApplicationDetail={fetchApplicationDetail}
          />
        </GraphCardGroup>
      </GraphCardWrapper>

      <Backdrop isLoading={mutationApplicationStatus.isLoading}>
        <Spinner size={40} />
      </Backdrop>

      <ConfirmModal
        isOpen={isDeleting}
        isLoading={mutationApplicationStatus.isLoading}
        title="Delete application"
        description={`Do you want to delete application "${applicationId}"?`}
        onClose={() => setDeleting(false)}
        onConfirm={handleDelete}
      />
    </form>
  )
}

export default ApplicationMutation
