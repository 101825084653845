import { effectData } from 'pages/label-rules/mutation-new-label-rule-model/hierarchyHelperFunctions';
import { TReportOperator } from 'services/report.interface';
import { FetchStatus, GraphCard, GraphCardContent, SpinnerLoader } from 'components';
import { id } from 'date-fns/locale';
import { ResponsiveContainer, BarChart, YAxis, Bar, XAxis } from 'recharts';
import { TFetchStatus } from 'services/fetch.interface';
import { TBarChartData } from 'services/chart.interface';
import { TCurrency } from 'services/cost.interface';
import { query } from '../project-widget/widgetHelpFunctions'
import { notAllowedComponent, selectedComponent, selectionComponent } from './aggregationData';

export type TagComponent = { tag: string, selected: boolean, parentConditionMet: boolean }

// ***************************************************************************
// Make sure we have no duplicates in the tags that can be chosen from when
// editing the widget.
// ***************************************************************************
export function noDupsLabelList(labelRuleDetailsList: effectData[]): string[] {
  const temp = labelRuleDetailsList.map((el) => { return el.name })
  return temp.filter((element, index) => {
    return temp.indexOf(element) === index;
  });
}

export function makeNewQueryRequest(tagSelector: string, aggregateString: string[]): query {
  let filter: {
    field?: string | undefined;
    value?: string | number | undefined;
    operator?: TReportOperator | undefined
  }[] = []
  if (tagSelector === 'labels' && aggregateString.length !== 0) {
    const firstParent = aggregateString[0]
    filter = [{
      field: `label.${firstParent}`,
      value: '',
      operator: 'CONTAINS'
    }]
  }
  let newString = ''
  if (tagSelector === 'labels' && aggregateString.length !== 0) {
    const parts = aggregateString
    let res = ''
    parts.forEach((el) => {
      if (res === '') {
        res = `label.${el}`
      } else {
        res = `${res}, label.${el}`
      }
    })
    newString = res
  } else {
    const parts = aggregateString
    let res = ''
    parts.forEach((el) => {
      const words = el.split(' ')
      let resWord = ''
      words.forEach((word) => {
        if (resWord === '') {
          const newWord = word.charAt(0).toLowerCase() + word.slice(1)
          resWord = newWord
        } else {
          const newWord = word.charAt(0).toUpperCase() + word.slice(1)
          resWord = `${resWord}${newWord}`
        }
      })
      if (res === '') {
        res = resWord
      } else {
        res = `${res}, ${resWord}`
      }
    })
    newString = res
  }
  return {
    name: 'Test query',
    description: '',
    options: {
      saveQuery: false,
      accumulate: true,
      aggregate: newString
    },
    filters: filter,
    limit: 10
  }
}
export function createResourceApiCall(
  setQueryRequest: (queryReq: query,) => void,
  provider: string,
  resourceId: string
) {
  setQueryRequest({
    name: 'Query test',
    description: 'Historical cost of a resource by id',
    filters: [
      {
        field: 'provider',
        value: provider,
        operator: 'EQUALS'
      },
      {
        field: 'resourceId',
        value: resourceId,
        operator: 'EQUALS'
      }
    ],
    options: {
      saveQuery: false,
      accumulate: false,
      window: 'lastmonth',
      step: '1d',
      aggregate: 'resourceId'
    },
    limit: 10
  })
}
export function CreateResourceChart(
  resourceDetailsProcessed: boolean,
  fetchProjectsStatus: TFetchStatus,
  fetchProjects: () => Promise<void>,
  resourceDataParsed: TBarChartData[],
  currency: TCurrency
) {
  if (resourceDataParsed.length !== 0) {
    return (
      <SpinnerLoader isLoading={!resourceDetailsProcessed}>
        <GraphCard isOutline={false}>
          <span key={`spanForTheResourceChartForTableWidget${id}`} className="text-lg font-medium text-gray-800 w-full">
            Recorded costs from last month
          </span>
          <GraphCardContent>
            <FetchStatus status={fetchProjectsStatus} retry={fetchProjects}>
              <div className="w-full h-full">
                <ResponsiveContainer>
                  <BarChart
                    data={resourceDataParsed.map((data) => {
                      const { color, value, name } = data
                      const newDate = name.split('-')[2]
                      return {
                        color,
                        value,
                        name: newDate
                      }
                    }).sort((a, b) => Number(a.name) - Number(b.name))}
                  >
                    <YAxis dataKey="value" tickFormatter={(value) => `${currency?.symbol || ''}${value}`} />
                    <Bar dataKey="value" fill="#474e44" />
                    <XAxis dataKey="name" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </FetchStatus>
          </GraphCardContent>
        </GraphCard>
      </SpinnerLoader>
    )
  }
  return <div />
}

function visualiseComponent(
  el: { tag: string, selected: boolean, parentConditionMet: boolean },
  onclick: () => void
) {
  if (el.selected) {
    return selectedComponent(el.tag, onclick)
  }
  if (!el.selected && el.parentConditionMet) {
    return selectionComponent(el.tag, onclick)
  }
  return notAllowedComponent(el.tag, onclick)
}

export function createASelectionList(
  labelRuleDetailsTagList: TagComponent[],
  tagSelector: string,
  filterValue: string,
  grouping: string[],
  setGrouping: (group: string[]) => void,
  remakeList: (arg0: string[], arg1: TagComponent[]) => void
) {
  return labelRuleDetailsTagList.filter((el) => {
    const parts = el.tag.split(' ')
    const index = parts.findIndex((part) => {
      return part.toLowerCase() === 'cost'
    })
    const filtervalueComparison = filterValue === '' ? true : el.tag.toLowerCase().includes(filterValue.toLowerCase())
    return (
      index === -1
      && filtervalueComparison
      && el.tag !== 'Updated flag'
      && el.tag !== 'Data date'
    )
  }).map((el) => {
    const onclick = () => {
      let newAggregate: string[] = []
      if (el.selected) {
        const index =
          grouping.findIndex((piece) => el.tag === piece)
        const temp1 = grouping.slice(0, index)
        const temp2 = grouping.slice(index + 1)
        newAggregate = temp1.concat(temp2)
        setGrouping(newAggregate)
      } else if (tagSelector === 'fields' || el.parentConditionMet) {
        newAggregate = grouping.concat(el.tag)
        setGrouping(newAggregate)
      } else newAggregate = grouping
      remakeList(newAggregate, labelRuleDetailsTagList)
    }
    return (
      <div
        key={`aTagWithLabel${el.tag}ForTableWidget${id}`}
        className="py-1 px-1 max-w-full"
      >
        {visualiseComponent(el, onclick)}
      </div>
    )
  })
}
