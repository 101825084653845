// import { GraphCardGroup } from 'components';
import { Popover } from '@mantine/core';
import { useState } from 'react';
import { MdAddToPhotos } from 'react-icons/md';
import { toast } from 'react-toastify';

interface ButtonWithModalProps {
  title: string,
  listOfNames: { label: string, lst: string[] }[],
  listOfCallback: { label: string, lst: (() => void)[] }[],
  ctr: number,
  maxAmount: number
}
export function ButtonWithModal({
  title,
  listOfNames,
  listOfCallback,
  ctr,
  maxAmount
}: ButtonWithModalProps) {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show)
  }

  return (
    <div>
      <div className="pl-3 flex flex-col items-center cursor-pointer">
        <Popover width={300} trapFocus position="bottom" withArrow shadow="md" opened={show} onChange={setShow}>
          <Popover.Target>
            <button
              data-tour="addWidgetButton"
              className="bg-sb-button-color absolute left-5 rounded-lg hover:bg-[#93ac89] text-white w-flex"
              style={{ height: 30, width: 30 }}
              type="button"
              title={title}
              onClick={() => {
                toggleShow()
              }}
            >
              <MdAddToPhotos style={{ marginLeft: 5 }} size={20}>
                {title}
              </MdAddToPhotos>
            </button>
          </Popover.Target>
          <Popover.Dropdown>
            {listOfNames.map(({ label, lst }) => {
              return (
                <div className="w-full">
                  <div className="w-full h-full">
                    <span className="w-full h-full py-3 text-lg font-bold text-gray-600">{label}</span>
                  </div>
                  {lst.map((name, idx) => {
                    return (
                      <div
                        className="border-b border-gray-100 hover:bg-gray-200 hover:cursor-pointer py-2 px-2"
                        onClick={() => {
                          toggleShow()
                          if (ctr < maxAmount) {
                            const theCallBack = listOfCallback.find((el) => el.label === label)
                            if (theCallBack) theCallBack.lst[idx]()
                          } else {
                            toast.error('max amount reached')
                          }
                        }}
                      >
                        {name}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </Popover.Dropdown>
        </Popover>
      </div>
      {/* <div className="relative"> */}
      {/* <div
          data-tour="addWidgetButtonPopUp"
          className={`dropdown-body w-48 absolute border-2 border-gray-200 bg-icon-color
           rounded ${show && 'dropdown-open'} top-10 left-5`}
          style={{ zIndex: 999 }}
        >
          {listOfNames.map(({ label, lst }) => {
            return (
              <div className="w-full">
                <div className="w-full h-full bg-[#c0dbb5]">
                  <span className="w-full h-full font-bold text-gray-600">{label}</span>
                </div>
                {lst.map((name, idx) => {
                  return (
                    <div
                      className="border-b border-gray-100 hover:bg-gra
                      y-200 hover:cursor-pointer py-2 px-2"
                      onClick={() => {
                        toggleShow()
                        if (ctr < maxAmount) {
                          const theCallBack = listOfCallback.find((el) => el.label === label)
                          if (theCallBack) theCallBack.lst[idx]()
                        } else {
                          toast.error('max amount reached')
                        }
                      }}
                    >
                      {name}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div> */}
      {/* </div> */}
    </div>
  )
}
