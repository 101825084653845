//--------------------------------------------------------------------------
// LRComponent.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { IoCloseOutline } from 'react-icons/io5'
import { ButtonIcon } from 'components'
import { snakeToNormalCase } from './constants'

//--------------------------------------------------------------------------
// The interface for the Label rule components in the resource explorer.
//--------------------------------------------------------------------------
interface LRComponentProps {
  columnName: string,
  value: string,
  effect?: boolean
  deleteCallback?: () => void
}
//--------------------------------------------------------------------------
// The function that returns for the Label rule components in the resource
// explorer. These actually show both rules as effect, with slight differences.
//--------------------------------------------------------------------------
export function LRComponent({ columnName, value, deleteCallback, effect }: LRComponentProps) {
  return (
    <div className="flex border border-gray-500 rounded-lg m-1">
      <div className="w-2/5 border-r border-gray-500 p-1">
        {snakeToNormalCase(columnName)}
      </div>
      <div className="p-1 bg-gray-300">
        Equal to
      </div>
      <div className="w-2/5 border-l border-gray-500 p-1">
        {value}
      </div>
      {effect ? <div /> : (
        <ButtonIcon onClick={() => { if (deleteCallback) deleteCallback() }}>
          <IoCloseOutline />
        </ButtonIcon>
      )}

    </div>
  )
}
