import { FcKey } from 'react-icons/fc'
import useAuthentication from 'hooks/useAuthentication'

// ****************************
// Sign in button for Key cloak
// ****************************
function LogInKeyCloakButton() {
  // *********
  // Constants
  // *********
  const { LogIn } = useAuthentication()

  // ***********************
  // Render the login button
  // ***********************
  return (
    <button
      className="border border-gray-300 rounded-full hover:bg-gray-50 transition-all"
      type="button"
      onClick={LogIn}
    >
      <div className="flex gap-2 justify-center items-center px-4 py-2">
        <FcKey size={24} />
        <span className="text-gray-700 text-sm font-semibold">
          Login
        </span>
      </div>
    </button>
  )
}

export default LogInKeyCloakButton
