import { ReactNode } from 'react'
import { RiCloseLine } from 'react-icons/ri'

import { mapClasses } from 'utils'
import ButtonIcon from 'components/button-icon'

interface ModalHeaderProps {
  isBorder?: boolean
  className?: string
  onClose?: () => void
  children: ReactNode
}

function ModalHeader({ isBorder, className, children, onClose }: ModalHeaderProps) {
  return (
    <div
      className={mapClasses(
        'px-5 py-5 flex justify-between items-center',
        'text-lg font-medium',
        isBorder && 'border-b border-dark-grey',
        className
      )}
    >
      <div>{children}</div>
      {onClose && (
        <ButtonIcon style={{ alignSelf: 'baseline' }} onClick={onClose}>
          <RiCloseLine size={24} />
        </ButtonIcon>
      )}
    </div>
  )
}

export default ModalHeader
