/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef } from 'react'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { ResponsiveContainer } from 'recharts'

import { TInlineChartDataSet, TInlineChartData } from 'services/chart.interface'
import { TCurrency } from 'services/cost.interface'

import { getTimstampList, formatChartDateLabel } from 'utils'

ChartJS.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Legend,
  Tooltip,
  Title
)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom' as const
    }
  },
  scales: {
    y: {
      beginAtZero: true
    }
  }
}

interface LineChartProps {
  data: Record<string, TInlineChartData[]>
  startDate?: Date | null
  endDate?: Date | null
  currency?: TCurrency
}

function LineChartComponent({
  data,
  startDate,
  endDate,
  currency
}: LineChartProps) {
  const keepRef = useRef<{ labels: string[] }>({ labels: [] })

  const chartOptions = useMemo(() => {
    if (!currency) return options

    const formatTickAndTooltip = (label: string | number) => {
      if (typeof label !== 'number') return label

      const currencySymbol = currency.symbol || ''
      if (String(label)[0] !== '-') return `${currencySymbol}${label}`
      return `-${currencySymbol}${String(label).slice(1)}`
    }

    return {
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          callbacks: {
            label: ({ raw, dataset }: { raw: any, dataset: any }) => {
              const cost = formatTickAndTooltip(raw);
              return `${dataset.label}: ${cost}`
            }
          }
        }
      },
      scales: {
        ...options.scales,
        y: { ...options.scales.y, ticks: { callback: formatTickAndTooltip } }
      }
    }
  }, [currency])

  const labelList = useMemo(() => {
    if (!startDate || !endDate) return keepRef.current.labels

    keepRef.current.labels = getTimstampList(startDate, endDate).map(
      (timestamp) => formatChartDateLabel(timestamp)
    )

    return keepRef.current.labels
  }, [startDate, endDate])

  const datasets = useMemo(() => {
    const result: TInlineChartDataSet[] = []

    Object.entries(data).forEach(([name, itemList]) => {
      if (!itemList.length) return
      const borderColor = itemList[0]?.color || ''

      const dataList = labelList.map((label) => {
        const item = itemList.find(({ date }) => date === label)
        return item?.value
      })

      result.push({
        label: name,
        type: 'line',
        data: dataList,
        fill: false,
        borderColor
      })
    })
    return result
  }, [labelList, data])

  return (
    <ResponsiveContainer>
      {datasets.length ? (
        <Chart
          type="line"
          data={{ datasets, labels: labelList }}
          options={chartOptions}
        />
      ) : (
        <p>No data for this date range</p>
      )}
    </ResponsiveContainer>
  )
}

export default LineChartComponent
