import { createContext, ReactElement, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'

import {
  TFetchConfigurationsFunction,
  TUpdateConfigurationsFunction
} from 'services/fetch.interface'
import { TConfiguration } from 'services/config.interface'
import { apiErrorHandler, axios } from 'utils'
import { FETCH_STATUS } from 'components'

interface ConfigurationContextType {
  getConfigurations: TFetchConfigurationsFunction
  updateConfigurations: TUpdateConfigurationsFunction
}

const ConfigurationContext = createContext<ConfigurationContextType>(
  {} as ConfigurationContextType
)

export function ConfigurationContextProvider({
  children
}: {
  children: ReactElement
}) {
  const getConfigurations = useCallback<TFetchConfigurationsFunction>(
    async (_, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<TConfiguration[]>('/config')
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data
      } catch (error) {
        apiErrorHandler(error)
        updateStatus?.(FETCH_STATUS.FAIL)
        return []
      }
    },
    []
  )

  const updateConfigurations = useCallback<TUpdateConfigurationsFunction>(
    async (body, updateStatus) => {
      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.put<TConfiguration[]>('/config', body)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        toast.success('Update successfully')
        return data
      } catch (error) {
        apiErrorHandler(error)
        updateStatus?.(FETCH_STATUS.FAIL)
        return []
      }
    },
    []
  )

  const value = useMemo<ConfigurationContextType>(
    () => ({ getConfigurations, updateConfigurations }),
    [getConfigurations, updateConfigurations]
  )

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  )
}

export default ConfigurationContext
