import { isNil } from 'lodash'

export type TPlacement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'left-start'
  | 'left'
  | 'left-end'

export type TComputePositionReturnValue = {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

type TComputePositionParams = {
  anchorEl: HTMLElement
  contentEl: HTMLElement
  extraSpace: number
  extraPositions?: { top?: number; left?: number }
  placement: TPlacement
}

const MIN_POSITION = 24

const computeOverScreenPosition = (
  contentEl: HTMLElement,
  positions: TComputePositionReturnValue
): TComputePositionReturnValue => {
  const { top: computedTop, left: computedLeft } = positions

  if (!contentEl.offsetWidth) return {}
  let left = computedLeft

  if (!isNil(left)) left = Math.max(left, MIN_POSITION)

  // check left position
  if (computedLeft) {
    const right = contentEl.offsetWidth + computedLeft + MIN_POSITION
    if (right >= document.body.clientWidth) {
      left = document.body.clientWidth - contentEl.offsetWidth - MIN_POSITION
    }
  }

  return { top: computedTop, left }
}

const computePositionVertical = (
  params: TComputePositionParams
): TComputePositionReturnValue => {
  const { anchorEl, contentEl, extraSpace, extraPositions, placement } = params
  const { offsetWidth: anchorWidth } = anchorEl
  const { offsetWidth: contentWidth, offsetHeight: contentHeight } = contentEl
  const anchorRect = anchorEl.getBoundingClientRect()

  let { left } = anchorRect
  let top = anchorRect.top - contentHeight - extraSpace
  if (placement.startsWith('bottom')) top = anchorRect.bottom + extraSpace

  // calculate extra positions
  if (!['top', 'bottom'].includes(placement)) left += extraPositions?.left || 0
  if (extraPositions?.top) top += extraPositions.top

  // calculate positions
  if (placement.endsWith('end')) left += anchorWidth - contentWidth
  if (['top', 'bottom'].includes(placement)) {
    left += anchorWidth / 2 - contentWidth / 2
  }

  return computeOverScreenPosition(contentEl, { top, left })
}

const computePositionHorizontal = (
  params: TComputePositionParams
): TComputePositionReturnValue => {
  const { anchorEl, contentEl, extraSpace, extraPositions, placement } = params
  const { offsetHeight: anchorHeight } = anchorEl
  const { offsetWidth: contentWidth, offsetHeight: contentHeight } = contentEl
  const anchorRect = anchorEl.getBoundingClientRect()

  let { top } = anchorRect
  let left = anchorRect.left - contentWidth - extraSpace
  if (placement.startsWith('right')) left = anchorRect.right + extraSpace

  // calculate extra positions
  if (!['left', 'right'].includes(placement)) top += extraPositions?.top || 0
  if (extraPositions?.left) left += extraPositions.left

  // calculate positions
  if (placement.endsWith('end')) top += anchorHeight - contentHeight
  if (['left', 'right'].includes(placement)) {
    top += (anchorHeight - contentHeight) / 2
  }

  return computeOverScreenPosition(contentEl, { top, left })
}

export const computePosition = (
  params: TComputePositionParams
): TComputePositionReturnValue => {
  const { placement } = params

  if (placement.startsWith('top') || placement.startsWith('bottom')) {
    return computePositionVertical(params)
  }
  if (placement.startsWith('right') || placement.startsWith('left')) {
    return computePositionHorizontal(params)
  }
  return {}
}
