import {
  ReactNode, useLayoutEffect, useRef, useState,
} from 'react'

import { mapClasses } from 'utils'
import { SPACING, MIN_HEIGHT } from 'constants/sizes.constants'

interface GraphCardContentProps {
  className?: string
  minHeight?: number
  children?: ReactNode
}

function GraphCardContent({
  className,
  minHeight = MIN_HEIGHT.GRAPH_CONTENT,
  children
}: GraphCardContentProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const containerElement = containerRef.current
    const parentElement = containerElement?.parentElement
    const previousSibling = containerElement?.previousSibling as HTMLElement
    if (!parentElement || !containerElement) return
    const { paddingTop, paddingBottom } = window.getComputedStyle(parentElement)
    const parentSpacing = parseFloat(paddingTop) + parseFloat(paddingBottom)
    // minus 2 to make sure the content's height is always less than card's height
    let newHeight = parentElement.clientHeight - parentSpacing - 2
    if (previousSibling) {
      newHeight = newHeight - SPACING.GRAPH_CARD - previousSibling.offsetHeight
    }
    setHeight(newHeight)
  }, [])

  return (
    <div
      ref={containerRef}
      className={mapClasses('grow flex', className)}
      style={{ minHeight }}
    >
      {!!height && children}
    </div>
  )
}

export default GraphCardContent
