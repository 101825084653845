import { useMemo, useState } from 'react'
import { TCurrency } from 'services/cost.interface'
import { TFetchStatus } from 'services/fetch.interface'
import { MdDeleteOutline, MdEdit, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import {
  FetchStatus,
  GraphCard,
  GraphCardContent,
  Table,
  TTableData,
  TTableColumn,
  ButtonIcon,
  ConfirmModal,
  FETCH_STATUS,
  Popover,
} from 'components'

interface GenericFilterTableCardProps<T extends TTableData> {
  initialLoading: boolean
  showActionColumn: boolean
  currency: TCurrency
  records: T[]
  fetchStatus: TFetchStatus
  columns: TTableColumn[]
  title?: string
  onEdit?: (item: T) => void
  onFetch?: () => Promise<void>
  onSelect?: (id: string) => void
  onDelete?: (item: T) => void
  onGeneric?: (item: T) => void
  selected?: string[]
}

// *************
// Table actions
// *************
interface TableActionsProps<T extends TTableData> {
  row: T
  onEdit?: (item: T) => void
  onFetch?: () => Promise<void>
  onDelete?: (item: T) => void
  onGeneric?: (item: T) => void
}

function TableActions<T extends TTableData>({
  row,
  onEdit,
  onFetch,
  onDelete,
  onGeneric,
}: TableActionsProps<T>) {
  // **************************
  // Set the delete item status
  // **************************
  const [deleteItemStatus, setDeleteItemStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [enableItemStatus, setEnableItemStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [deletingItem, setDeletingItem] = useState('')
  const [enableItem, setEnableItem] = useState('')
  const [enableTitle, setEnableTitle] = useState('')
  const [anchorElementEdit, setAnchorElementEdit] = useState<HTMLElement | null>(null)
  const [anchorElementDelete, setAnchorElementDelete] = useState<HTMLElement | null>(null)
  const [anchorElementEnable, setAnchorElementEnable] = useState<HTMLElement | null>(null)
  // **************************
  // Handle the delete function
  // **************************
  const handleDelete = async () => {
    if (onDelete) {
      setDeleteItemStatus(FETCH_STATUS.START) // Set the status to start
      await onDelete(row) // call the delete function
      setDeleteItemStatus(FETCH_STATUS.SUCCESS) // Set the status to success
    }
    setDeletingItem('') // clear the deleting item
    if (onFetch) onFetch() // Make it refetch the data
  }

  // *******************************************
  // Handle action that can be used for anything
  // *******************************************
  const handleGeneric = async () => {
    if (onGeneric) {
      setEnableItemStatus(FETCH_STATUS.START) // Set the status to start
      await onGeneric(row) // perform the generic action
      setEnableItemStatus(FETCH_STATUS.START) // Set the status to start
      if (onFetch) onFetch() // Make it refetch the data
      setEnableItem('') // clear the deleting item
      setEnableTitle('')
    }
  }

  // **************************
  // Handle editing of the item
  // **************************
  const handleEdit = async () => {
    if (onEdit) onEdit(row) // perform the generic action
  }

  return (
    <div
      className="flex justify-center gap-2"
      onClick={(event) => event.stopPropagation()}
    >
      <ButtonIcon ref={setAnchorElementEdit} roundedFull className="p-1" onClick={() => handleEdit()}>
        <MdEdit size={20} />
      </ButtonIcon>
      <Popover mode="hover" placement="top-end" anchorElement={anchorElementEdit}>
        <span>`Edit {row.name}`</span>
      </Popover>
      <ButtonIcon
        roundedFull
        ref={setAnchorElementDelete}
        color="error"
        className="p-1"
        onClick={() => setDeletingItem(row.name)}
      >
        <MdDeleteOutline size={20} />
      </ButtonIcon>
      <Popover mode="hover" placement="top-end" anchorElement={anchorElementDelete}>
        <span>`Delete {row.name}`</span>
      </Popover>
      {onGeneric && (
        <div>
          <ButtonIcon ref={setAnchorElementEnable} roundedFull className="p-1" onClick={() => { setEnableItem(row.name); setEnableTitle((row.enabled) ? 'Disable' : 'Enable'); }}>
            {(!row.enabled) ?
              <MdOutlineCheckBoxOutlineBlank size={20} /> : <MdOutlineCheckBox size={20} />}
          </ButtonIcon>
          <Popover mode="hover" placement="top-end" anchorElement={anchorElementEnable}>
            {(row.enabled) ?
              <span>`Disable {row.name}`</span> : <span>`Enabled {row.name}`</span>}
          </Popover>
        </div>
      )}
      <ConfirmModal
        isOpen={!!deletingItem}
        isLoading={deleteItemStatus.isLoading}
        title="Delete label rule"
        description={`Do you want to delete label rule "${deletingItem}"?`}
        onClose={() => setDeletingItem('')}
        onConfirm={handleDelete}
      />

      <ConfirmModal
        isOpen={!!enableItem}
        isLoading={enableItemStatus.isLoading}
        title="Enable/Disable label rule"
        description={`Do you want to "${enableTitle}" label rule "${enableItem}"?`}
        onClose={() => setEnableItem('')}
        onConfirm={handleGeneric}
      />
    </div>
  )
}

// **************************
// GenericTableCard component
// **************************
function GenericTableCard<T extends TTableData>({
  showActionColumn,
  initialLoading,
  currency,
  records,
  columns,
  fetchStatus,
  title,
  onFetch,
  onSelect,
  onEdit,
  onDelete,
  onGeneric,
  selected,
}: GenericFilterTableCardProps<T>) {
  const formatOptions = useMemo(() => ({ currency }), [currency])

  const actionColumn = useMemo<TTableColumn>(
    () => ({
      name: 'Actions',
      field: '',
      sortable: false,
      filterable: false,
      width: ((onGeneric) ? '150px' : '100px'),
      render: (_, { row }) => (
        <TableActions<T>
          row={row as T}
          onEdit={onEdit}
          onFetch={onFetch}
          onDelete={onDelete}
          onGeneric={onGeneric}
        />
      )
    }),
    []
  )

  // ********************************
  // See if to show the action column
  // ********************************
  const columnsWithActions = [...columns]
  if (showActionColumn) columnsWithActions.push(actionColumn)

  return (
    <GraphCard isOutline={false} isLoading={initialLoading}>
      {title && (
        <div className="w-full flex items-center text-lg font-medium text-gray-800">
          {!!title && (
            <span>{title}</span>
          )}
        </div>
      )}
      <GraphCardContent className="overflow-x-auto">
        <FetchStatus status={fetchStatus} retry={onFetch}>
          <Table
            columns={columnsWithActions}
            data={records}
            formatOptions={formatOptions}
            onRowClick={(row) => { if (onSelect) onSelect(row.id) }}
            selectedRowIdList={selected}
          />
        </FetchStatus>
      </GraphCardContent>
    </GraphCard>
  )
}

export default GenericTableCard
