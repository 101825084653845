import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { axios } from 'utils/axios'
import { TFocusGroup } from 'services/costhistory.interface'
import { useQuery } from 'react-query'
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { FaChartLine } from 'react-icons/fa'
import { query } from '../project-widget/widgetHelpFunctions'

export interface TopServiceBySpendProps {
  id: string,
  key: string
}
export function TopServiceBySpend({ id, key }: TopServiceBySpendProps) {
  const {
    fetchProjectsStatus,
  } = useProject()
  const [data, setData] = useState<TFocusGroup[]>([])
  const [dataProcessed, setDataProcessed] =
    useState<boolean>(false)
  const [query, setQuery] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'serviceName',
        step: '1m',
        window: 'lastmonth'
      },
      filters: [
      ],
      limit: 10
    }
  )
  const changeQueryId = () => {
    setQuery(
      {
        name: uuid(),
        description: '',
        options: {
          saveQuery: false,
          accumulate: true,
          aggregate: 'serviceName',
          step: '1m',
          window: 'lastmonth'
        },
        filters: [
        ],
        limit: 10
      }
    )
  }
  const { error } = useQuery({
    queryKey: [`query?${JSON.stringify(query)}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .post('/query', query)
            .then((res) => {
              setData(res.data.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setData([])
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  function getPercentages(): { label: string, percentage: number }[] {
    if (data.length !== 0) {
      // const dataToWorkWith = data
      let totalCost = 0
      const results: { label: string, monthlyCost: number }[] = []
      data.forEach((el) => {
        const name = Object.getOwnPropertyNames(el)[0]
        const { totalEffectiveCost } = el[name]
        const cost = Number(totalEffectiveCost)
        // const splitted = name.split('-')
        const newName = name === '' ? 'Unknown' : name
        const index = results.findIndex((el) => el.label === newName)
        totalCost += cost
        if (index !== -1) {
          results[index].monthlyCost += cost
        } else {
          results.push({ label: newName, monthlyCost: cost })
        }
      })
      const precentages: { label: string, percentage: number }[] = results.map((el) => {
        return { label: el.label, percentage: ((el.monthlyCost / totalCost) * 100) }
      })
      return precentages
    }
    return [{ label: '', percentage: 0 }]
  }

  function getTopServices() {
    if (data.length !== 0) {
      const percentages = getPercentages()
      const res = percentages
        .sort((a, b) => b.percentage - a.percentage)
        .slice(0, 3)
        .map((el) => el.label)
      return res
    } return []
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div id={id} key={key} className="p-2 w-56 h-full">
      <div key={key} className="h-full">
        <FetchStatus status={fetchProjectsStatus} retry={changeQueryId}>
          <div className="h-full">
            <div className="h-3/4">
              <span className="font-semibold text-gray-800 text-lg">
                {
                  getTopServices().map((el, idx) => {
                    return <div><span>#{idx + 1} {el}</span></div>
                  })
                }
              </span>
              <span className="text-gray-600 text-xs">Top services by spend</span>
            </div>
            <div className="flex justify-end">
              <FaChartLine color="#668957" size={40} />
            </div>
          </div>
        </FetchStatus>
      </div>
    </div>
  )
}
