/* eslint-disable @typescript-eslint/no-explicit-any */
import { Bounce, toast } from 'react-toastify'

export const apiErrorHandler = (error: any) => {
  let message = (error?.message as string) || ''
  if (error?.response?.data?.message) {
    message = error.response.data.message
  }
  const status = error?.response?.status

  if ([401, 403].includes(status)) {
    toast.error('Unauthorized', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
    })
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    if (window.location.pathname !== '/login') {
      window.location.href = '/login'
    }
  } else {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
    })
  }

  return { error: message }
}
