import { useContext } from 'react'

import LabelRuleContext from 'context/LabelRuleContext'

function useLabelRules() {
  const labelRules = useContext(LabelRuleContext)
  return labelRules
}

export default useLabelRules
