import { ReactNode } from 'react'

import { mapClasses } from 'utils'

interface ModalBodyProps {
  className?: string
  children: ReactNode
}

function ModalBody({ className, children }: ModalBodyProps) {
  return <div className={mapClasses('p-5 pt-0 max-h-[calc(100vh-64px)] overflow-y-auto', className)}>{children}</div>
}

export default ModalBody
