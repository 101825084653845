import { Navigate } from 'react-router-dom'

import useAuthentication from 'hooks/useAuthentication'
import SignupForm from './SignupForm'

function Signup() {
  const { isLoading, isAuthenticated } = useAuthentication()

  if (!isLoading && isAuthenticated) {
    return <Navigate to="/" />
  }

  return <SignupForm />
}

export default Signup
