/* eslint-disable max-len */
import { ChangeEvent, FormEvent, useState, useMemo, useEffect } from 'react'

import {
  TReport,
  TReportFilter,
  TReportBodyData,
  TReportFilterDisplay
} from 'services/report.interface'
// import { REPORT_FILTER_TYPE } from 'constants/report.constants'
import {
  FETCH_STATUS,
  Backdrop,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'components'
import useReport from 'hooks/useReport'
import RegexInput from 'components/regex-input'
import { ReportEditor } from '../ReportEditor'

export const re = '^[a-z0-9][a-z0-9.-]*[a-z0-9]$'
export const reTest = /^[a-z0-9][a-z0-9.-]*[a-z0-9]$/

interface MutationNewReportModalProps {
  isOpen: boolean
  data?: TReportFilterDisplay
  onMutated: (reportFilter: TReportFilter, reports: TReport[]) => void
  onClose: () => void
}

function MutationNewReportModal({
  isOpen,
  data,
  onMutated,
  onClose
}: MutationNewReportModalProps) {
  // const { currency } = useAuthentication()
  const { createReportFilter } = useReport()

  // ************
  // Setup states
  // ************
  const [createReportFilterStatus, setCreateReportFilterStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [reportDisplayData, setReportDisplayData] = useState<
    TReportFilterDisplay | undefined
  >() // the data that brought in from the grid
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [payload, setPayload] = useState<TReportBodyData>() // store the payload that is used to save to api
  const [reportName, setReportName] = useState<string>('') // Used to trigger query of filters
  const [reportDescription, setReportDescription] = useState<string>('') // Used to trigger query of filters
  const [reportId, setReportId] = useState<string>('') // Used to trigger query of filters
  const [filterButton, setFilterButton] = useState<JSX.Element>(<div />)

  // *********************************************
  // Function to handle when the report is changed
  // *********************************************
  const handleOnChanged = (payload: TReportBodyData) => {
    // *******************
    // Save the Report payload
    // *******************
    setPayload(payload)
  }

  // ***************************************
  // Used to save state if the form is valid
  // ***************************************
  const isValidForm = useMemo(() => {
    if (!reportName) return false // check if has report name
    // if (payload?.chart?.filter === '' || undefined) return false // check if has chart filter
    let isValid = true
    let hasCondition = false
    // *******************************************************
    // Check if we have any partially completed report filters
    // *******************************************************

    if (payload?.filters && payload?.filters.length > 0) {
      payload?.filters?.forEach((filter) => {
        hasCondition = true
        if (!filter.field || !filter.operator || !filter.value) {
          isValid = false
        }
      })
    } else {
      isValid = true
      hasCondition = true
    }
    return isValid && hasCondition
  }, [reportName, payload])

  // ***********************************
  // Handle name and description changes
  // ***********************************
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'name') {
      setReportName(value)
      if (reportDisplayData) reportDisplayData.name = value
    } else if (name === 'description') {
      setReportDescription(value)
      if (reportDisplayData) reportDisplayData.description = value
    }
  }

  // ******************
  // Handle form submit
  // ******************
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!payload) return
    // ************************
    // Post the data to the API
    // ************************
    console.log(payload)
    createReportFilter(payload, setCreateReportFilterStatus).then((reports) => {
      const { name, description } = payload
      onMutated({ id: reportId, name, description, filters: payload.filters }, reports)
      onClose()
    })
  }

  // **********************************************
  // Effect to setup the states when data is passed
  // **********************************************
  useEffect(() => {
    if (!data) return
    if (data.name === '') setIsCreate(true)
    setReportName(data.name)
    setReportDescription(data.description || '')
    setReportDisplayData(data)
    setReportId(data.id)
  }, [data])

  return (
    <Modal isOpen={isOpen} onClose={onClose} disableBackdropClick>
      <ModalHeader onClose={onClose}>
        {isCreate ? 'Create' : 'Update'} new report
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody className="px-0 py-0 w-[80vw]">
          <div className="flex gap-6 px-5 pb-4 border-b">
            <RegexInput
              type="text"
              name="name"
              label="Name"
              value={reportName}
              onChange={handleChange}
              pattern={re}
              errorMessage="LabelRule name is invalid. It must consist of lower case alphanumeric characters, \'-\' or \'.\' and must start and and with and alphanumeric characters"
            />
            <Input
              type="text"
              name="description"
              label="Description"
              value={reportDescription}
              onChange={handleChange}
            />
          </div>
          <ReportEditor
            reportParam={reportDisplayData}
            onChanged={handleOnChanged}
            setFilterButtonCallback={setFilterButton}
          />
        </ModalBody>
        <ModalFooter className="gap-4 bg-gray-100 px-5 py-3 flex w-full">
          <div className="w-1/2 flex justify-start">
            {filterButton}
          </div>
          <div className="flex justify-end w-1/2">
            <button
              type="button"
              className="rounded-lg hover:bg-red-500 hover:bg-opacity-10 py-1 px-4 text-red-500"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!isValidForm || !reTest.test(reportName)}
              className="bg-primary-color hover:bg-[#93ac89] disabled:bg-gray-300 text-white rounded-md py-1 px-4"
            >
              {isCreate ? 'Create' : 'Update'}
            </button>
          </div>
        </ModalFooter>
      </form>

      <Backdrop isLoading={createReportFilterStatus.isLoading}>
        <Spinner size={40} />
      </Backdrop>
    </Modal>
  )
}

export default MutationNewReportModal
