import { ReactNode } from 'react'

import { mapClasses } from 'utils'
import { SPACING } from 'constants/sizes.constants'

interface GraphCardWrapperProps {
  noPadding?: boolean
  smPadding?: boolean
  className?: string
  spacing?: number
  children?: ReactNode
}

function GraphCardWrapper({
  noPadding,
  smPadding,
  className,
  spacing = SPACING.GRAPH_WRAPPER,
  children
}: GraphCardWrapperProps) {
  return (
    <div
      className={mapClasses(
        'main flex flex-col',
        (!noPadding || smPadding) && 'xs:p-4 tablet:p-10',
        smPadding && 'xs:p-5 tablet:p-5',
        className
      )}
      style={{ gap: spacing }}
    >
      {children}
    </div>
  )
}

export default GraphCardWrapper
