//--------------------------------------------------------------------------
// ReportEditor.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { useEffect, useState } from 'react'
import { AreaChartSeries } from '@mantine/charts'
import { useElementSize } from '@mantine/hooks'
import '@mantine/dates/styles.css'
import { useQuery } from 'react-query'
import { toast, Bounce } from 'react-toastify'
import { v4 as uuid } from 'uuid'
import { TQueryRequest } from 'services/commons.interface'
import { TFocusGroup, TCostHistoryQueryResponse, stepPeriodOptions, focusFieldOptions, TPlotSeries } from 'services/costhistory.interface'
import { TReportBodyData, TReportCondition, TReportFilterDisplay } from 'services/report.interface'
import { axios } from 'utils/axios'
import { BarLineChart, SpinnerLoader } from 'components'
import { FaChartArea, FaChartLine, FaPlus } from 'react-icons/fa6'
import { FaChartBar } from 'react-icons/fa'
import { convertReportFilterDisplayToQuery, convertTFocusGroupToBarChartSeries, convertTFocusGroupToBarChartSeriesKey } from 'utils/dataConverter'
import { dateFormatChanger } from 'features/projects/project-labeled-data-widget/chartHelperFunctions'
import { DateRangePicker } from 'features/projects/project-datepicker'
import { snakeToNormalCase } from 'pages/resource-explorer/constants'
import { ResourceFilterElement } from './ResourceFilterElement'
//--------------------------------------------------------------------------
// Interface for the report editor.
//--------------------------------------------------------------------------
interface ReportEditorProps {
  reportParam?: TReportFilterDisplay
  onChanged?: (payload: TReportBodyData) => void
  setFilterButtonCallback: (theButton: JSX.Element) => void
}

//--------------------------------------------------------------------------
// Type for the chart data, the data actually is the TFocusGroup
// but to put the timestamp in the correct format it would need to be a string
// instead.
//--------------------------------------------------------------------------

export type jsonData = {
  [index: string]: any
}
//--------------------------------------------------------------------------
// Constant of the pattern that needs to be followed by the input form.
//--------------------------------------------------------------------------
const re = '^[0-9]*$'

//--------------------------------------------------------------------------
// The function that creates to report editor object.
//--------------------------------------------------------------------------
export function ReportEditor(
  { reportParam, onChanged, setFilterButtonCallback }: ReportEditorProps
) {
  //--------------------------------------------------------------------------
  // This is a reference callback for one of the divs that allows to size the
  // chart to the same height.
  // check out: https://mantine.dev/hooks/use-element-size/
  //--------------------------------------------------------------------------
  const {
    ref,
    height
  } = useElementSize();

  //--------------------------------------------------------------------------
  // All states used in the ReportEditor.
  //--------------------------------------------------------------------------
  // const [startDate, setStartDate] = useState<Date>(new Date())
  // const [endDate, setEndDate] = useState<Date>(new Date())
  // const [window, setWindow] = useState<boolean>(true)

  const [window, setWindow] = useState<[Date | null, Date | null]>([new Date(), new Date()]);

  const [stepDuration, setStepDuration] = useState<string>('1')
  const [stepUnit, setStepUnit] = useState<string>('d')
  const [focusField, setFocusField] = useState<string>('totalBilledCost')
  const [focusValue, setFocusValue] = useState<string>('resourceId')
  const [chartType, setChartType] = useState<'line' | 'bar' | 'area' | undefined>('line')

  const [seriesData, setSeriesData] = useState<TPlotSeries[] | object[]>([])
  const [seriesDataKey, setSeriesDataKey] = useState<AreaChartSeries[]>([])

  const [focusData, setFocusData] = useState<TFocusGroup[]>([])
  const [filters, setFilters] = useState<{
    field: string,
    operator: string,
    value: string | number
  }[]>([])
  const [dataProcessed, setDataProcessed] = useState<boolean>(true)
  const [queryKey, setQueryKey] = useState<string>('')
  const [queryRequest, setQueryRequest] = useState<TQueryRequest>()

  const [labels, setLabels] = useState<string[]>([])
  const [labelsLoaded, setLabelsLoaded] = useState<boolean>(false)
  const [fields, setFields] = useState<string[]>([])
  const [fieldsLoaded, setFieldsLoaded] = useState<boolean>(false)

  //--------------------------------------------------------------------------
  // These following functions will allow the system to parse the start and
  // end-date from the date, giving the current date if the object does not
  // exist.
  //--------------------------------------------------------------------------

  function getStartDate() {
    if (reportParam) {
      if (reportParam.chart?.window) {
        const split = reportParam.chart?.window.split(',')
        if (split) {
          if (split[0]) {
            return new Date(split[0])
          }
          return new Date()
        }
        return new Date()
      }
      return new Date()
    }
    return new Date()
  }

  function getEndDate() {
    if (reportParam) {
      if (reportParam.chart?.window) {
        const split = reportParam.chart?.window.split(',')
        if (split) {
          if (split[1]) {
            return new Date(split[1])
          }
          return new Date()
        }
        return new Date()
      }
      return new Date()
    }
    return new Date()
  }

  //--------------------------------------------------------------------------
  // If you change the date using the datepicker this will be called,
  // puts it in the correct format and then updates the actual data.
  //--------------------------------------------------------------------------
  // const onDateChange = (
  //   dates: { startDate?: Date; endDate?: Date; key?: string }[]
  // ) => {
  //   if (reportParam) {
  //     if (reportParam.chart) {
  //       reportParam.chart.window =
  //         `${dates[0].startDate?.toISOString().replaceAll('.000Z', 'Z')},${dates[0].endDate
  //           ?.toISOString()
  //           .replaceAll('.000Z', 'Z')}`
  //       setWindow(!window)
  //     }
  //   }
  // }

  //--------------------------------------------------------------------------
  // If the data has been loaded in you can start to set the aforementioned
  // states to the data to be shown.
  //--------------------------------------------------------------------------
  useEffect(() => {
    if (!reportParam) return
    if (reportParam.name.length === 0) {
      reportParam.chart = {
        window: `${window[0]?.toISOString().replaceAll('.000Z', 'Z')},${window[1]?.toISOString().replaceAll('.000Z', 'Z')}`,
        step: '1d',
        accumulate: false,
        aggregate: 'resourceId',
        chartType: 'line',
        chartValue: 'totalBilledCost'
      }
      if (onChanged) onChanged(reportParam as TReportBodyData)
      return
    }
    if (dataProcessed) {
      setWindow([getStartDate(), getEndDate()])
    }
    const el = reportParam!.chart!.step
    const idx = el.length - 1
    if (reportParam.chart) {
      setStepDuration(reportParam.chart.step.substring(0, idx))
      setStepUnit(reportParam.chart.step.substring(idx, idx + 1))
      setFocusField(reportParam.chart?.chartValue)
      setFocusValue(reportParam.chart?.aggregate)
    }
    setDataProcessed(false)
    setQueryRequest(convertReportFilterDisplayToQuery(reportParam))
    setQueryKey(uuid())
    if (reportParam.filters) setFilters(reportParam.filters)
  }, [reportParam])

  //--------------------------------------------------------------------------
  // This effect is called whenever the window, filters, stepDuration or stepUnit
  // is updated, it will update the payload for when the user clicks update.
  //--------------------------------------------------------------------------
  useEffect(() => {
    if (onChanged && reportParam) {
      onChanged(
        {
          name: reportParam.name,
          description: reportParam.description as string,
          filters: filters as TReportCondition[],
          chart: {
            window: `${window[0]?.toISOString().replaceAll('.000Z', 'Z')},${window[1]?.toISOString().replaceAll('.000Z', 'Z')}`,
            step: `${stepDuration}${stepUnit}`,
            accumulate: false,
            aggregate: focusValue,
            chartType: chartType as 'line' | 'area' | 'bar',
            chartValue: focusField,
          }
        }
      )
    }
  }, [window, filters, stepDuration, stepUnit, focusValue, focusField])

  //--------------------------------------------------------------------------
  // This is the query used to fetch the chart data, labels and fields.
  //--------------------------------------------------------------------------
  useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!dataProcessed && queryRequest) {
          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setFocusData(res.data.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              setDataProcessed(true)
              toast.error(`Error code ${error.status}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
              // throw new Error(`Error code ${error.status}`)
            })
        }
        if (!labelsLoaded) {
          await axios
            .get('/labels')
            .then((res) => {
              setLabels(res.data)
              setLabelsLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels are created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (!fieldsLoaded) {
          await axios
            .get('/fields')
            .then((res) => {
              setFields(res.data)
              setFieldsLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels are created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  //--------------------------------------------------------------------------
  // When the chart data is loaded we want it to be in the correct format.
  // This will create x labels and y-values(for each different cloud instance).
  //--------------------------------------------------------------------------
  useEffect(() => {
    const barSeries: jsonData[] = convertTFocusGroupToBarChartSeries(
      focusData,
      'timestamp',
      focusField
    )
    const barSeriesKey = convertTFocusGroupToBarChartSeriesKey(
      focusData
    )
    setSeriesData(barSeries.map((el) => {
      if (el.date) el.date = dateFormatChanger(el.date)
      const names = Object.getOwnPropertyNames(el).filter((name) => name !== 'date')
      names.forEach((name) => {
        if (Number(el[name])) {
          el[name] = Number(Number(el[name]).toFixed(2))
        }
      })
      return el
    }))
    setSeriesDataKey(barSeriesKey)
  }, [focusData])

  //--------------------------------------------------------------------------
  // Splits the modal in two halfs:
  // - One to change the filters, window, steps and value to be shown.
  // - One to show the chart.
  // It is for this that the reference defined above will be used.
  //--------------------------------------------------------------------------
  const filterCallBack = () => {
    if (reportParam) {
      if (reportParam.name !== '') {
        setQueryRequest(convertReportFilterDisplayToQuery(
          {
            name: reportParam.name,
            description: reportParam.description as string,
            filters: filters as TReportCondition[],
            chart: {
              window: `${window[0]?.toISOString().replaceAll('.000Z', 'Z')},${window[1]?.toISOString().replaceAll('.000Z', 'Z')}`,
              step: `${stepDuration}${stepUnit}`,
              accumulate: false,
              aggregate: focusValue,
              chartType: chartType as 'line' | 'area' | 'bar',
              chartValue: focusField,
            }
          } as TReportFilterDisplay
        ))
        setDataProcessed(false)
        setQueryKey(uuid())
      }
    }
  }
  useEffect(() => {
    setFilterButtonCallback(
      <button
        type="button"
        className="bg-[#668957] text-white rounded-lg p-1 w-full hover:bg-[#93ac89] hover:border-[#93ac89] border-2 border-[#668957]"
        onClick={filterCallBack}
      >
        Filter
      </button>
    )
  }, [setFilterButtonCallback])

  return (
    <div className="flex w-full">
      <SpinnerLoader isLoading={!dataProcessed}>
        <div ref={ref} className="w-1/2 p-2 max-h-[505px]">
          <div className="w-full">
            <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl">
              <div className="flex w-full border-b border-gray-500 rounded-t-lg p-2 bg-gray-300 w-20">
                <span className="w-1/2"> Resource filters:</span>
                <div className="flex justify-end w-1/2">
                  <button
                    type="button"
                    className="cursor-pointer hover:bg-opacity-10 p-0.5 rounded-lg"
                    onClick={() => {
                      const newFilters = filters.concat([{
                        field: '',
                        operator: 'EQUALS',
                        value: ''
                      }])
                      setFilters(newFilters)
                    }}
                  >
                    <FaPlus />
                  </button>
                </div>
              </div>
              <div className="h-[125px] overflow-auto">
                {labelsLoaded && fieldsLoaded && (
                  <div className="p-2">
                    {filters.map((filter, idx) => {
                      if (!filter.field.startsWith('label.')) {
                        return (
                          <div className="p-2">
                            <ResourceFilterElement
                              filter={filter}
                              idx={idx}
                              filters={filters}
                              setFilters={setFilters}
                              options={fields}
                            />
                          </div>
                        )
                      }
                      return <div />
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl mt-2">
              <div className="flex w-full border-b border-gray-500 rounded-t-lg p-2 bg-gray-300 w-20">
                <span className="w-1/2">Label filters:</span>
                <div className="flex justify-end w-1/2">
                  <button
                    type="button"
                    className="cursor-pointer hover:bg-opacity-10 p-0.5 rounded-lg"
                    onClick={() => {
                      const newFilters = filters.concat([{
                        field: 'label.',
                        operator: 'EQUALS',
                        value: ''
                      }])
                      setFilters(newFilters)
                    }}
                  >
                    <FaPlus />
                  </button>
                </div>
              </div>
              <div className="h-[125px] overflow-auto">
                {labelsLoaded && fieldsLoaded && (
                  <div className="p-2">
                    {filters.map((filter, idx) => {
                      if (filter.field.startsWith('label.')) {
                        const filterRepr = {
                          field: filter.field.split('.')[1],
                          operator: filter.operator,
                          value: filter.value,
                        }
                        return (
                          <div className="p-2">
                            <ResourceFilterElement
                              filter={filterRepr}
                              idx={idx}
                              filters={filters}
                              setFilters={setFilters}
                              options={labels}
                              label
                            />
                          </div>
                        )
                      }
                      return <div />
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl mt-2">
              <div className="rounded-b-lg">
                <div className="flex w-full border-b border-gray-500 rounded-t-lg p-2 bg-gray-300 w-20">
                  <span className="w-1/2">
                    Options:
                  </span>
                </div>
                <div className="flex">
                  <span className="w-1/2 bg-gray-200 border-b border-r border-gray-500 px-1">
                    Window:
                  </span>
                  <div className="border-b w-full border-gray-500 py-2">
                    <div className="flex justify-center">
                      <DateRangePicker window={window} setWindow={setWindow} />
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <span className="w-1/2 bg-gray-200 border-b border-r border-gray-500 px-1">
                    step:
                  </span>
                  <div className="flex w-full border-b w-full border-gray-500">
                    <div className="flex flex-col w-1/2">
                      <input
                        type="text"
                        className="p-1 text-gray-500 border-r border-gray-500"
                        pattern={re}
                        onChange={(event) => {
                          if (reportParam?.chart) {
                            setStepDuration(event.target.value)
                          }
                        }}
                        value={stepDuration}
                      />
                    </div>
                    <select
                      name="stepUnit"
                      className="h-full w-full bg-white text-center bottom-0"
                      value={stepUnit}
                      onChange={(event) => setStepUnit(event.target.value)}
                    >
                      {stepPeriodOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex border-b w-full border-gray-500">
                  <span className="w-1/2 bg-gray-200 border-r border-gray-500 rounded-bl-md p-1">
                    Chart field:
                  </span>
                  <div className="flex w-full py-2">
                    <select
                      name="chartField"
                      id="chartField"
                      className="h-full w-full bg-white text-center bottom-0 rounded-b-lg"
                      value={focusField}
                      onChange={(event) => setFocusField(event.target.value)}
                    >
                      {focusFieldOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex">
                  <span className="w-1/2 bg-gray-200 border-r border-gray-500 rounded-bl-md p-1">
                    Chart value:
                  </span>
                  <div className="flex w-full py-2">
                    <select
                      name="chartValue"
                      id="chartValue"
                      className="h-full w-full bg-white text-center bottom-0 rounded-b-lg"
                      value={focusValue}
                      onChange={(event) => setFocusValue(event.target.value)}
                    >
                      {fields.map((option) => {
                        const normalCase = snakeToNormalCase(option)
                        // `${normalCase.slice(0, 1)[0].toLocaleUpperCase()}
                        // ${normalCase.slice(1, normalCase.length)}`
                        const splitted = normalCase.split(' ')
                        let keyValue = ''
                        splitted.forEach((split) => {
                          if (keyValue === '') {
                            keyValue = split
                          } else keyValue = `${keyValue}${`${split.slice(0, 1)[0].toLocaleUpperCase()}${split.slice(1, split.length)}`}`
                        })
                        return (
                          <option key={option} value={keyValue}>
                            {`${normalCase.slice(0, 1)[0].toLocaleUpperCase()}${normalCase.slice(1, normalCase.length)}`}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full pr-2">
          <div className="flex flex-col w-full border border-gray-500 rounded-lg bg-white shadow-2xl mt-2">
            <div className="flex w-full border-b border-gray-500 rounded-t-lg p-1 bg-gray-300 w-20">
              <span className="w-1/2">
                Chart:
              </span>
              <span className="flex justify-end w-1/2">
                <div className="align-center">
                  <button
                    type="button"
                    className="border border-gray-500 p-1 rounded-lg bg-white"
                    onClick={() => {
                      setChartType('bar')
                    }}
                  >
                    <FaChartBar size={20} />
                  </button>
                  <button
                    type="button"
                    className="border border-gray-500 p-1 mx-1 rounded-lg bg-white"
                    onClick={() => {
                      setChartType('line')
                    }}
                  >
                    <FaChartLine size={20} />
                  </button>
                  <button
                    type="button"
                    className="border border-gray-500 p-1 rounded-lg bg-white"
                    onClick={() => {
                      setChartType('area')
                    }}
                  >
                    <FaChartArea size={20} />
                  </button>
                </div>
              </span>
            </div>

            <div className="rounded-b-lg p-2 pb-2">
              <BarLineChart
                height={height - 2}
                data={seriesData}
                dataSeries={seriesDataKey}
                chartType={chartType}
                currency={{
                  value: 'DOLLAR',
                  symbol: '$'
                }}
              />
            </div>
          </div>
        </div>
      </SpinnerLoader>
      {/* <DateRangePickerComponent
        startDate={startDate}
        endDate={endDate}
        handleChange={onDateChange}
      /> */}
    </div>
  )
}
