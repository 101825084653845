import { BarChart } from '@mantine/charts';
// import { dataNameAndColor } from 'features/projects/project-labeled-data-card';
import { dataNameAndColor, projectAndTheirApps } from 'features/projects/project-widget/widgetHelpFunctions';
import { TCurrency } from 'services/cost.interface';
import { makeTooltip } from './helper';

interface LabeledDataProps {
  data: projectAndTheirApps[],
  series: dataNameAndColor[],
  height: number,
  width: number,
  currency: TCurrency

}

function LabeledDataComponent({
  data,
  series,
  height,
  width,
  currency
}: LabeledDataProps) {
  return (
    <div className="w-full h-full">
      <BarChart
        h={height}
        w={width}
        data={data}
        dataKey="label"
        type="stacked"
        unit={currency.symbol}
        series={series}
        style={{ transitionDuration: '0ms' }}
        tooltipProps={{
          content: ({ label, payload }) => makeTooltip(label, payload, currency),
        }}
        withLegend
        legendProps={{ verticalAlign: 'bottom', height: 50, layout: 'horizontal', align: 'left' }}
      />
    </div>
  )
}

export default LabeledDataComponent
