// import { effectData } from './mutation-new-label-rule-model/hierarchyHelperFunctions'
import { v4 as uuid } from 'uuid'

export type effectType = {
  key: string,
  value: string,
  children: effectType[]
}
export type ruleType = {
  key: string,
  value: string,
  operand: string
}
export type labelRuleType = {
  name: string,
  description: string,
  enabled: boolean,
  rules: ruleType[],
  effects: effectType[]
}
export type effectTypeWithId = {
  id: string,
  key: string,
  value: string,
  children: effectTypeWithId[]
}
export type ruleTypeWithId = {
  id: string,
  key: string,
  value: string,
  operand: string
}
export type labelRuleTypeWithId = {
  id: string,
  name: string,
  description: string,
  enabled: boolean,
  rules: ruleTypeWithId[],
  effects: effectTypeWithId[]
}
export type labelRuleUpdateQuery = {
  name: string,
  description: string,
  enabled: boolean,
  rules: [
    {
      key: string,
      value: string,
      operand: string
    },
    {
      key: string,
      value: string,
      operand: string
    }
  ],
  effects: effectType[]
}
export function createNewLabelRule(): labelRuleTypeWithId {
  return ({
    id: uuid(),
    name: '',
    description: '',
    enabled: true,
    rules: [],
    effects: []
  }
  )
}
export function createNewRuleWithID(): ruleTypeWithId {
  return ({
    id: uuid(),
    key: '',
    value: '',
    operand: 'equals'
  }
  )
}
export function createNewEffectWithID(): effectTypeWithId {
  return ({
    id: uuid(),
    key: '',
    value: '',
    children: []
  }
  )
}
