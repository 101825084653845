import { useMemo } from 'react'
import {
  AxisOptions,
  Chart,
  ChartOptions,
  UserSerie,
  TooltipOptions
} from 'react-charts'

interface ChartProps<F> {
  dataValues: UserSerie<F>[]
  dateProperty: string
  chartType?: 'line' | 'area' | 'bar' | 'bubble'
  valueProperty: string
}

function ChartComponent<F>({
  dataValues,
  dateProperty,
  chartType,
  valueProperty
}: ChartProps<F>) {
  const getValue = (item: any, property: string, isdate: boolean) => {
    if (isdate) {
      return Date.parse((item as any)[property])
    }
    const v = (item as any)[property]
    return v
  }

  /* const getOptions = () => {
    const ax: AxisOptions<F>[] = []
    axisProperty?.forEach((item, i) => {
      if (i > 1) {
        ax.push({
          scaleType: 'linear',
          id: 2,
          min: 0,
          max: 1,
          elementType: item.chartType,
          // eslint-disable-next-line dot-notation
          getValue: (datum) => parseFloat(getValue(datum, item.valueProperty, false))
        })
      } else {
        ax.push({
          scaleType: 'linear',
          elementType: item.chartType,
          min: 0,
          max: 1,
          // eslint-disable-next-line dot-notation
          getValue: (datum) => parseFloat(getValue(datum, item.valueProperty, false))
        })
      }
    })
    return ax
  } */

  const secondaryAxes = useMemo<AxisOptions<F>[]>(
    () => [
      {
        position: 'left',
        scaleType: 'linear',
        elementType: chartType,
        getValue: (datum) => getValue(datum, valueProperty, false)
      }
    ],
    []
  )

  // Access the client
  const primaryAxis = useMemo(
    (): AxisOptions<F> => ({
      position: 'bottom',
      scaleType: 'time',
      getValue: (datum) => getValue(datum, dateProperty, true)
    }),
    []
  )

  // const secondaryAxes = useMemo((): AxisOptions<F>[] => getOptions(), [])
  // const secondaryAxes = useMemo<AxisOptions<F>[]>(() => secondaryAxis, [])
  const toolTip: TooltipOptions<F> = {
    align: 'auto',
    show: true,
    groupingMode: 'primary'
  }

  const opt: ChartOptions<F> = {
    data: dataValues,
    primaryAxis,
    secondaryAxes,
    showDebugAxes: false,
    intersectionObserverRootMargin: `${100}px`,
    interactionMode: 'closest',
    showVoronoi: false,
    tooltip: toolTip,
    useIntersectionObserver: true
  }
  return (
    <div className="h-full">
      <Chart options={opt} />
    </div>
  )
}

export default ChartComponent
