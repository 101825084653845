/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { MIN_HEIGHT } from 'constants/sizes.constants'
import { TCurrency } from 'services/cost.interface'
import {
  flatTableRowDataWithChildren,
  addNessessoryFields
} from './table.utils'

import { TFilter, TTableColumn, TTableData } from './types'

interface TTableContext {
  droppableId?: string
  draggable?: boolean
  extendRowIdList: string[]
  toggleExtendRowId: (id: string, isExpand: boolean) => void
  toggleExtendAll: () => void
  groupChildrenField?: string
  minHeight?: number
  fullHeight?: boolean
  columns: TTableColumn[]
  data: TTableData[]
  formatOptions?: { currency?: TCurrency }
  order: string
  filterList: TFilter[]
  setFilterList: React.Dispatch<React.SetStateAction<TFilter[]>>
  setOrder: React.Dispatch<React.SetStateAction<string>>
  orderBy: string
  setOrderBy: React.Dispatch<React.SetStateAction<string>>
  selectedRowIdList?: string[]
  onRowClick?: (row: TTableData) => void
}

const TableContext = createContext<TTableContext>({
  droppableId: '',
  draggable: false,
  extendRowIdList: [],
  toggleExtendRowId: () => {},
  toggleExtendAll: () => {},
  groupChildrenField: '',
  minHeight: MIN_HEIGHT.TABLE,
  fullHeight: true,
  columns: [],
  data: [],
  formatOptions: {},
  filterList: [],
  setFilterList: () => {},
  order: '',
  setOrder: () => {},
  orderBy: '',
  selectedRowIdList: [],
  setOrderBy: () => {}
})

export interface TableContextProviderProps {
  droppableId?: string
  draggable?: boolean
  groupChildrenField?: string
  minHeight?: number
  fullHeight?: boolean
  columns: TTableColumn[]
  data: TTableData[]
  onRowClick?: (row: TTableData) => void
  formatOptions?: { currency?: TCurrency }
  selectedRowIdList?: string[]
  children: ReactNode
}

export function TableContextProvider({
  droppableId = '',
  draggable = false,
  groupChildrenField,
  minHeight = MIN_HEIGHT.TABLE,
  fullHeight = true,
  columns: columnsProp,
  data: dataProp,
  formatOptions,
  selectedRowIdList,
  onRowClick,
  children
}: TableContextProviderProps) {
  const keepRef = useRef<{
    extendableRowIdList: string[]
    onRowClick: TableContextProviderProps['onRowClick']
  }>({ onRowClick, extendableRowIdList: [] })

  const [filterList, setFilterList] = useState<TFilter[]>([])
  const [extendRowIdList, setExtendRowIdList] = useState<string[]>([])
  const [order, setOrder] = useState('')
  const [orderBy, setOrderBy] = useState('')

  const columns = useMemo<TTableColumn[]>(() => {
    if (!columnsProp) return []

    return columnsProp.map((column) => ({
      filterable: true,
      sortable: true,
      ...column
    }))
  }, [columnsProp])

  const data = useMemo(() => {
    if (!dataProp) return []
    const result = addNessessoryFields({
      data: dataProp,
      childrenField: groupChildrenField
    })
    // support for [toggleExtendAll]
    const flattenRowData = flatTableRowDataWithChildren(
      result,
      groupChildrenField
    )
    const flattenRowDataHaveChildren = flattenRowData.filter(
      (rowData) => rowData[groupChildrenField || '']?.length
    )
    keepRef.current.extendableRowIdList = flattenRowDataHaveChildren.map(
      ({ _id }) => _id || ''
    )
    return result
  }, [groupChildrenField, dataProp])

  const toggleExtendRowId = useCallback((rowId: string, isExpand: boolean) => {
    setExtendRowIdList((oldIdList) => {
      const filteredList = oldIdList.filter((id) => id !== rowId)
      if (!isExpand) return filteredList
      return [...filteredList, rowId]
    })
  }, [])

  const toggleExtendAll = useCallback(() => {
    setExtendRowIdList((oldIdList) => {
      if (oldIdList.length) return []
      return keepRef.current.extendableRowIdList
    })
  }, [groupChildrenField])

  const value = useMemo<TTableContext>(
    () => ({
      droppableId,
      draggable,
      extendRowIdList,
      toggleExtendRowId,
      toggleExtendAll,
      minHeight,
      fullHeight,
      groupChildrenField,
      columns,
      data,
      formatOptions,
      filterList,
      setFilterList,
      order,
      setOrder,
      orderBy,
      setOrderBy,
      selectedRowIdList,
      onRowClick: keepRef.current.onRowClick
    }),
    [
      droppableId,
      draggable,
      extendRowIdList,
      toggleExtendRowId,
      toggleExtendAll,
      minHeight,
      fullHeight,
      groupChildrenField,
      columns,
      data,
      formatOptions,
      filterList,
      order,
      orderBy,
      selectedRowIdList
    ]
  )

  useEffect(() => {
    keepRef.current.onRowClick = onRowClick
  }, [onRowClick])

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export default TableContext
