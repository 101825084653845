import { useRef, useEffect } from 'react'
import { addDays, format } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import usePopup from 'hooks/usePopup'
import { mapClasses } from 'utils'

interface DatePickerComponentProps {
  startDate: Date | undefined
  endDate: Date | undefined
  // size?: 'sm' | 'md' | 'lg' | 'h' | 'q'
  handleChange: (
    dates: { startDate?: Date; endDate?: Date; key?: string }[]
  ) => void
  onClosed?: (startDate?: Date, endDate?: Date) => void
}

function DateRangePickerComponent({
  startDate,
  endDate,
  // size,
  handleChange,
  onClosed
}: DatePickerComponentProps) {
  const triggerDatePickerRef = useRef<HTMLDivElement | null>(null)
  const { isOpen, openPopup } = usePopup(triggerDatePickerRef)

  useEffect(() => {
    if (!isOpen) {
      if (onClosed) {
        onClosed(startDate, endDate)
      }
    }
  }, [isOpen])

  return (
    <div
      className={mapClasses(
        'flex flex-col items-center border cursor-pointer rounded-lg h-full w-full px-3 py-1.5 border-gray-500 rounded-lg bg-white'
      )}
      ref={triggerDatePickerRef}
      onClick={openPopup}
    >
      {format(startDate || addDays(new Date(), 7), 'MMM dd, yyyy')} -{' '}
      {format(endDate || new Date(), 'MMM dd, yyyy')}
      {isOpen && (
        <div className="absolute border shadow z-20 bg-white items-center justify-center">
          <DateRangePicker
            className="relative"
            onChange={(item) => handleChange([item.selection])}
            months={1}
            ranges={[
              {
                startDate: startDate || addDays(new Date(), 7),
                endDate: endDate || new Date(),
                key: 'selection'
              }
            ]}
            direction="vertical"
          />
        </div>
      )}
    </div>
  )
}

export default DateRangePickerComponent
