import { ReactNode } from 'react'

import Spinner from 'components/spinner'

export interface SpinnerLoaderProps {
  className?: string
  height?: number | string
  spinnerSize?: number
  isLoading: boolean
  children: ReactNode
}

function SpinnerLoader({
  className,
  isLoading = false,
  height = 36,
  spinnerSize,
  children
}: SpinnerLoaderProps) {
  if (!isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <div
      className={`flex justify-center items-center ${className}`}
      style={{ height }}
    >
      <Spinner size={spinnerSize} />
    </div>
  )
}

export default SpinnerLoader
