import { Popover } from '@mantine/core'
import { useState, ChangeEvent, InputHTMLAttributes, forwardRef, useEffect } from 'react'
// import { ChangeEvent, useMemo, useState } from 'react'
import { mapClasses } from 'utils'

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  readonlyName?: boolean
  label?: string
  pattern?: string
  errorMessage?: string
  containerClassName?: string
  onValidated?: (isValid: boolean) => void
}
const PatternMatchedInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      containerClassName,
      className,
      readonlyName,
      pattern,
      errorMessage,
      onValidated,
      ...props
    },
    ref
  ) => {
    const [isValid, setIsValid] = useState<boolean>(true)
    const { onChange, ...newProps } = props;
    const handleValidateInitial = () => {
      if (props.value) {
        const value = props.value.toString()
        if (value !== '') {
          if (pattern) {
            const regex = new RegExp(pattern)
            const isValid = regex.test(value)
            setIsValid(isValid)
            if (onValidated) onValidated(isValid)
          } else {
            setIsValid(true)
            if (onValidated) onValidated(true)
          }
        } else {
          setIsValid(true)
          if (onValidated) onValidated(true)
        }
      }
    }

    const handleValidate = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      if (value !== '') {
        if (pattern) {
          const regex = new RegExp(pattern)
          const isValid = regex.test(value)
          setIsValid(isValid)
          if (onValidated) onValidated(isValid)
        } else {
          setIsValid(true)
          if (onValidated) onValidated(true)
        }
      } else {
        setIsValid(true)
        if (onValidated) onValidated(true)
      }
    }
    useEffect(() => {
      handleValidateInitial()
    }, [props])
    return (
      <div className={mapClasses('flex flex-col w-full', containerClassName)}>
        <Popover width={300} position="bottom" withArrow shadow="md" opened>
          <Popover.Target>
            <input
              ref={ref}
              disabled={readonlyName}
              className={mapClasses(
                'bg-transparent border',
                !isValid ? 'border-red-500 focus-visible:outline-none' : '',
                className
              )}
              onChange={(event) => {
                handleValidate(event)
                if (onChange) onChange(event)
              }}
              {...newProps || props}
            />
          </Popover.Target>
          {!isValid && (
            <Popover.Dropdown>
              <span className="ml-2 block text-xs text-red-500">
                ({errorMessage})
              </span>
            </Popover.Dropdown>
          )}
        </Popover>
      </div>
    )
  }
)

export default PatternMatchedInput
