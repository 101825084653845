import React, { ReactNode, useCallback, useEffect, useRef } from 'react'

export const RESIZE_DIRECTIONS = {
  LEFT_BOTTOM: 'LEFT_BOTTOM',
  LEFT: 'LEFT',
  RIGHT_BOTTOM: 'RIGHT_BOTTOM',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
}

interface ResizableProps {
  className?: string
  minSizes?: { width: number; height: number }
  maxSizes?: { width: number; height: number }
  sizes: { width: number; height: number }
  onStartResize?: () => void
  onSizeChange: (params: {
    direction: string
    width: number
    height: number
    oldWidth: number
    oldHeight: number
  }) => void
  onEndResize?: () => void
  children: ReactNode
}

function Resizable({
  className,
  sizes,
  minSizes,
  maxSizes,
  onStartResize,
  onSizeChange,
  onEndResize,
  children
}: ResizableProps) {
  const keepRef = useRef({
    direction: '',
    oldLeft: 0,
    oldTop: 0,
    oldSizes: sizes
  })
  const leftBarRef = useRef<HTMLDivElement | null>(null)
  const cornerRef = useRef<HTMLDivElement | null>(null)
  const RIGHTBarRef = useRef<HTMLDivElement | null>(null)

  const onMouseDown = (event: React.MouseEvent, direction: string) => {
    event.stopPropagation()
    keepRef.current.direction = direction
    const { pageX: nextLeft, pageY: nextTop } = event

    keepRef.current.oldLeft = nextLeft
    keepRef.current.oldTop = nextTop
    keepRef.current.oldSizes = sizes
    onStartResize?.()
  }

  const handleResize = useCallback(
    (event: MouseEvent) => {
      const {
        direction, oldSizes, oldLeft, oldTop,
      } = keepRef.current
      if (!direction) return
      event.stopPropagation()

      const { pageX: nextLeft, pageY: nextTop } = event

      let newWidth = oldSizes.width
      let newHeight = oldSizes.height
      if ([RESIZE_DIRECTIONS.LEFT, RESIZE_DIRECTIONS.LEFT_BOTTOM].includes(direction)) {
        newWidth = oldSizes.width + oldLeft - nextLeft
      }
      if ([RESIZE_DIRECTIONS.RIGHT, RESIZE_DIRECTIONS.RIGHT_BOTTOM].includes(direction)) {
        newWidth = oldSizes.width + nextLeft - oldLeft
      }
      if (
        [
          RESIZE_DIRECTIONS.BOTTOM,
          RESIZE_DIRECTIONS.LEFT_BOTTOM,
          RESIZE_DIRECTIONS.RIGHT_BOTTOM
        ].includes(direction)
      ) {
        newHeight = oldSizes.height + nextTop - oldTop
      }

      if (minSizes?.width) newWidth = Math.max(newWidth, minSizes.width)
      if (minSizes?.height) newHeight = Math.max(newHeight, minSizes.height)

      if (maxSizes?.width) newWidth = Math.min(newWidth, maxSizes.width)
      if (maxSizes?.height) newHeight = Math.min(newHeight, maxSizes.height)

      onSizeChange({
        direction,
        width: newWidth,
        height: newHeight,
        oldWidth: oldSizes.width,
        oldHeight: oldSizes.height
      })
    },
    [minSizes, maxSizes]
  )

  // handle stop resizing
  useEffect(() => {
    const handleMouseUp = (event: MouseEvent) => {
      if (keepRef.current.direction) {
        event.stopPropagation()
        keepRef.current.direction = ''
        onEndResize?.()
      }
    }

    document.addEventListener('mouseup', handleMouseUp)
    return () => document.removeEventListener('mouseup', handleMouseUp)
  }, [onEndResize])

  // handle resize
  useEffect(() => {
    document.addEventListener('mousemove', handleResize)
    return () => document.removeEventListener('mousemove', handleResize)
  }, [handleResize])

  return (
    <div
      className={`relative ${className}`}
      style={{ width: sizes.width, height: sizes.height }}
    >
      {children}
      <div
        ref={leftBarRef}
        className="absolute top-0 left-0 w-2 h-full cursor-ew-resize"
        onMouseDown={(event) => onMouseDown(event, RESIZE_DIRECTIONS.LEFT)}
      />
      <div
        ref={leftBarRef}
        className="absolute top-0 right-0 w-2 h-full cursor-ew-resize"
        onMouseDown={(event) => onMouseDown(event, RESIZE_DIRECTIONS.RIGHT)}
      />
      <div
        ref={RIGHTBarRef}
        className="absolute bottom-0 left-0 w-full h-2 cursor-ns-resize"
        onMouseDown={(event) => onMouseDown(event, RESIZE_DIRECTIONS.BOTTOM)}
      />
      <div
        ref={cornerRef}
        className="absolute z-10 bottom-0 left-0 w-2 h-2 cursor-nesw-resize"
        onMouseDown={(event) => onMouseDown(event, RESIZE_DIRECTIONS.LEFT_BOTTOM)}
      />
      <div
        ref={cornerRef}
        className="absolute z-10 bottom-0 right-0 w-2 h-2 cursor-nwse-resize"
        onMouseDown={(event) => onMouseDown(event, RESIZE_DIRECTIONS.RIGHT_BOTTOM)}
      />
    </div>
  )
}

export default Resizable
