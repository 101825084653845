import React, { useMemo } from 'react'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import _ from 'lodash'

import { TFetchStatus } from 'services/fetch.interface'
import { TComponent } from 'services/component.interface'
import {
  ButtonIcon,
  FetchStatus,
  GraphCard,
  TTableData,
  Table
} from 'components'
import { BASIC_TABLE_COLUMNS } from '../constants'

const AVAILABLE_KEY = 'available'

interface AvailableComponentTableProps {
  fetchStatus: TFetchStatus
  list: TComponent[]
  selectingIndexList: number[]
  setSelectingIndexList: React.Dispatch<React.SetStateAction<number[]>>
  onClickInfo: (row: TTableData) => void
  retry: () => void
}

function AvailableComponentTable({
  fetchStatus,
  list,
  selectingIndexList,
  setSelectingIndexList,
  onClickInfo,
  retry
}: AvailableComponentTableProps) {
  const availableTableColumns = useMemo(
    () => [
      ...BASIC_TABLE_COLUMNS,
      {
        name: '',
        field: 'properties',
        width: 55,
        filterable: false,
        sortable: false,
        render: (__, { row }) => (
          <div className="flex justify-end">
            <ButtonIcon
              onClick={(event) => {
                event.stopPropagation()
                onClickInfo(row._rawData || {})
              }}
            >
              <HiOutlineInformationCircle size={18} />
            </ButtonIcon>
          </div>
        )
      }
    ],
    []
  )

  return (
    <GraphCard isOutline={false} isLoading={fetchStatus.isLoading}>
      <div className="w-full flex items-center text-lg font-medium text-gray-800 mb-2">
        Available uncategorized components
      </div>
      <div className="grow overflow-x-auto">
        <FetchStatus height="100%" status={fetchStatus} retry={retry}>
          <Table
            key={AVAILABLE_KEY}
            droppableId={AVAILABLE_KEY}
            draggable
            data={list}
            columns={availableTableColumns}
            selectedRowIdList={selectingIndexList.map(String)}
            onRowClick={(row) => {
              setSelectingIndexList((oldList) => {
                const newList = oldList.filter((val) => val !== row._index)
                if (_.isNil(row._index)) return newList
                if (oldList.includes(row._index)) return newList
                return [...newList, row._index]
              })
            }}
          />
        </FetchStatus>
      </div>
    </GraphCard>
  )
}

export default AvailableComponentTable
