import React, { ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'

import { mapClasses } from 'utils'

interface ModalProps {
  isOpen: boolean
  disableBackdropClick?: boolean
  className?: string
  onClose: () => void
  children: ReactNode
}

function Modal({
  isOpen,
  disableBackdropClick,
  className,
  onClose,
  children
}: ModalProps) {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null)

  const handleClickWrapper = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!disableBackdropClick && wrapperElement === event.target) {
      onClose()
    }
  }

  if (!isOpen) return null

  return createPortal(
    <div className={mapClasses('fixed', className)} style={{ zIndex: 100 }}>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          ref={setWrapperElement}
          className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 bg-black bg-opacity-80"
          onClick={handleClickWrapper}
        >
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 min-w-[400px] max-w-[calc(100vw-64px)]">
            {children}
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
