import { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'

import useAuthentication from 'hooks/useAuthentication'

import { Spinner } from 'components'

function PrivateRoute({ children }: { children: ReactElement }) {
  const auth = useAuthentication()

  if (auth.isLoading) {
    return (
      <div className="fixed bg-white flex justify-center items-center w-screen h-screen">
        <Spinner size={46} />
      </div>
    )
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" />
  }
  return children
}

export default PrivateRoute
