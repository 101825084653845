import { useMemo, useState } from 'react'
import { MdDeleteOutline, MdEdit } from 'react-icons/md'

import { TCurrency } from 'services/cost.interface'
import { TFetchStatus } from 'services/fetch.interface'
import { TReportFilterDisplay } from 'services/report.interface'
import {
  ButtonIcon,
  ConfirmModal,
  FETCH_STATUS,
  FetchStatus,
  GraphCard,
  GraphCardContent,
  Table,
  TTableColumn
} from 'components'
import useReport from 'hooks/useReport'
import { REPORT_FILTER_TABLE_COLUMNS } from './constants'

interface TableActionsProps {
  row: TReportFilterDisplay
  onEdit: (reportFilter: TReportFilterDisplay) => void
  onFetch: () => Promise<void>
}

function TableActions({ row, onEdit, onFetch }: TableActionsProps) {
  const { deleteReportFilter } = useReport()

  const [deleteReportFilterStatus, setDeleteReportFilterStatus] = useState(
    FETCH_STATUS.SUCCESS
  )
  const [deletingReportFilterName, setDeletingReportFilterName] = useState('')

  const handleDelete = async () => {
    await deleteReportFilter(
      { queryName: deletingReportFilterName },
      setDeleteReportFilterStatus
    )
    setDeletingReportFilterName('')
    onFetch()
  }

  return (
    <div
      className="flex justify-center gap-2"
      onClick={(event) => event.stopPropagation()}
    >
      <ButtonIcon roundedFull className="p-1" onClick={() => onEdit(row)}>
        <MdEdit size={20} />
      </ButtonIcon>
      <ButtonIcon
        roundedFull
        color="error"
        className="p-1"
        onClick={() => setDeletingReportFilterName(row.name)}
      >
        <MdDeleteOutline size={20} />
      </ButtonIcon>

      <ConfirmModal
        isOpen={!!deletingReportFilterName}
        isLoading={deleteReportFilterStatus.isLoading}
        title="Delete report"
        description={`Do you want to delete report "${deletingReportFilterName}"?`}
        onClose={() => setDeletingReportFilterName('')}
        onConfirm={handleDelete}
      />
    </div>
  )
}

interface ReportFiltersTableCardProps {
  initialLoading: boolean
  currency: TCurrency
  reportFilters: TReportFilterDisplay[]
  fetchStatus: TFetchStatus
  onEdit: (reportFilter: TReportFilterDisplay) => void
  onFetch: () => Promise<void>
  onSelect: (id: string) => void
}

function ReportFiltersTableCard({
  initialLoading,
  currency,
  reportFilters,
  fetchStatus,
  onFetch,
  onEdit,
  onSelect
}: ReportFiltersTableCardProps) {
  const formatOptions = useMemo(() => ({ currency }), [currency])

  const actionColumn = useMemo<TTableColumn>(
    () => ({
      name: 'Actions',
      field: '',
      sortable: false,
      filterable: false,
      width: 100,
      render: (_, { row }) => (
        <TableActions
          row={row as TReportFilterDisplay}
          onEdit={onEdit}
          onFetch={onFetch}
        />
      )
    }),
    []
  )

  return (
    <GraphCard isOutline={false} isLoading={initialLoading}>
      <GraphCardContent className="overflow-x-auto" minHeight={50}>
        <FetchStatus status={fetchStatus} retry={onFetch}>
          <Table
            columns={[...REPORT_FILTER_TABLE_COLUMNS, actionColumn]}
            data={reportFilters}
            formatOptions={formatOptions}
            onRowClick={(row) => onSelect(row.id)}
          />
        </FetchStatus>
      </GraphCardContent>
    </GraphCard>
  )
}

export default ReportFiltersTableCard
