import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { axios } from 'utils/axios'
import { TFocusGroup } from 'services/costhistory.interface'
import { useQuery } from 'react-query'
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { FaMoneyBills } from 'react-icons/fa6'
import { TCurrency } from 'services/cost.interface'
import { query } from '../project-widget/widgetHelpFunctions'

export interface summaryBarProps {
  id: string,
  title: string,
  key: string,
  window: string,
  currency: TCurrency,
}
export function PreviousMonthSpend({
  id,
  title,
  key,
  window,
  currency,
}: summaryBarProps) {
  const {
    fetchProjectsStatus,
    // projectList,
    // fetchProjects
  } = useProject()
  // const id = uuid()
  const [data, setData] = useState<TFocusGroup[]>([])
  const [dataProcessed, setDataProcessed] =
    useState<boolean>(false)
  const [query, setQuery] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'provider',
        step: '1m',
        window
      },
      filters: [
      ]
    }
  )
  const changeQueryId = () => {
    setQuery(
      {
        name: uuid(),
        description: '',
        options: {
          saveQuery: false,
          accumulate: true,
          aggregate: 'provider',
          step: '1m',
          window
        },
        filters: [
        ]
      }
    )
  }
  const { error } = useQuery({
    queryKey: [`query?${JSON.stringify(query)}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .post('/query', query)
            .then((res) => {
              setData(res.data.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setData([])
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  function getTotalCost() {
    if (data.length !== 0) {
      const dataToWorkWith = data[0]
      const objName = Object.getOwnPropertyNames(dataToWorkWith)
      if (objName.length > 0) {
        const monthlyCosts = objName.map((el) => {
          const theNamesData = dataToWorkWith[el]
          return Number(theNamesData.totalBilledCost)
        })
        return Number(monthlyCosts.reduce((prev, cur) => {
          return prev + cur
        }, 0).toFixed(2))
      } return 0
    } return 0
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div id={id} key={key} className="p-2 h-full">
      <div key={key} className="h-full">
        <FetchStatus status={fetchProjectsStatus} retry={changeQueryId}>
          <div className="h-full">
            <div className="h-3/4">
              <div>
                <span className="font-semibold text-gray-800 text-lg">
                  {currency.symbol}
                  {
                    getTotalCost()
                  }
                </span>
              </div>
              <span className="text-gray-600 text-xs">{title}</span>
            </div>
            <div className="flex justify-end">
              {title === 'Previous month spendings' ? <FaMoneyBills color="#4f69d2" size={40} /> : <FaMoneyBills color="#668957" size={40} />}
            </div>
          </div>
        </FetchStatus>
      </div>
    </div>
  )
}
