import { createContext, ReactElement, useCallback, useMemo } from 'react'

import { TCost } from 'services/cost.interface'
import { TFetchExternalCostFunction } from 'services/fetch.interface'
import { FETCH_STATUS } from 'components'
import { apiErrorHandler, axios } from 'utils'

interface ExternalCostContextType {
  getExternalCost: TFetchExternalCostFunction
}

const ExternalCostContext = createContext<ExternalCostContextType>(
  {} as ExternalCostContextType
)

export function ExternalCostContextProvider({
  children
}: {
  children: ReactElement
}) {
  const getExternalCost = useCallback<TFetchExternalCostFunction>(
    async ({ accumulate, window }, updateStatus) => {
      const queryStringList = [`window=${window}`, `accumulate=${accumulate}`]

      const url = `/cost/external?${queryStringList.join('&')}`

      try {
        updateStatus?.(FETCH_STATUS.START)
        const { data } = await axios.get<TCost[]>(url)
        updateStatus?.(FETCH_STATUS.SUCCESS)
        return data
      } catch (error) {
        apiErrorHandler(error)
        updateStatus?.(FETCH_STATUS.FAIL)
        return []
      }
    },
    []
  )

  const value = useMemo<ExternalCostContextType>(
    () => ({ getExternalCost }),
    [getExternalCost]
  )

  return (
    <ExternalCostContext.Provider value={value}>
      {children}
    </ExternalCostContext.Provider>
  )
}

export default ExternalCostContext
