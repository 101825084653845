import { InputHTMLAttributes, forwardRef } from 'react'

import { mapClasses } from 'utils'

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'sm' | 'md' | 'lg'
  label?: string
  errorMessage?: string
  containerClassName?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = 'lg',
      label = '',
      containerClassName,
      className,
      errorMessage,
      ...props
    },
    ref
  ) => {
    return (
      <div className={mapClasses('flex flex-col w-full', containerClassName)}>
        {label && (
          <span className="flex text-gray-500 text-xs font-normal mb-1">
            {label}
            {errorMessage && (
              <span className="ml-2 block text-xs text-red-500">
                ({errorMessage})
              </span>
            )}
          </span>
        )}
        <input
          ref={ref}
          className={mapClasses(
            'bg-transparent border focus-visible:outline-none ',
            errorMessage ? 'border-red-500' : 'border-dark-grey focus:border-blue-500',
            size === 'lg' && 'h- px-3 py-1.5',
            size === 'md' && 'px-3 py-1',
            size === 'sm' && 'px-2 py-1 text-sm',
            'rounded-lg w-full',
            className
          )}
          {...props}
        />
      </div>
    )
  }
)

export default Input
