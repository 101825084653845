export const tourConfig = (id: string, callbackList: (() => void)[]) => {
  // console.log(callbackList)
  return [
    {
      selector: `[data-tour="ATableWidgetWithId${id}"]`,
      content: 'This is the Table widget! Here you can take a look at your data. Choose the labels you want to traverse through in a specific order, your data will then be loaded in for you.'
    },
    {
      selector: `[data-tour="TheTableElementOf${id}"]`,
      content: 'This is the Table itself, the main component of this widget! Once filled with data you will be able to click on the elements in it, this allows you to delve deeper in the information chosen. Clicking on an element means that you want to look at the data of the second tag chosen for the data with the value you just clicked on.'
    },
    {
      selector: `[data-tour="spanForTheTableScreenForTableWidget${id}"]`,
      content: 'After clicking on an element in the table, the name of that element will be placed here. This indicates that you have chosen to delve deeper in the information of that element. Clicking on a piece of this path will allow you to go back to the moment you clicked that element.'
    },
    {
      selector: `[data-tour="tableInfoCardsLeftSideOf${id}"]`,
      content: 'This element will give you a tree view of where you currently are in the data and what you chose for which tag.'
    },
    {
      selector: `[data-tour="tableInfoCardsRightSideOf${id}"]`,
      content: 'This card shows you the aggregated monthly cost of all the currently visible elements.'
    },
    {
      selector: `[data-tour="theOptionsButtonFor${id}"]`,
      content: 'Press this to open options, here you can change and delete the widget.',
      action: () => { callbackList[0]() }
    },
    {
      selector: `[data-tour="anOptionLabelEdit widgetOf${id}"]`,
      content: 'Pressing this will open the page that will allow you to select the data you want to view.',
    },
    {
      selector: `[data-tour="ATableWidgetWithId${id}"]`,
      content: 'This is the page mentioned before. Select what kind of tags you want to view using the tabs "fields" and "labels". These will each show their tags which you can click to add them. The selected tags are presented on the right side which will allow you to remove them and reorder them. The selected order is important as this will affect in which order the data is shown.',
      action: () => {
        callbackList[1]()
        callbackList[2]()
      }
    },
    {
      selector: `[data-tour="ATableWidgetWithId${id}"]`,
      content: 'Enjoy discovering your data',
      action: () => {
        callbackList[3]()
        callbackList[4]()
      }
    }
  ]
}
