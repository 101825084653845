import { Buffer } from 'buffer';
import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import useCatalog from 'hooks/useCatalog';

function CatalogApplication() {
  const [tab, setTab] = useState<any>(0)

  const { getCatalogPackageVersionDetails, catalogPackageVersion, loading } = useCatalog()
  const { catalogId, packageName, version } = useParams()

  const base64toUTF8 = (base64: string) => Buffer.from(base64, 'base64').toString('utf-8');

  useEffect(() => {
    getCatalogPackageVersionDetails(catalogId, packageName, version)
  }, [getCatalogPackageVersionDetails, catalogId, packageName, version])

  return (
    <div className="main xs:p-4 tablet:p-10">
      <div className="flex items-start justify-start">
        {loading ? (
          <Skeleton height={144} width="144px" containerClassName="pr-2 pb-1" />
        ) : (
          <div className="w-36 h-36 mr-2">
            <img
              src={
                catalogPackageVersion?.logo[0]?.src
                  ? catalogPackageVersion?.logo[0]?.src
                  : '/images/image1.jpg'
              }
              alt={catalogPackageVersion?.applicationName}
              className="w-full h-full"
            />
          </div>
        )}
        {loading ? (
          <Skeleton height={36} width="400px" />
        ) : (
          <p className="font-bold text-xl">
            {catalogPackageVersion?.applicationName}
          </p>
        )}
      </div>
      {loading ? (
        <Skeleton height={38} width="500px" />
      ) : (
        <div className="flex items-center justify-start mt-3">
          <div className="mr-2">
            <p className="font-bold text-lg">Versions:</p>
          </div>
          <div className="flex justify-center items-center">
            {catalogPackageVersion?.version}
          </div>
        </div>
      )}
      {loading ? (
        <Skeleton height={150} />
      ) : (
        <div className="flex flex-wrap items-center justify-start mt-3">
          <div className="large:flex-0.3 xs:flex-1">
            <p className="font-bold text-lg">Description:</p>
            <p className="mt-1 text-gray-700 text-base w-full">
              {catalogPackageVersion?.description}
            </p>
          </div>
          <div className="flex-0.6">
            <p>
              Maintainers:{' '}
              {catalogPackageVersion?.maintainer?.map(
                (maintainer) => `${maintainer}, `
              )}
            </p>
            <p className="my-5">License: {catalogPackageVersion?.license}</p>
            <p>Labels: {catalogPackageVersion?.labels.category}</p>
          </div>
        </div>
      )}
      <div className="mt-5">
        {loading ? (
          <Skeleton height={36} width="400px" />
        ) : (
          <div className="my-3">
            <p className="font-bold text-lg">Components:</p>
          </div>
        )}
        <div>
          {loading ? (
            <Skeleton height={43} containerClassName="my-4" />
          ) : (
            <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
              <ul
                className="flex flex-wrap -mb-px text-sm font-medium text-center"
                id="myTab"
                data-tabs-toggle="#myTabContent"
                role="tablist"
              >
                {catalogPackageVersion && Object.keys(
                  catalogPackageVersion?.definitions as any
                ).map(
                  (defination, index) => (
                    <li key={`tab-${index}`} className="mr-2" role="presentation">
                      <button
                        className={`inline-block p-4 rounded-t-lg ${
                          tab === index
                            ? 'border-b-2 border-primary-color hover:border-primary-color text-primary-text-color'
                            : 'hover:border-b-2 hover:border-gray-300'
                        }`}
                        type="button"
                        role="tab"
                        onClick={() => setTab(index)}
                      >
                        {defination}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
          {loading ? (
            <Skeleton height={600} />
          ) : (
            <div className="pt-2">
              {catalogPackageVersion && Object.keys(
                catalogPackageVersion?.definitions as any
              ).map((key, index) => (
                <div
                  key={`tab-details-${index}`}
                  className={`${
                    tab === index ? 'block' : 'hidden'
                  } p-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                >
                  <p className="mb-5 text-center tablet:w-2/8 xs:w-full">
                    Click to edit the definition in YAML {'</>'}
                  </p>
                  <div className="flex flex-wrap">
                    <div className="large:w-4/10 xs:w-full ml-3">
                      <Editor
                        defaultLanguage="yaml"
                        height="500px"
                        language="yaml"
                        theme="vs-dark"
                        defaultValue={base64toUTF8(catalogPackageVersion?.definitions[key])}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <Skeleton
          height={44}
          width="300px"
          containerClassName="flex justify-end items-end mt-2"
        />
      ) : (
        <div className="flex justify-end items-end my-3">
          <button
            type="button"
            className="bg-primary-color text-white font-medium rounded-md py-2.5 px-7"
          >
            Deploy Application
          </button>
        </div>
      )}
    </div>
  )
}

export default CatalogApplication
