export function getToday(): [Date | null, Date | null] {
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  const endofDay = new Date();
  endofDay.setHours(23, 59, 59, 999);
  return [startOfDay, endofDay]
}
export function getYesterday(): [Date | null, Date | null] {
  const dateObj = new Date()
  dateObj.setDate(dateObj.getDate() - 1);
  const startOfDay = dateObj;
  startOfDay.setHours(0, 0, 0, 0);
  const endofDay = dateObj;
  endofDay.setHours(23, 59, 59, 999);
  return [startOfDay, endofDay]
}

export function getDateInterval(begin: number, end: number): [Date | null, Date | null] {
  const beginDateObj = new Date()
  beginDateObj.setDate(beginDateObj.getDate() - begin);
  beginDateObj.setHours(0, 0, 0, 0)

  const endDateObj = new Date()
  endDateObj.setDate(endDateObj.getDate() - end);
  endDateObj.setHours(23, 59, 59, 999)
  return [beginDateObj, endDateObj]
}
export function thisWeek(): [Date | null, Date | null] {
  const cur = new Date()
  const first = cur.getDate() - cur.getDay() + 1
  const last = first + 6
  const firstWeekDay = new Date(cur.getFullYear(), cur.getMonth(), first)
  const lastWeekDay = new Date(cur.getFullYear(), cur.getMonth(), last)
  lastWeekDay.setHours(23, 59, 59, 999)
  return [firstWeekDay, lastWeekDay]
}
export function lastWeek(): [Date | null, Date | null] {
  const d = new Date();
  const last = d.setTime(d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000);
  const first = d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000);
  return [new Date(first), new Date(last)]
}
export function getThisMonth(): [Date | null, Date | null] {
  const first = new Date();
  first.setDate(1)
  first.setHours(0, 0, 0, 0);
  const last = new Date();
  last.setDate(new Date(last.getFullYear(), last.getMonth() + 1, 0).getDate())
  last.setHours(23, 59, 59, 999);
  return [first, last]
}

export function getLastMonth() {
  const first = new Date();
  first.setDate(1)
  first.setMonth(first.getMonth() - 1)
  first.setHours(0, 0, 0, 0);
  const last = new Date();
  last.setDate(new Date(last.getFullYear(), last.getMonth(), 0).getDate())
  last.setMonth(last.getMonth() - 1)
  last.setHours(23, 59, 59, 999);
  return [first, last]
}
