import { useState } from 'react'
import { createPortal } from 'react-dom'

import { YamlContent } from 'components'
import { mapClasses } from 'utils'

interface ComponentYamlContentProps {
  yamlData: unknown
  onClose: () => void
}

function ComponentYamlContent({
  yamlData,
  onClose
}: ComponentYamlContentProps) {
  const [isFullScreen, setFullScreen] = useState(false)

  const handleClose = () => {
    onClose()
    setFullScreen(false)
  }

  return (
    <>
      {createPortal(
        <div
          className="fixed z-[999] top-0 left-0 w-full h-full bg-black bg-opacity-80"
          onClick={handleClose}
        />,
        document.body
      )}

      {createPortal(
        <div
          className={mapClasses(
            'fixed',
            isFullScreen
              ? 'top-0 left-0 w-full h-full'
              : 'top-1/2 left-1/2 w-1/2 h-1/2 -translate-x-1/2 -translate-y-1/2'
          )}
          style={{ zIndex: 1000 }}
        >
          <YamlContent
            isFullScreen={isFullScreen}
            yamlData={yamlData}
            onToogleFullScreen={() => setFullScreen(!isFullScreen)}
            onClose={handleClose}
          />
        </div>,
        document.body
      )}
    </>
  )
}

export default ComponentYamlContent
