import { Link } from 'react-router-dom'

import { mapClasses } from 'utils'
import useAuthentication from 'hooks/useAuthentication'

import Spinner from 'components/spinner'
import LogInKeyCloakButton from 'components/key-cloak-login-button'

function SigninForm() {
  const { isLoading } = useAuthentication()

  return (
    <div className="relative h-full">
      <div
        className={mapClasses(
          'w-full h-full flex flex-col items-center gap-8 bg-white rounded-md p-12',
          isLoading && 'blur-sm'
        )}
      >
        <div className="flex flex-col items-center">
          <h1 className="text-3xl font-thin text-gray-700">Sign in</h1>
        </div>

        <div className="grow w-full flex flex-col justify-center gap-8 max-w-[280px]">
          <div className="flex flex-col gap-4">
            <LogInKeyCloakButton />
          </div>

          <div className="relative border-gray-100 text-gray-400">
            <div className="absolute left-1/2 -translate-x-1/2 border-t w-1/2" />
            <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-3">
              OR
            </div>
          </div>

          <div className="flex justify-center gap-1">
            <span className="font-light">Don&apos;t have an account?</span>
            <Link className="text-blue-600 hover:underline" to="/register">
              Sign up
            </Link>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="absolute z-10 left-0 top-0 w-full h-full flex justify-center items-center bg-white bg-opacity-10 rounded-lg">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default SigninForm
